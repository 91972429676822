import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { SidebarToggleService } from "src/app/core/sidebar-toggle/sidebar-toggle.service";
declare var $:any;
@Component({
  selector: 'app-nav-bar-top',
  templateUrl: './nav-bar-top.component.html',
  styleUrls: ['./nav-bar-top.component.scss']
})
export class NavBarTopComponent implements OnInit {
  ActiveUser: any;
  ActiveAgent;
  toggleSidebar = false;
  constructor(
    private sidebarToggleService: SidebarToggleService,
     ) {
    this.sidebarToggleService.toggleSidebar.subscribe((response:any)=>{
      if(response == true){
        $('body').removeClass('sidebar_close');
      }else{
        $('body').addClass('sidebar_close');
      }
        // if ($('body').hasClass('sidebar_close')) {
        //   console.log("case 1 here")
        //     $('body').removeClass('sidebar_close');
        // } else {
        //   console.log("case 2 here")
        //     $('toggle_icon.current').removeClass('sidebar_close');
        //     $('body').addClass('sidebar_close');
        // }
    })
  }

  ngOnInit(): void {
    this.ActiveUser = JSON.parse(localStorage.getItem('UserType'));
    this.ActiveAgent = JSON.parse(localStorage.getItem('ActiveAgent'));
    // console.log(this.ActiveUser);
  }

  oncheckUserNav(value) {
    if(this.ActiveUser.navigation.findIndex(x => x == value) < 0 ) {
      return false;
    }
    else{
      return true;
    }
  }

}
