<div class="wrapper">
  <app-nav-bar-top></app-nav-bar-top>
  <div id="main" class="main-panel ps-container">
      <app-header></app-header>
      <div class="main_content">
        <router-outlet></router-outlet>
          <app-footer class="footer"></app-footer>
      </div>
  </div>
</div>
