
<main class="site--main">
    <section class="login_sec">
        <div class="container">
            <div class="center_box">
                <div class="logo_box"><a href="#"><img src="assets/logo/mysocialhousing-logo.jpg"></a></div>
                <h3 class="title">Welcome to my social housing</h3>
                <p class="subtitle">Sign in by entering the information below</p>
                <div class="form_box" *ngIf="!autologin">
                    <form id="login" [formGroup]="registerForm" (submit)="onSubmit()">
                        <input type="hidden" name="_token" value="sgbJdzSyZOp2iPpnqSWievPCrNShcLC357f1ERbK">
                        <!-- <div class="form-group border-box">
                            <label for="email">Email</label>
                            <input name="email" type="email" class="floating-input" placeholder="Enter Email" id="email"
                                autocomplete="off" formControlName="email">
                            <div *ngIf="formSubmitted && registerForm.controls.email.errors" class="has-error">
                                <span *ngIf="registerForm.controls.email.errors.required">Email is required</span>
                            </div>
                        </div> -->
                        <div class="form-group border-box">
                            <label for="Password">Password</label>
                            <input name="password" class="floating-input" type="password" placeholder="Enter Password"
                                id="Password" formControlName="password">
                            <div *ngIf="formSubmitted && registerForm.controls.password.errors" class="has-error">
                                <span *ngIf="registerForm.controls.email.errors.required">Password is required</span>
                            </div>
                        </div>
                        <div class="form-row align-items-center">
                            <div class="form-group col-6">
                                <!--<input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Remember me</label>-->
                                <!-- <div class="custom-control custom-checkbox">
                                    <input name="remember" type="checkbox" class="custom-control-input"
                                        id="customCheck3">
                                    <label class="custom-control-label" for="customCheck3">Remember me</label>
                                </div> -->
                            </div>
                            <div class="form-group text-right col-6 forgot_pass">
                                <a  (click)="resetPassword()">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-block btn-primary">Log In</button>
                        </div>

                        <!-- <div class="bottom_txt">
                            Not a yet member? <a href="#">Sign Up</a>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </section>
    <div class="copyright">© 2021 My Social Housing. All Rights Reserved</div>
</main>
