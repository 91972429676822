<div *ngIf="emailForm" class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Send Email</h4>
                </div>
                <div class="col-sm-6">
                    <!-- <button *ngIf="showSendToAll" (click)="sendemailToAll()" class="btn btn-outline-success btn-fw sendEmail">Send to all</button> -->
                </div>
            </div>
            <form class="forms-sample" [formGroup]="sendEmailForm" (submit)="onSubmitEmail()">
                <div class="form-group row">
                    <label for="exampleInputUsername2" class="col-sm-2 col-form-label">To</label>
                    <div class="col-sm-10">
                        <span>{{this.uidList.length}} {{this.uidList.length > 1 ? 'Users' : 'User'}} </span>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="template" class="col-sm-2 col-form-label">Templates</label>
                    <div class="col-sm-10">
                        <select (change)="onChangeEmailTemplate($event.target.value)" id="template" name="template" formControlName="msgTemplate" class="template">
                            <!-- <option  [value]=""> -- select an option -- </option> -->
                            <ng-template ngFor let-emailTemplate [ngForOf]="allEmailTemplates" let-i="index" >
                                <option [value]="i">{{emailTemplate.title}} : {{emailTemplate.description.split(' ').slice(0, 5).join(' ')}} {{emailTemplate.description.split(' ').length > 5 ? '  . . .':''}}</option>
                            </ng-template>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="title" class="col-sm-2 col-form-label">Title</label>
                    <div class="col-sm-10">
                    <input type="text" class="form-control" id="title"  formControlName="subject" [(ngModel)]='emailTemplateTitle'>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="textarea" class="col-sm-2 col-form-label">Content</label>
                    <div class="col-sm-10" id="textarea-c"  >
                        <textarea name="" id="textarea-c"  class="form-control" cols="30" rows="10" formControlName="content" [(ngModel)]='emailBody'></textarea>
                    </div>
                </div>
                <div class="float-right">
                    <button type="submit" [disabled]="!emailTemplateTitle || !emailBody" class="btn btn_primary mr-3">Send</button>
                    <button type="button" (click)="bsModalRef.hide()"
                        class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="pushForm" class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Send Push</h4>
                </div>
                <div class="col-sm-6">
                    <button type="button" *ngIf="showSendToAll" (click)="sendpushToAll()"
                    [disabled]="!emailTemplateTitle || !emailBody" class="btn btn_primary sendEmail">Send to all</button>
                </div>
            </div>
            <form class="forms-sample" [formGroup]="sendPushForm" (submit)="onSubmitPush()">
                <div class="form-group row">
                    <label for="exampleInputUsername2" class="col-sm-2 col-form-label">To</label>
                    <div class="col-sm-10">
                        <span>{{this.uidList.length}} {{this.uidList.length > 1 ? 'Users' : 'User'}} </span>
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Title</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" formControlName="title" name="title" placeholder="title"
                            spellcheck="true">
                        <div *ngIf="formSubmitted && sendPushForm.controls.title.errors" class="has-error">
                            <span *ngIf="sendPushForm.controls.title.errors.required">title is required </span>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-12">
                        <button type="button" *ngIf="showBody" (click)="showPrecomposeMessagefn()"
                        class="btn btn-outline-primary btn-fw">Select Precomposed Message</button>

                    <button type="button" *ngIf="showPrecomposeMessage" (click)="showCustomMessagefn()"
                        class="btn btn-outline-primary btn-fw">Add Custom Message</button>
                    </div>
                    

                </div>
                <div class="form-group row" *ngIf="showBody">
                    <label for="exampleInputMobile" class="col-sm-2 col-form-label">Body</label>
                    <div class="col-sm-10">
                        <textarea class="form-control" placeholder="body" formControlName="body" name="body" rows="4"
                            spellcheck="true"></textarea>
                        <div *ngIf="formSubmitted && sendPushForm.controls.body.errors" class="has-error">
                            <span *ngIf="sendPushForm.controls.body.errors.required">body is required </span>
                        </div>
                    </div>
                </div>


                <div class="form-group row" *ngIf="showPrecomposeMessage">
                    <label for="exampleInputMobile" class="col-sm-2 col-form-label">Precomposed Message</label>
                    <div class="col-sm-10">
                        <ng-select formControlName="body" [items]="precomposedMsgArr" [virtualScroll]="true"
                            bindLabel="msg" bindValue="msg" placeholder="Select msg" [(ngModel)]="SelectedMsg">
                        </ng-select>
                        <div *ngIf="formSubmitted && sendPushForm.controls.body.errors" class="has-error">
                            <span *ngIf="sendPushForm.controls.body.errors.required">body is required </span>
                        </div>
                    </div>
                </div> -->
                <div class="form-group row">
                    <label for="template" class="col-sm-2 col-form-label">Templates</label>
                    <div class="col-sm-10">
                        <select (change)="onChangeEmailTemplate($event.target.value)" id="template" name="template" formControlName="msgTemplate" class="template">
                            <!-- <option  [value]=""> -- select an option -- </option> -->
                            <ng-template ngFor let-emailTemplate [ngForOf]="allEmailTemplates" let-i="index" >
                                <option [value]="i">{{emailTemplate.title}} : {{emailTemplate.description.split(' ').slice(0, 5).join(' ')}} {{emailTemplate.description.split(' ').length > 5 ? '  . . .':''}}</option>
                            </ng-template>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="title" class="col-sm-2 col-form-label">Title</label>
                    <div class="col-sm-10">
                    <input type="text" class="form-control" id="title"  formControlName="title" [(ngModel)]='emailTemplateTitle'>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="textarea" class="col-sm-2 col-form-label">Content</label>
                    <div class="col-sm-10" id="textarea-c"  >
                        <textarea name="" id="textarea-c"  class="form-control" cols="30" rows="10" formControlName="body" [(ngModel)]='emailBody'></textarea>
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label for="exampleInputMobile" class="col-sm-2 col-form-label">Link</label>
                    <div class="col-sm-10">
                        <input class="form-control" placeholder="link" formControlName="link" name="link" />
                        <div *ngIf="formSubmitted && sendPushForm.controls.link.errors" class="has-error">
                            <span *ngIf="sendPushForm.controls.link.errors.pattern">invalid link</span>
                        </div>
                    </div>
                </div> -->
                <div class="float-right">
                    <button type="submit" *ngIf="!showSendToAll" [disabled]="!emailTemplateTitle || !emailBody" class="btn btn_primary mr-3">Send</button>
                    <!-- <button type="submit" class="btn btn-outline-success btn-fw mr-2">Send</button> -->
                    <button type="button" (click)="bsModalRef.hide()"
                        class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>