import {
  Component,
  OnInit,
  ComponentRef,
  ComponentFactoryResolver,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { Options } from "ng5-slider";
import { Router } from "@angular/router";
import * as moment from "moment";
import { ImagePreviewComponent } from "../../shared/image-preview/image-preview.component";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SendPushEmailComponent } from "../../shared/send-push-email/send-push-email.component";
import Swal from "sweetalert2";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { ApiServiceService } from "src/app/core/api-service/api-service";
// import SimpleCrypto from "simple-crypto-js"
import * as bcrypt from "bcryptjs";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { NgxSpinnerService } from "ngx-spinner";
import { AddUserNoteComponent } from "../../shared/add-user-note/add-user-note.component";
import { UpdateUserDetailsComponent } from "../../shared/update-user-details/update-user-details.component";
import { HeaderTitleService } from "src/app/core/headerTitle/header-title.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import * as _ from "lodash";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit {
  // secretKey = "my-social-housing";
  // simpleCrypto : any = new SimpleCrypto(this.secretKey);

  // popoversMap = new Map<any, ComponentRef<SendPushEmailComponent>>();
  // popoverFactory = this.resolver.resolveComponentFactory(SendPushEmailComponent);
  // modalRef: BsModalRef;
  showPropertyFilter: Boolean = true;
  p: number = 1;
  mp: number = 1;
  popoversMap = new Map<any, ComponentRef<ImagePreviewComponent>>();
  popoverFactory = this.resolver.resolveComponentFactory(ImagePreviewComponent);
  modalRef: BsModalRef;
  userDetail;
  otherUserListingArray;
  ActiveUser: any;
  userNotesArray;
  referringAgentNotesArray;
  userDOB;
  openCloseBool: Boolean = true;
  staffUserListArray;
  indicatorPercentage = 0;
  fileToUpload: File | null = null;
  proofOfIdToUpload: File | null = null;
  proofOfIncomeToUpload: File | null = null;
  @ViewChild("fileUploader") fileUploader: ElementRef;
  removeOfficer = {
    name: "Remove Case Officer",
  };
  additionalMemberData: any;

  @Output() searchEvent = new EventEmitter<string>();
  @Output() filterEvent = new EventEmitter<any>();

  selectedCity;

  filters = {
    miles: 1000,
    closestFirst: true,
    bedroomFilter: {
      min: 0,
      max: 9,
      range: "range",
    },
    lat: "",
    long: "",
  };

  options: Options = {
    floor: 0,
    ceil: 1000,
    showSelectionBar: true,
    selectionBarGradient: {
      from: "white",
      to: "#0db9f0",
    },
  };
  searchProperty = null;
  areaOfInterestList;
  clearAssignedUser: Boolean = false;
  propertiesListArray: any[] = [];
  propertiesListArrayCopy = [];
  totalPage: any;
  rowLimit: any;
  totalCount: any = 0;
  totalReturn: any;
  sortDataArray = [];
  searchMatchedProperties = [];
  pageSize = 5;
  sortvalue = ["created", "desc"];
  sort: string = "asc";

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private resolver: ComponentFactoryResolver,
    private alertnotification: AlertNotification,
    private apiservice: ApiServiceService,
    private formBuilder: FormBuilder,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private headerTitleService: HeaderTitleService
  ) {
    this.additionalMemberData = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      DOB: ["", [Validators.required]],
      relationship: ["", [Validators.required]],
      proofOfId: ["", [Validators.required]],
      proofOfIncome: ["", [Validators.required]],
    });

    this.alertnotification.getArrayData.subscribe(async (response) => {
      if (response && this.userDetail) {
        this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
        this.userNotesArray =
          this.userDetail &&
          this.userDetail.notes &&
          this.userDetail.notes.length > 0
            ? this.userDetail.notes.reverse()
            : [];
        this.referringAgentNotesArray =
          this.userDetail &&
          this.userDetail.referringAgentNotes &&
          this.userDetail.referringAgentNotes.length > 0
            ? this.userDetail.referringAgentNotes.reverse()
            : [];
        this.userDOB = null;
        this.userDOB =
          this.userDetail.dateOfBirth && this.userDetail.dateOfBirth.seconds
            ? moment
                .unix(this.userDetail.dateOfBirth.seconds)
                .format("DD MMM YYYY")
            : "";
      }
    });

    this.getStaffUserList();
  }

  async ngOnInit() {
    this.getAreaOfInterest();
    this.headerTitleService.setTitle("User Detail");
    this.ActiveUser = JSON.parse(localStorage.getItem("UserType"));
    // console.log(this.ActiveUser);
    window.scroll(0, 0);
    if (localStorage.getItem("userDetail")) {
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      console.log(this.userDetail);
      this.userDOB = this.userDetail.dateOfBirth;
      try {
        if (
          this.userDetail.dateOfBirth &&
          this.userDetail.dateOfBirth.seconds
        ) {
          this.userDOB =
            this.userDetail.dateOfBirth && this.userDetail.dateOfBirth.seconds
              ? moment
                  .unix(this.userDetail.dateOfBirth.seconds)
                  .format("DD MMM YYYY")
              : "";
          this.userNotesArray =
            this.userDetail &&
            this.userDetail.notes &&
            this.userDetail.notes.length > 0
              ? this.userDetail.notes.reverse()
              : [];
          this.referringAgentNotesArray =
            this.userDetail &&
            this.userDetail.referringAgentNotes &&
            this.userDetail.referringAgentNotes.length > 0
              ? this.userDetail.referringAgentNotes.reverse()
              : [];
        }
      } catch (err) {
        console.log(err);
      }
      if (this.userDOB) {
        let age = Math.floor(
          (new Date().getTime() - new Date(this.userDOB).getTime()) /
            (24 * 3600 * 1000 * 365)
        );
        if (
          (!this.userDetail.age || this.userDetail.age != age) &&
          this.userDetail.dateOfBirth &&
          age
        ) {
          // update age in firebase
          this.userDetail.age = age;
          this.firebaseservice.updateUserAge(this.userDetail.id, age);
        }
      }
      var shortListProperties: any = await this.getuserCount(
        this.userDetail.email
      );
      var matchedProperties: any = await this.getMatchedShortlist(
        this.userDetail.email
      );
      this.otherUserListingArray = await this.getOtherUserList(
        "firstName",
        this.userDetail.firstName,
        this.userDetail
      );

      var lastName: any = await this.getOtherUserList(
        "lastName",
        this.userDetail.lastName,
        this.userDetail
      );
      // console.log(lastName);
      lastName && lastName.length > 0
        ? lastName.map((x) => {
            // console.log(this.otherUserListingArray.indexOf(y => y.id == x.id));
            if (this.otherUserListingArray.indexOf((y) => y.id == x.id) < 0) {
              this.otherUserListingArray.push(x);
            }
          })
        : "";
      // remove dublicate otherUser List
      this.otherUserListingArray = this.otherUserListingArray.reduce(
        (unique, o) => {
          if (
            !unique.some(
              (obj) =>
                obj.id === o.id &&
                obj.firstName === o.firstName &&
                obj.lastName === o.lastName
            )
          ) {
            unique.push(o);
          }
          return unique;
        },
        []
      );
      let tempShortlist = [];
      for (const el of Object.entries(matchedProperties)) {
        var number = parseInt(el[0]);
        if (!isNaN(number)) {
          tempShortlist.push(el[1]);
        }
      }

      this.userDetail["matchedProperties"] = tempShortlist
        ? tempShortlist.filter((x) => {
            // console.log(x, x.shortlisted, "x short");
            if (x && x.shortlisted && x.shortlisted == "true") {
              return x;
            }
          })
        : [];

      this.userDetail["shortlistedProperties"] = shortListProperties
        ? shortListProperties.filter((x) => {
            if (x && x.shortlisted && x.shortlisted == "true") {
              return x;
            }
          })
        : [];
      this.userNotesArray =
        this.userDetail &&
        this.userDetail.notes &&
        this.userDetail.notes.length > 0
          ? this.userDetail.notes.reverse()
          : [];
      // this.userNotesArray = await this.getUserNotes(this.userDetail.id);
      // console.log(this.userNotesArray);
      console.log(this.userDetail, "userdetailss");
      this.getPropertiesData(0);
    } else {
      this.router.navigate(["/admin/users"]);
    }
    if (this.ActiveUser.email == "admin@mysocialhousing.com") {
      this.clearAssignedUser = true;
    }
    if (this.ActiveUser.email == "developer@bridgehousingsolutions.co.uk") {
      this.showPropertyFilter = true;
    }

    this.calculateUserProfileStatus();

    this.userDetail["arrImgBankStatementsObj"] = [];
    if (this.userDetail.arrImgBankStatements) {
      for (const file of this.userDetail.arrImgBankStatements) {
        try {
          const newfile = file.replace(":443", "");
          let name = await this.firebaseservice.getFileNameAndType(newfile);
          var re = /(?:\.([^.]+))?$/;
          let type = re.exec(name)[1];
          console.log(type, "TYPE");

          this.userDetail["arrImgBankStatementsObj"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
        } catch (error) {
          console.log("ERROR IN SERVICE", error);
        }
      }
    }

    this.userDetail["arrImgIdProofObj"] = [];
    if (this.userDetail.arrImgIdProof) {
      for (const file of this.userDetail.arrImgIdProof) {
        try {
          // this.userDetail["arrImgIdProof"] = [];
          // let file = this.userDetail.proofOfIdFiles;
          const newfile = file.replace(":443", "");
          let name = await this.firebaseservice.getFileNameAndType(newfile);
          var re = /(?:\.([^.]+))?$/;
          let type = re.exec(name)[1];
          this.userDetail["arrImgIdProof"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
          this.userDetail["arrImgIdProofObj"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
          console.log("data =====", this.userDetail["arrImgIdProofObj"]);
        } catch (error) {
          console.log("ERROR IN SERVICE", error);
        }
      }
    } else {
      for (const file of this.userDetail.arrImgIdProof) {
        try {
          const newfile = file.replace(":443", "");
          let name = await this.firebaseservice.getFileNameAndType(newfile);
          var re = /(?:\.([^.]+))?$/;
          let type = re.exec(name)[1];
          this.userDetail["arrImgIdProofObj"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
        } catch (error) {
          console.log("ERROR IN SERVICE", error);
        }
      }
    }
    console.log("this.userDetail", this.userDetail);
    this.userDetail["arrImgIncomeProofObj"] = [];
    if (this.userDetail.arrImgIncomeProof) {
      for (const file of this.userDetail.arrImgIncomeProof) {
        try {
          // this.userDetail["arrImgIncomeProof"] = [];
          // let file = this.userDetail.proofOfIncomeFiles;
          const newfile = file.replace(":443", "");
          let name = await this.firebaseservice.getFileNameAndType(newfile);
          var re = /(?:\.([^.]+))?$/;
          let type = re.exec(name)[1];
          this.userDetail["arrImgIncomeProof"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
          this.userDetail["arrImgIncomeProofObj"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
        } catch (error) {
          console.log("ERROR IN SERVICE", error);
        }
      }
    } else {
      for (const file of this.userDetail.arrImgIncomeProof) {
        try {
          const newfile = file.replace(":443", "");
          let name = await this.firebaseservice.getFileNameAndType(newfile);
          var re = /(?:\.([^.]+))?$/;
          let type = re.exec(name)[1];
          this.userDetail["arrImgIncomeProofObj"].push({
            url: newfile,
            name,
            type: type,
            // type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
          });
        } catch (error) {
          console.log("ERROR IN SERVICE", error);
        }
      }
    }

    // profile status calculation
    // - Proof of ID
    // - Proof of Income
    // - Bank Statements
    // - Children's ID’s
    // - Supporting Evidence
  }
  async calculateUserProfileStatus() {
    let sum = 0;
    let total = 0;
    if (this.userDetail.proofOfIdFiles && this.userDetail.proofOfIncomeFiles) {
      sum += this.userDetail.proofOfIdFiles
        ? Number(this.userDetail.proofOfIdFiles.length > 0)
        : 0;
      sum += this.userDetail.proofOfIncomeFiles
        ? Number(this.userDetail.proofOfIncomeFiles.length > 0)
        : 0;
      total = 2;
    } else {
      sum += this.userDetail.arrImgIdProof
        ? Number(this.userDetail.arrImgIdProof.length > 0)
        : 0;
      sum += this.userDetail.arrImgIncomeProof
        ? Number(this.userDetail.arrImgIncomeProof.length > 0)
        : 0;
      sum += this.userDetail.arrImgBankStatements
        ? Number(this.userDetail.arrImgBankStatements.length > 0)
        : 0;

      total = 3;
    }

    // if(this.userDetail.additionalMembers){
    //   for(const member of this.userDetail.additionalMembers){
    //     if(member.relationshipToYou.toLowerCase() == "son" || member.relationshipToYou.toLowerCase() == "daughter"){
    //         total++;
    //         sum += Number(member.imgIdProofMember != "" || member.imgIncomeProofMember != "");
    //     }
    //   }
    // }
    this.indicatorPercentage = Math.round((sum / total) * 100);
    // console.log(sum, this.indicatorPercentage);
  }

  inputForm = this.formBuilder.group({
    file: null,
    fileType: "arrImgIdProof",
  });

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  async uploadFile() {
    if (!this.fileToUpload) return;
    if (
      !this.fileToUpload.name.endsWith(".png") &&
      !this.fileToUpload.name.endsWith(".jpg") &&
      !this.fileToUpload.name.endsWith(".jpeg") &&
      !this.fileToUpload.name.endsWith(".pdf") &&
      !this.fileToUpload.name.endsWith(".xls") &&
      !this.fileToUpload.name.endsWith(".xlsx") &&
      !this.fileToUpload.name.endsWith(".ppt") &&
      !this.fileToUpload.name.endsWith(".pptx") &&
      !this.fileToUpload.name.endsWith(".doc") &&
      !this.fileToUpload.name.endsWith(".docx") &&
      !this.fileToUpload.name.endsWith(".csv")
    ) {
      this.alertnotification.errorAlert("Incorrect file format");
      this.fileToUpload = null;
      this.fileUploader.nativeElement.value = null;
      return;
    }
    const fileType = this.inputForm.value.fileType;
    const userId = this.userDetail.id;
    const exsistingImages = this.userDetail[fileType];

    await this.firebaseservice.uploadImage(
      userId,
      fileType,
      exsistingImages,
      this.fileToUpload
    );
    this.alertnotification.successAlert(
      "User documentation uploaded successfully"
    );
    this.fileToUpload = null;
    this.fileUploader.nativeElement.value = null;

    await this.getUserDetailsToLocalStorage();
    await this.calculateUserProfileStatus();
    await this.ngOnInit();
  }

  deleteImage(file, fileType) {
    Swal.fire({
      text: `Do you want to delete this file?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result: any) => {
      if (result.value) {
        const userId = this.userDetail.id;
        const exsistingImages = this.userDetail[fileType];

        this.loaderService.show();
        await this.firebaseservice.deleteImage(
          userId,
          fileType,
          exsistingImages,
          file
        );
        await this.getUserDetailsToLocalStorage();
        await this.calculateUserProfileStatus();
        await this.ngOnInit();
        this.loaderService.hide();
        this.alertnotification.successAlert(
          "User document deleted successfully"
        );
      } else {
        return;
      }
    });
  }

  async getUserDetailsToLocalStorage() {
    await this.firebaseservice
      .getUserByEmail(this.userDetail.email)
      .then((result) => {
        var records = [];
        result.forEach(function (object) {
          var data = object.data();
          data["id"] = object.id;
          records.push(data);
        });
        if (records.length > 0) {
          localStorage.setItem("userDetail", JSON.stringify(records[0]));
          this.userDetail = records[0];
        }
      });
    this.loaderService.hide();
  }

  getStaffUserList() {
    this.apiservice.getRequest_b2(`/getUser`).subscribe(
      async (response: any) => {
        this.staffUserListArray = response.data;
        // this.staffUserListArray[0] = this.removeOfficer;
        // this.staffUserListArray.push(this.removeOfficer);
      },
      (error) => {
        this.alertnotification.errorAlert(error.error.message);
      }
    );
  }

  getUserNotes(id) {
    return new Promise((resolve, reject) => {
      this.apiservice
        .getRequest_b2(`/getUserNote?docId=${id}`)
        .subscribe((response: any) => {
          resolve(response.data);
        }),
        (error) => {
          this.alertnotification.errorAlert(error.message);
        };
    });
  }

  getOtherUserList(field, value, userObject) {
    console.log(field, value);
    return this.firebaseservice
      .getOtherUser(field, value)
      .then(
        function (querySnapshot) {
          var records = [];
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            if (userObject.id != doc.id) {
              records.push(data);
            }
          });
          // console.log(records, 'records******');
          records = records.filter((x) => {
            if (!x.deleted || x.deleted == false) {
              return x;
            }
          });
          this.loaderService.hide();
          return records;
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }

  getuserCount(email) {
    return new Promise((resolve, reject) => {
      this.apiservice
        .postRequest(
          "shortlistedCountByEmail.php",
          JSON.stringify({ email: email })
        )
        .subscribe((response: any) => {
          console.log(response.data, "data shortlist");
          resolve(response.data);
        }),
        (error) => {
          this.alertnotification.errorAlert(error.message);
        };
    });
  }

  getMatchedShortlist(email) {
    return new Promise((resolve, reject) => {
      this.apiservice
        .postRequest("/shortlistProperties.php?all=true", { email: email })
        .subscribe((response: any) => {
          resolve(response);
        }),
        (error) => {
          this.alertnotification.errorAlert(error.message);
        };
    });
  }

  changeDOB(value) {
    // console.log(value);
    return value && value.seconds
      ? moment.unix(value.seconds).format("DD MMM YYYY")
      : "";
  }

  changeDOBFamily(value) {
    var initDate = moment(value, "DD/MM/YYYY");
    return value ? moment(initDate).format("DD MMM YYYY") : "";
  }

  changeDOBadditionalMember(date) {
    let dateNew =
      date && date.seconds
        ? moment.unix(date.seconds).format("DD MMM YYYY")
        : date
        ? moment(date).isValid()
          ? moment(date).format("DD MMM YYYY")
          : date
        : "";
    return dateNew;
  }

  async showImage(imagePath, type) {
    if (
      type == "Proof of ID" ||
      type == "Bank Statement" ||
      type == "Proof of Income"
    ) {
      if (this.ActiveUser.downloadDoc == false) {
        return;
      }
    }
    // here ==========>>>>>> add a log for staff members 0n viewing user document
    let loggedInUserData = JSON.parse(localStorage.getItem("UserType"));
    let logData = {
      staffName: loggedInUserData.name,
      staffEmail: loggedInUserData.email,
      actionPerformed:
        "Clicked on documents for " +
        this.userDetail.firstName +
        " " +
        this.userDetail.lastName,
      dateTime: new Date(),
      documentUrl: imagePath,
      userId: this.userDetail.id,
    };
    this.firebaseservice.uploadStaffActivity(logData);
    var initialState = {
      ImageUrl: imagePath,
      proofType: type,
    };
    this.modalRef = await this.modalService.show(ImagePreviewComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
  }

  async sendEmail() {
    if (this.userDetail.email) {
      var userList = this.userDetail.email ? [`${this.userDetail.email}`] : [];
      var initialState = { userList: userList, type: "email", user: "single" };
      this.modalRef = await this.modalService.show(SendPushEmailComponent, {
        initialState,
        class: "modal-lg",
        backdrop: "static",
      });
    } else {
      Swal.fire("Hey", "This user does not have valid email", "warning");
    }
  }

  async sendPush() {
    if (this.userDetail.uid) {
      var userList = this.userDetail.uid
        ? [{ uid: this.userDetail.uid, email: this.userDetail.email }]
        : [];
      var initialState = {
        userList: userList,
        type: "push",
        user: "single",
        sendTo: "single",
      };
      this.modalRef = await this.modalService.show(SendPushEmailComponent, {
        initialState,
        class: "modal-lg",
        backdrop: "static",
      });
    } else {
      Swal.fire("Hey", "This user does not have valid push ID", "warning");
    }
  }

  copyUserLink(object) {
    if (object && object.email) {
      var password = this.generatePassword(10);
      var data = {
        object: JSON.stringify(object),
        email: object.email,
        id: object.id,
        password: password,
      };
      this.apiservice.postRequest_b2("addSharedUser", data).subscribe(
        (response) => {
          const salt = bcrypt.genSaltSync(10);
          var hashPwd = bcrypt.hashSync(password, salt);
          hashPwd = hashPwd.split("/").join(" ");
          console.log(hashPwd);
          // console.log(hashPwd);
          try {
            var url = `https://public.mysocialhousing.co.uk/msh/userdetail/${hashPwd}`;
            let textarea;
            let result;
            try {
              textarea = document.createElement("textarea");
              textarea.setAttribute("readonly", true);
              textarea.setAttribute("contenteditable", true);
              textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
              textarea.value = url;

              document.body.appendChild(textarea);

              textarea.focus();
              textarea.select();

              const range = document.createRange();
              range.selectNodeContents(textarea);

              const sel = window.getSelection();
              sel.removeAllRanges();
              sel.addRange(range);

              textarea.setSelectionRange(0, textarea.value.length);
              result = document.execCommand("copy");
            } catch (err) {
              console.error(err);
              result = null;
            } finally {
              document.body.removeChild(textarea);
            }

            // manual copy fallback using prompt
            if (!result) {
              const isMac =
                navigator.platform.toUpperCase().indexOf("MAC") >= 0;
              const copyHotkey = isMac ? "⌘C" : "CTRL+C";
              result = prompt(`Press ${copyHotkey}`, url); // eslint-disable-line no-alert
              if (!result) {
                return this.alertnotification.errorAlert("error");
              }
            }
            Swal.fire({
              position: "center",
              icon: "success",
              title: "User Profile link copied to clipboard",
              showConfirmButton: false,
              timer: 3000,
            });
          } catch (error) {
            this.alertnotification.errorAlert(error);
          }
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    } else {
      this.alertnotification.successAlert("No any user email found");
    }
  }

  convertDate(date) {
    return (
      moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY") +
      " at " +
      moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    );
  }

  generatePassword(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  oncheckUserView() {
    if (this.ActiveUser.type != "Owner" && this.ActiveUser.type != "Editor") {
      return true;
    } else {
      return false;
    }
  }

  changeDateFormat(date) {
    // 2021-02-16T06:16:03.379Z
    if (date && date.seconds) {
      return `${moment.unix(date.seconds).format("DD")}th ${moment
        .unix(date.seconds)
        .format("MMM")} ${moment.unix(date.seconds).format("YYYY")}, ${moment
        .unix(date.seconds)
        .format("HH:mm")}`;
    } else {
      return "";
    }
  }
  changeDateFormat1(date) {
    // 2021-02-16T06:16:03.379Z
    if (date && date.seconds) {
      return `${moment.unix(date.seconds).format("DD")}th ${moment
        .unix(date.seconds)
        .format("MMM")} ${moment.unix(date.seconds).format("YYYY")}`;
    } else {
      return "";
    }
  }
  async addNote() {
    var initialState = { userObject: this.userDetail };
    this.modalRef = await this.modalService.show(AddUserNoteComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
  }

  oncheckUserStatus() {
    if (this.ActiveUser.type != "Viewer") {
      return false;
    } else {
      return true;
    }
  }

  async editDetails(type, index) {
    var initialState = {
      formType: type,
      userObject: this.userDetail,
      index: index,
    };
    this.modalRef = await this.modalService.show(UpdateUserDetailsComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
  }

  additionalMemberProofsUpload(proofType, files: FileList) {
    if (proofType == "Income") {
      this.proofOfIncomeToUpload = files.item(0);
    } else if (proofType == "ID") {
      this.proofOfIdToUpload = files.item(0);
    }
  }
  async addAdditionalMember() {
    let proofIdUrl = await this.firebaseservice.uploadFile(
      this.proofOfIdToUpload
    );
    let proofIncomeUrl = await this.firebaseservice.uploadFile(
      this.proofOfIncomeToUpload
    );
    this.loaderService.show();

    let newMember = {
      dateOfBirth: new Date(this.additionalMemberData.value.DOB),
      firstName: this.additionalMemberData.value.firstName,
      lastName: this.additionalMemberData.value.lastName,
      relationshipToYou: this.additionalMemberData.value.relationship,
      imgIdProofMember: proofIdUrl,
      imgIncomeProofMember: proofIncomeUrl,
    };

    await this.firebaseservice.updateUserAdditionalMembers(this.userDetail.id, [
      newMember,
      ...this.userDetail.additionalMembers,
    ]);
    this.userDetail.additionalMembers = [
      newMember,
      ...this.userDetail.additionalMembers,
    ];
    let d = document.getElementById("close-additionalMember-modal");
    d.click();
    this.loaderService.hide();
  }

  onSelectStaff(evt) {
    if (evt) {
      const data = {
        assignedStaff: {
          name: evt.name || "",
          email: evt.email || "",
          uid: evt.uid || "",
          assignedDate: new Date(),
        },
      };
      this.loaderService.show();
      this.firebaseservice
        .updateUserStatus(this.userDetail.id, data)
        .then(async (records) => {
          // console.log("successfully updated")
          this.userDetail["assignedStaff"] = data["assignedStaff"];
          localStorage.setItem("userDetail", JSON.stringify(this.userDetail));

          this.alertnotification.successAlert("Staff assigned successfully");
          this.alertnotification.updateNoteData();
          this.loaderService.hide();
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    } else {
      const data = {
        assignedStaff: {},
      };
      this.loaderService.show();
      this.firebaseservice
        .updateUserStatus(this.userDetail.id, data)
        .then(async (records) => {
          // console.log("successfully updated")
          this.userDetail["assignedStaff"] = [];
          localStorage.setItem("userDetail", JSON.stringify(this.userDetail));
          this.alertnotification.successAlert(
            "Assigned staff removed successfully"
          );
          this.alertnotification.updateNoteData();
          this.loaderService.hide();
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    }
  }

  checkAssignedStaff(object) {
    return object && object.assignedStaff && object.assignedStaff.name
      ? object.assignedStaff.name
      : null;
  }

  getShortListedPropertyByEmail(email) {
    // this.router.navigate(['admin/shortlisted'],{ queryParams: { 'email': email}});

    this.router.navigate([]).then((result) => {
      window.open(`admin/shortlisted/?email=${email}`, "_blank");
    });
  }

  onDelete(index) {
    Swal.fire({
      title: `Do you want to delete this Note?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value == true) {
        this.userDetail.referringAgentNotes.splice(index, 1);
        this.firebaseservice
          .updateUserStatus(this.userDetail.id, this.userDetail)
          .then(async (records) => {
            this.alertnotification.successAlert("Note successfully deleted");
            this.loaderService.hide();
            localStorage.setItem("userDetail", JSON.stringify(this.userDetail));
          })
          .catch((error) => {
            this.loaderService.hide();
            console.log(error);
          });
      } else {
        return;
      }
    });
  }

  onchangeCheckbox(evt) {
    if (evt.target.checked) {
      this.filters.closestFirst = true;
    } else {
      this.filters.closestFirst = false;
    }
  }

  onChange(city) {
    if (city && city.lat && city.lng) {
      this.filters.closestFirst = false;
      this.filters.lat = city.lat;
      this.filters.long = city.lng;
    } else {
      this.filters.lat = "";
      this.filters.long = "";
      this.filters.closestFirst = true;
      //this.propertiesListArrayCopy = [];
      return;
    }
  }

  getAreaOfInterest() {
    var data = {};
    this.apiservice.postRequest("getCityList.php", data).subscribe(
      (result: any) => {
        console.log("Select city");
        console.log(result.data);
        this.areaOfInterestList = result.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onChangeMiles(evt) {
    this.filters.miles = evt.value;
  }

  onChangeBedType() {
    let bedroomDetails = this.filters.bedroomFilter;
    if (bedroomDetails.min === bedroomDetails.max) {
      this.filters.bedroomFilter.range = "notRange";
    } else {
      this.filters.bedroomFilter.range = "range";
    }
  }

  onClickReset() {
    this.filters = {
      miles: 1000,
      closestFirst: true,
      bedroomFilter: {
        min: 0,
        max: 9,
        range: "range",
      },
      lat: "",
      long: "",
    };
    this.searchProperty = null;
    this.selectedCity = null;
    this.propertiesListArrayCopy = [];
    this.sendFiltersData();
    this.sendSearchText();
  }

  sendSearchText() {
    //console.log("yes");
    this.searchEvent.emit(this.searchProperty);
  }

  isDisabled(value, type) {
    let bedroomDetails = this.filters.bedroomFilter;
    if (type == "min" && value > bedroomDetails.max) {
      return true;
    } else if (type == "max" && value < bedroomDetails.min) {
      return true;
    } else {
      return false;
    }
  }

  onClickSave() {
    this.sendFiltersData();
  }

  sendFiltersData() {
    if (
      this.propertiesListArrayCopy.length == 0 &&
      this.propertiesListArray.length > 0
    ) {
      this.propertiesListArrayCopy = this.propertiesListArray.slice(0);
    }

    this.filterEvent.emit(this.filters);
    this.searchProperty = this.filters;
    this.mp = 1;
    if (this.filters.bedroomFilter.range == "range") {
      this.propertiesListArrayCopy = this.sortDataArray.filter((x) => {
        if (
          x.roomtype >= this.filters.bedroomFilter.min &&
          x.roomtype <= this.filters.bedroomFilter.max
        ) {
          return x;
        }
      });
    } else {
      this.propertiesListArrayCopy = this.sortDataArray.filter((x) => {
        if (x.roomtype == this.filters.bedroomFilter.min) {
          return x;
        }
      });
    }

    if (this.filters.closestFirst) {
      this.propertiesListArrayCopy = _.sortBy(this.propertiesListArrayCopy, [
        function (o) {
          return o.distance;
        },
      ]);
    }

    if (this.filters.lat && this.filters.long) {
      _.map(this.propertiesListArrayCopy, (obj) => {
        if (obj.lat && obj.lng) {
          obj.distance = this.calculateDistance(
            obj.lat,
            obj.lng,
            this.filters.lat,
            this.filters.long
          );
        }
      });
      this.propertiesListArrayCopy = _.sortBy(this.propertiesListArrayCopy, [
        function (o) {
          return o.distance;
        },
      ]);
    } else {
      _.map(this.propertiesListArrayCopy, (obj) => {
        if (obj.lat && obj.lng) {
          obj.distance = this.calculateDistance(
            obj.lat,
            obj.lng,
            this.userDetail.latitude,
            this.userDetail.longitude
          );
        }
      });

      this.propertiesListArrayCopy = _.sortBy(this.propertiesListArrayCopy, [
        function (o) {
          return o.distance;
        },
      ]);

      this.propertiesListArrayCopy = _.filter(
        this.propertiesListArrayCopy,
        (o) => {
          return o.distance <= 1000;
        }
      );
    }

    if (this.filters.miles < 1000) {
      this.propertiesListArrayCopy = _.filter(
        this.propertiesListArrayCopy,
        (o) => {
          return o.distance <= this.filters.miles;
        }
      );
    }

    this.totalCount = this.propertiesListArrayCopy.length;
    console.log(this.propertiesListArrayCopy);
  }

  getPropertiesData(number) {
    this.apiservice
      .getRequest(`/filter_api.php?all=true&email='${this.userDetail.email}'`)
      .subscribe(
        (response: any) => {
          this.propertiesListArray = [];
          this.propertiesListArrayCopy = [];
          Object.keys(response).map((x: any) => {
            if (x == "totalpage") {
              this.totalPage = response[x];
            } else if (x == "rowlimit") {
              this.rowLimit = response[x];
            } else if (x == "pagenum") {
            } else if (x == "totalcount") {
              this.totalCount = response[x];
            } else if (x == "totalreturn") {
              this.totalReturn = response[x];
            } else {
              response[x]["distance"] = this.calculateDistance(
                response[x].lat,
                response[x].lng,
                this.userDetail.latitude,
                this.userDetail.longitude
              );
              //console.log(this.userDetail.latitude + " " + this.userDetail.longitude);
              response[x]["distanceFromCity"] = null;
              let allInfo =
                response[x]?.title +
                response[x]?.desc +
                response[x]?.property_address +
                response[x]?.feature;
              allInfo = allInfo;
              if (allInfo.includes(55)) {
                response[x]["over55"] = true;
              }

              this.propertiesListArray.push(response[x]);
              if (response[x]["distance"] != "NaN") {
                this.propertiesListArrayCopy.push(response[x]);
              }
            }
          });

          this.totalCount = this.propertiesListArrayCopy.length;
          this.sortDataArray = this.propertiesListArray;
          //this.propertiesListArrayCopy = this.propertiesListArray.slice(0);
          //this.searchMatchedProperties = this.propertiesListArray.slice(0);
          //console.log(this.propertiesListArray);
          // if(localStorage.getItem("pageInfo")) {
          //   let pageInfo =  JSON.parse(localStorage.getItem("pageInfo"));
          //   this.p = pageInfo.page;
          //   console.log("----pageInfo.propertyIndex---------")
          //   console.log(pageInfo.propertyIndex)
          //   setTimeout(() => {
          //     this.scrollToParticularPageIndex(pageInfo.propertyIndex)
          //   }, 500);
          // }
        },
        (error) => {
          // console.log(error.error);
          this.alertnotification.errorAlert(error.error.message);
        }
      );
  }

  // scrollToParticularPageIndex(id){
  //   const element = document.getElementById(id); // id of the scroll to element
  //   element.scrollIntoView();
  //   if(localStorage.getItem("pageInfo")) {
  //     localStorage.removeItem("pageInfo");
  //   }
  // }

  calculateDistance(houseLat, houseLong, userLat, userLong) {
    if (userLat == houseLat && userLong == houseLong) {
      return 0;
    } else {
      var raduserLat = (Math.PI * userLat) / 180;
      var radhouseLat = (Math.PI * houseLat) / 180;
      var theta = userLong - houseLong;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(raduserLat) * Math.sin(radhouseLat) +
        Math.cos(raduserLat) * Math.cos(radhouseLat) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = Math.round((dist + 0.16) * 10) / 10;
      return dist.toFixed(1);
    }
  }

  checkImage(property) {
    if (property.image_type == "1") {
      var path = `${environment.imageUrl}/${property.image}`;
      return path;
    } else {
      return property.image;
    }
  }

  clickPagination() {
    window.scroll(0, 20);
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }

  checkRoomType(value) {
    return value && value > 0 ? value : "N/A";
  }

  convertPropertyDate(date) {
    return (
      moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY") +
      " at " +
      moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    );
  }

  viewPropertyDetail(object, i) {
    let data = {
      propertyId: object.id,
      details: JSON.stringify(object),
    };
    this.apiservice
      .postRequest_b2("addSharedProperty", data)
      .subscribe((response) => {
        window.open("/msh/propertydetail/" + object.id, "_blank");
      });
  }

  trackDownloadDocument(docUrl) {
    // here ==========>>>>>> add a log for staff members 0n viewing user document
    let loggedInUserData = JSON.parse(localStorage.getItem("UserType"));
    let logData = {
      staffName: loggedInUserData.name,
      staffEmail: loggedInUserData.email,
      actionPerformed:
        "Clicked on documents for " +
        this.userDetail.firstName +
        " " +
        this.userDetail.lastName,
      dateTime: new Date(),
      documentUrl: docUrl,
      userId: this.userDetail.id,
    };
    this.firebaseservice.uploadStaffActivity(logData);
  }

  markUserAsVerified(status) {
    const msg = !status ? "unverified" : "verified";
    Swal.fire({
      text: `Do you want to mark this user as ${msg}?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result: any) => {
      if (result.value) {
        this.loaderService.show();
        this.userDetail.verified = status;
        console.log("=>", this.userDetail.verified);
        if (status) {
          this.firebaseservice.updateMarkAsVerified(this.userDetail.id, status);
        } else {
          this.userDetail.referringAgent = "N/A";
          this.firebaseservice.updateMarkAsUnVerified(
            this.userDetail.id,
            status
          );
        }
        this.loaderService.hide();
        this.alertnotification.successAlert("User status updated successfully");
        localStorage.setItem("userDetail", JSON.stringify(this.userDetail));
      } else {
        return;
      }
    });
  }

  shortListOrFavorite(propertyDetail, index) {
    Swal.fire({
      text: `Do you want to shortlist this property for the user ?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result: any) => {
      if (result.value) {
        if (this.modalRef) {
          this.modalRef.hide();
        }
        const body = {
          email: this.userDetail.email,
          id: propertyDetail.id,
          shortlisted: false,
        };
        this.apiservice.postRequest(`addfavorite.php`, body).subscribe(
          (response: any) => {
            let msg = "Property shortlisted successfully";
            if (response.messeage === "Updated success.") {
              msg = "Property already shortlisted for this user";
            }
            this.alertnotification.successAlert(msg);
            this.propertiesListArray[index].shortlist_by_RA = "1";
          },
          (error) => {
            // console.log(error.error);
            this.alertnotification.errorAlert(error.error.message);
          }
        );
      } else {
        return;
      }
    });
  }
}
