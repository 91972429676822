import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";
// import * as admin from "firebase-admin";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize, skip } from "rxjs/operators";
import { ApiServiceService } from "src/app/core/api-service/api-service";

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class FirebaseQueryService {
  DB = firebase.firestore();
  storageFire = firebase.storage();

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private apiservice: ApiServiceService,
    private loaderService: NgxSpinnerService
  ) {}

  async userUpload(userData): Promise<any> {
    var randomPassword = Math.random().toString(36).slice(-8);
    let isFailed = false;
    let failedEmail;
    if (userData.email) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(userData.email, randomPassword)
        .then(async (res) => {
          userData["userId"] = res.user.uid;
          await this.DB.collection("users")
            .doc(userData["userId"])
            .set(userData);
          await firebase.auth().sendPasswordResetEmail(userData.email);
        })
        .catch((res) => {
          console.log(res);
          isFailed = true;

          failedEmail = userData.email;
        });
    }

    return { isFailed, failedEmail };
  }

  async changePassword(newPassword) {
    const user = firebase.auth().currentUser;
    user
      .updatePassword(newPassword)
      .then((res) => {
        // Update successful.
        return true;
      })
      .catch((error) => {
        // An error ocurred
        // ...
        return false;
      });
  }

  async getFileNameAndType(file): Promise<any> {
    const reference = this.storageFire.refFromURL(file);

    return reference.name;
  }

  async uploadFile(file: File) {
    this.loaderService.show();
    try {
      var n = Date.now();
      const metadata = {
        contentType: file.type,
      };

      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        var filePath = `images/${file.name}-${n}`;
      } else {
        var filePath = `files/${file.name}-${n}.${file.name.split(".").pop()}`;
      }
      var fileRef = this.storage.ref(filePath);

      const uploadTaskSnapshot = await fileRef.put(file, metadata);

      const url = await uploadTaskSnapshot.ref.getDownloadURL();
      this.loaderService.hide();
      return url;
    } catch (error) {
      console.log("ERR ===", error);
      alert("Image uploading failed!");
      this.loaderService.hide();
    }
  }

  async uploadImage(userId, fileType, exsistingImages, file: File) {
    this.loaderService.show();
    try {
      var n = Date.now();
      const metadata = {
        contentType: file.type,
      };

      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        var filePath = `images/${file.name}-${n}`;
      } else {
        var filePath = `files/${file.name}-${n}.${file.name.split(".").pop()}`;
      }
      var fileRef = this.storage.ref(filePath);

      const uploadTaskSnapshot = await fileRef.put(file, metadata);

      const url = await uploadTaskSnapshot.ref.getDownloadURL();

      if (exsistingImages) {
        let newData = {
          [fileType]: [url, ...exsistingImages],
        };
        await this.DB.collection("users").doc(userId).update(newData);
      } else {
        let newData = {
          [fileType]: [url],
        };
        await this.DB.collection("users").doc(userId).update(newData);
      }
      this.loaderService.hide();
    } catch (error) {
      console.log("ERR ===", error);
      alert("Image uploading failed!");
      this.loaderService.hide();
    }
  }

  async deleteImage(userId, fileType, exsistingImages, url) {
    this.loaderService.show();

    if (exsistingImages) {
      const index = exsistingImages.indexOf(url);
      exsistingImages.splice(index, 1);
      let newData = {
        [fileType]: [...exsistingImages],
      };
      await this.DB.collection("users").doc(userId).update(newData);
      this.storageFire
        .refFromURL(url)
        .delete()
        .catch((err) => console.log(err));
    }

    this.loaderService.hide();
  }

  createUser(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  signInUser(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  verifyToken(token) {
    // return admin.auth().verifyToken(token);
  }

  currentUser() {
    return firebase.auth().currentUser;
  }

  getUserList() {
    this.loaderService.show();
    return this.DB.collection("users").orderBy("createdAt", "desc").get();
  }
  /*
  async updateUserScript(){
    // this.DB.collection('users').doc("YJlb0sVxVU2jJ7aIpmi4").set({
    //       "firstNameRA": "dummy firstname",
    //       "lastNameRA":  "dummy lastname",
    //       "emailRA":  "dummy email",
    //       "phoneNumberRA":  "dummy phone"
    //     },{merge:true});
    let allUsers = await this.DB.collection('users').orderBy('createdAt','desc').get().then(async(result)=>{
      // const promises = [];
      // let index=0;
      result.forEach(async(doc) => {
        // if(index>0){
        console.log(doc.id, '=>', doc.data().firstNameFC, doc.data().lastNameFC, doc.data().email1,doc.data().phoneNumber2 );

        // console.log(doc.id, '=>', doc.data().firstNameFC );
          // await this.DB.collection('users').doc(doc.id).set({
          //   "firstNameFC": '',
          //   "lastNameFC":  '',
          //   "email1":  '',
          //   "phoneNumber2":  ''
          // },{merge:true});
          // }
          // index++;
        });
        // return Promise.all(promises)
    });
    // allUsers.forEach(doc => {
    //     console.log(doc.id, '=>', doc.data().firstNameFC, doc.data().firstNameRA, doc.data().lastNameRA,doc.data().emailRA ,doc.data().phoneNumberRA );
    //   });
      // for (let index = 0; index < allUsers.size; index++) {
      //   const doc = allUsers[index];

      // console.log( doc );
      // await this.DB.collection('users').doc(doc.id).set({
      //   "firstNameRA": doc.data().firstNameFC?doc.data().firstNameFC:'',
      //   "lastNameRA":  doc.data().lastNameFC?doc.data().lastNameFC:'',
      //   "emailRA":  doc.data().email1?doc.data().email1:'',
      //   "phoneNumberRA":  doc.data().phoneNumber2?doc.data().phoneNumber2:''
      // },{merge:true})

      // }
    // allUsers.forEach(async(doc) => {
    //   console.log(doc.id, '=>', doc.data().firstNameFC );
    //   await this.DB.collection('users').doc(doc.id).set({
    //     "firstNameRA": doc.data().firstNameFC?doc.data().firstNameFC:'',
    //     "lastNameRA":  doc.data().lastNameFC?doc.data().lastNameFC:'',
    //     "emailRA":  doc.data().email1?doc.data().email1:'',
    //     "phoneNumberRA":  doc.data().phoneNumber2?doc.data().phoneNumber2:''
    //   },{merge:true})
    // });
  }

  */

  getStatus() {
    return this.DB.collection("users_status").get();
  }

  getUserbyref(ref) {
    this.loaderService.show();
    return this.DB.collection("users").doc(ref).get();
  }

  async getUserByEmail(email) {
    return await this.DB.collection("users").where("email", "==", email).get();
  }

  passwordreset(email) {
    this.loaderService.show();
    return firebase.auth().sendPasswordResetEmail(email);
  }

  searchByRentArrears(value) {
    return this.DB.collection("users")
      .where("referringAgent", "==", value)
      .get();
  }

  filterByAge(value, range) {
    if (range == "range") {
      return this.DB.collection("users")
        .where("age", ">=", value[0])
        .where("age", "<=", value[1])
        .get();
    } else if (range == "=") {
      return this.DB.collection("users").where("age", "==", value[0]).get();
    } else {
      return this.DB.collection("users").where("age", ">", value[0]).get();
    }
  }

  filterByBed(value) {
    return this.DB.collection("users").where("bedroomSize", "==", value).get();
  }

  filterByAreaOfInterest(value) {
    this.loaderService.show();
    return this.DB.collection("users")
      .where("areaOfInterest", "array-contains-any", value)
      .get();
  }

  updateUserStatus(ref, value) {
    return this.DB.collection("users").doc(ref).update(value);
  }

  updateUserReferringAgent(ref, value) {
    return this.DB.collection("users").doc(ref).update(value);
  }

  updateUserAge(ref, age) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ age: age }, { merge: true });
  }
  updateUserAdditionalMembers(ref, additionalMembers) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ additionalMembers: additionalMembers }, { merge: true });
  }
  getOtherUser(field, value) {
    return this.DB.collection("users").where(field, "==", value).get();
  }

  addNotesArray(doc, data) {
    return this.DB.collection("users")
      .doc(doc)
      .update({
        referringAgentNotes: firebase.firestore.FieldValue.arrayUnion(data),
      });
  }

  addStatusArray(data) {
    return this.DB.collection("users_status").add(data);
  }

  deleteStatus(ref, value) {
    return this.DB.collection("users_status").doc(ref).update(value);
  }

  getStaffLinkedUser(email) {
    this.loaderService.show();
    return this.DB.collection("users")
      .where("assignedStaff.email", "==", email)
      .get();
  }

  searchUserWithDate(value, offset, limit) {
    this.loaderService.show();
    var date1 = value[0];
    var date2 = value[1];
    console.log(date1, date2, "date");
    // if(limit == 'All') {
    return this.DB.collection("users")
      .where("createdAt", ">=", moment(date1).startOf("day").toDate())
      .where("createdAt", "<=", moment(date2).endOf("day").toDate())
      .orderBy("createdAt", "desc")
      .startAfter(offset)
      .get();
    // }else{
    //   return this.DB.collection("users").where('createdAt','>=',date1).where('createdAt','<=',date2).orderBy('createdAt','desc').startAfter(offset).limit(limit).get()
    // }
  }

  // for staff members tracking
  uploadStaffActivity(logData) {
    this.apiservice.getRequest_b2("/notificationCountInc").subscribe();
    return this.DB.collection("staff_activities").add(logData);
  }

  getStaffActivities() {
    return this.DB.collection("staff_activities")
      .orderBy("dateTime", "desc")
      .get();
  }

  getUserPropertyStatusActivitiesForRA() {
    return this.DB.collection("user_property_status")
      .where("markAsRead", "==", false)
      .orderBy("createdAt", "desc")
      .get();
  }

  getUserPropertyStatusForRA(agent) {
    return this.DB.collection("user_property_status")
      .where("referringAgent", "==", agent)
      .orderBy("createdAt", "desc")
      .get();
  }

  updatePropertyStatusLog(ref) {
    return this.DB.collection("user_property_status")
      .doc(ref)
      .update({ markAsRead: true });
  }

  updateMarkAsVerified(ref, value) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ verified: value, verifiedDate: new Date() }, { merge: true });
  }
  updateMarkAsUnVerified(ref, value) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ verified: value, referringAgent: "N/A" }, { merge: true });
  }
}
