import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { HeaderTitleService } from "src/app/core/headerTitle/header-title.service";
import Swal from "sweetalert2";
import * as moment from "moment";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Options } from "ng5-slider";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, Validators } from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { UserSharedDataServices } from "src/app/core/api-service/user-shared-service";
import { Xliff2 } from "@angular/compiler";

@Component({
  selector: "app-properties",
  templateUrl: "./properties.component.html",
  styleUrls: ["./properties.component.scss"],
})
export class PropertiesComponent implements OnInit {
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  filterProperties = ["Show Recent Properties", "Show All Properties"];
  propertiesListArray: any[] = [];
  p: number = 1;
  totalPage: any;
  rowLimit: any;
  pagenum: any = 1;
  totalCount: any = 0;
  totalReturn: any;
  sortvalue = ["created", "desc"];
  sort: string = "asc";
  paginationCount;
  filterAgeContainer = [10, 10];
  searchBedButton: boolean = true;
  bedFilterArray = [];
  sortDataArray: any[];
  providerArrayList: any;
  range;
  bedroom1 = null;
  bedroom2 = null;
  providerCheck = null;
  propertyCheck = null;
  searchValue;
  propertyCount: number;
  selectedPropertyIDs = [];
  ActiveUser: any;
  selectAllModel: Boolean = false;
  checkBoxBool: Boolean = true;
  staffUserListArray;
  removeOfficer = {
    name: "Remove Case Officer",
  };
  filterCaseofficerArr;
  exportdata;
  isFilterShow: Boolean = false;
  clearAssignedUser: Boolean = false;
  bedFilter = { value: {}, filtered: false };
  providerFilter = { value: {}, filtered: false };
  officerFilter = { value: {}, filtered: false };
  propertDetail: any;
  filters = {
    miles: 1000,
    closestFirst: true,
    bedroomFilter: {
      min: 0,
      max: 9,
      range: "range",
    },
  };
  options: Options = {
    floor: 0,
    ceil: 1000,
    showSelectionBar: true,
    selectionBarGradient: {
      from: "white",
      to: "#0db9f0",
    },
  };

  refreshButtonStatus: Boolean = false;
  selectedProgressColor;
  searchUser = null;
  allUsersPush = [];
  usersListArray: any[];
  allUsersEmail = [];
  selectedUsersEmail = [];
  selectedUsersPush = [];
  sendMailData: any;
  selectedPropertiesIndexes = [];
  allcontentData;
  emailTemplateTitle = "";
  tempSelectedPropertiesIndexes;
  selectedPropertydata;
  recipient;
  textcontentData;
  userEmailList: any[];
  emailBody: any;
  p_popup: number = 1;
  property;
  ActiveAgent;
  RAuserlist = [];
  showFilter;
  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private router: Router,
    private headerTitleService: HeaderTitleService,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private userServices: UserSharedDataServices
  ) {
    this.sendMailData = this.formBuilder.group({
      emails: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      content: ["", [Validators.required]],
      textcontent: ["", [Validators.required]],
    });
    this.alertnotification.currentMessage.subscribe((result) => {
      this.propertyCount = result;
    });

    this.alertnotification.filteredData.subscribe((x) => {
      this.exportdata = x;
    });

    this.getStaffUserList();
  }

  async ngOnInit() {
    this.headerTitleService.setTitle("Properties");
    window.scroll(0, 0);
    this.ActiveUser = JSON.parse(localStorage.getItem("UserType"));
    this.ActiveAgent = JSON.parse(localStorage.getItem("ActiveAgent"));
    let rauserlist = [];
    this.userServices
      .getUserListServices(this.ActiveAgent.name)
      .then(async function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          var data = doc.data();
          data["id"] = doc.id;
          rauserlist.push(data);
        });
      });
    this.RAuserlist = rauserlist;
    for (var i = 1; i <= 9; i++) {
      if (i == 1) {
        this.bedFilterArray.push(`${i} Bedroom`);
      } else {
        this.bedFilterArray.push(`${i} Bedrooms`);
      }
    }
    await this.getPropertiesData(this.pagenum);
    if (this.ActiveUser.email == "admin@mysocialhousing.com") {
      this.clearAssignedUser = true;
    }
  }

  getStaffUserList() {
    this.apiservice.getRequest_b2(`/getUser`).subscribe(
      async (response: any) => {
        this.filterCaseofficerArr = response.data.slice(0);
        this.staffUserListArray = response.data;
      },
      (error) => {
        this.alertnotification.errorAlert(error.error.message);
      }
    );
  }

  getProvidersList() {
    this.apiservice.getRequest(`/providers.php`).subscribe(
      (response) => {
        this.providerArrayList = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getPropertiesData(number) {
    this.apiservice.getRequest(`/api.php?all=true&allproperty=true`).subscribe(
      (response: any) => {
        this.propertiesListArray = [];
        console.log(response);
        Object.keys(response).map((x) => {
          if (x == "totalpage") {
            this.totalPage = response[x];
          } else if (x == "rowlimit") {
            this.rowLimit = response[x];
          } else if (x == "pagenum") {
          } else if (x == "totalcount") {
            this.totalCount = response[x];
          } else if (x == "totalreturn") {
            this.totalReturn = response[x];
          } else {
            this.propertiesListArray.push(response[x]);
          }
        });
        let myData = [];
        this.propertiesListArray.map((item) => {
          if (item.referring_agents == this.ActiveAgent.name) {
            myData.push(item);
          }
        });
        console.log(myData);
        this.sortDataArray = myData;
        this.propertiesListArray = myData;
        console.log(this.propertiesListArray.length);
        this.getProvidersList();
      },
      (error) => {
        this.alertnotification.errorAlert(error.error.message);
      }
    );
    console.log(this.propertiesListArray);
  }

  getAllPropertiesData() {
    this.apiservice
      .getRequest(`/api.php?all=true&allproperty=true&totalProperties=true`)
      .subscribe(
        (response: any) => {
          this.propertiesListArray = [];
          console.log(response);
          Object.keys(response).map((x) => {
            if (x == "totalpage") {
              this.totalPage = response[x];
            } else if (x == "rowlimit") {
              this.rowLimit = response[x];
            } else if (x == "pagenum") {
            } else if (x == "totalcount") {
              this.totalCount = response[x];
            } else if (x == "totalreturn") {
              this.totalReturn = response[x];
            } else {
              this.propertiesListArray.push(response[x]);
            }
          });
          this.sortDataArray = this.propertiesListArray;
          console.log(this.propertiesListArray.length);
          this.getProvidersList();
        },
        (error) => {
          this.alertnotification.errorAlert(error.error.message);
        }
      );
    console.log(this.propertiesListArray);
  }

  clickPagination() {
    window.scroll(0, 0);
    this.selectedPropertyIDs = [];
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectAllModel = false;
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
  }

  convertDate(date) {
    return (
      moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY") +
      " at " +
      moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
    );
  }

  viewPropertyDetail(object, i) {
    console.log(object);
    let data = {
      propertyId: object.id,
      details: JSON.stringify(object),
    };
    this.apiservice.postRequest_b2("addSharedProperty", data).subscribe(
      (response) => {
        this.router.navigate([]).then((result) => {
          window.open(`/msh/propertydetail/${object.id}`, "_blank");
        });
      },
      (error) => {
        this.alertnotification.errorAlert(error.message);
      }
    );
  }

  editPropertyDetail(object) {
    localStorage.setItem("Editproperty", JSON.stringify(object));
    this.router.navigate(["/admin/updateProperty"]);
  }

  checkRoomType(value) {
    return value && value > 0 ? value : "N/A";
  }

  onChangeBed(evt, index) {
    var value = evt ? evt.split(" ")[0] : null;
    this.searchBedButton = false;
    if (!evt) {
      if (this.filterAgeContainer.indexOf(10) != -1) {
        this.filterAgeContainer[index] = 10;
        this.range = "";
        this.propertiesListArray = this.sortDataArray;
      } else {
        this.filterAgeContainer[index] = 10;
        if (this.filterAgeContainer[1] == 10) {
          this.range = "min";
        } else {
          this.range = "max";
        }
      }
    } else {
      if (index == 0 && this.filterAgeContainer[1] == 10) {
        this.range = "min";
        this.filterAgeContainer[index] = parseInt(value);
      } else if (index == 1 && this.filterAgeContainer[0] == 10) {
        this.range = "max";
        this.filterAgeContainer[index] = parseInt(value);
      } else if (index == 0 && this.filterAgeContainer[1] !== 10) {
        this.range = "range";
        this.filterAgeContainer[index] = parseInt(value);
      } else if (index == 1 && this.filterAgeContainer[0] !== 10) {
        this.range = "range";
        this.filterAgeContainer[index] = parseInt(value);
      } else {
      }
    }
    this.onChangebedFilter();
  }

  onChangebedFilter() {
    this.propertiesListArray = this.sortDataArray;

    if (this.filterAgeContainer.length == 0) {
      this.bedFilter.filtered = false;
      return;
    } else {
      this.bedFilter.filtered = true;

      if (this.range == "range") {
        this.bedFilter.value = this.filterAgeContainer;
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return (
            this.filterAgeContainer[0] <= x.roomtype &&
            this.filterAgeContainer[1] >= x.roomtype
          );
        });
      } else if (this.range == "min") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[0] <= x.roomtype;
        });
      } else if (this.range == "max") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[1] >= x.roomtype;
        });
      }
    }

    if (this.officerFilter.filtered) {
      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        if (
          x &&
          x.assigned_staff &&
          JSON.parse(x.assigned_staff) &&
          JSON.parse(x.assigned_staff)["email"] ==
            this.officerFilter.value["email"]
        ) {
          return x;
        }
      });
    }

    if (this.providerFilter.filtered) {
      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        return this.providerFilter.value == x.provider;
      });
    }
  }

  onChangeProviderFilter(evt) {
    this.providerFilter.value = evt;
    this.propertiesListArray = this.sortDataArray;

    if (evt) {
      this.providerFilter.filtered = true;

      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        return evt == x.provider;
      });
    } else {
      this.providerFilter.filtered = false;
    }

    if (this.officerFilter.filtered) {
      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        if (
          x &&
          x.assigned_staff &&
          JSON.parse(x.assigned_staff) &&
          JSON.parse(x.assigned_staff)["email"] ==
            this.officerFilter.value["email"]
        ) {
          return x;
        }
      });
    }

    if (this.bedFilter.filtered) {
      if (this.range == "range") {
        this.bedFilter.value = this.filterAgeContainer;
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return (
            this.filterAgeContainer[0] <= x.roomtype &&
            this.filterAgeContainer[1] >= x.roomtype
          );
        });
      } else if (this.range == "min") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[0] <= x.roomtype;
        });
      } else if (this.range == "max") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[1] >= x.roomtype;
        });
      }
    }
  }

  onChangePropertyFilter(evt) {
    console.log(evt);
    if (evt === this.filterProperties[0] || evt === undefined) {
      console.log("recent");
      this.getPropertiesData(this.pagenum);
    } else if (evt === this.filterProperties[1]) {
      console.log("all");
      this.getAllPropertiesData();
    }
  }

  onChangeAssignedUsers(evt) {
    this.p = 1;
    this.officerFilter.value = evt;
    this.propertiesListArray = this.sortDataArray;

    if (evt) {
      this.officerFilter.filtered = true;

      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        if (
          x &&
          x.assigned_staff &&
          JSON.parse(x.assigned_staff) &&
          JSON.parse(x.assigned_staff)["email"] == evt.email
        ) {
          return x;
        }
      });
    } else {
      this.officerFilter.filtered = false;
    }

    if (this.providerFilter.filtered) {
      this.propertiesListArray = this.propertiesListArray.filter((x) => {
        return this.providerFilter.value == x.provider;
      });
    }

    if (this.bedFilter.filtered) {
      if (this.range == "range") {
        this.bedFilter.value = this.filterAgeContainer;
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return (
            this.filterAgeContainer[0] <= x.roomtype &&
            this.filterAgeContainer[1] >= x.roomtype
          );
        });
      } else if (this.range == "min") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[0] <= x.roomtype;
        });
      } else if (this.range == "max") {
        this.propertiesListArray = this.propertiesListArray.filter((x) => {
          return this.filterAgeContainer[1] >= x.roomtype;
        });
      }
    }
  }

  clearSearch() {
    this.searchValue = null;
  }

  typeSearch() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedPropertyIDs = [];
    this.p = 1;
  }

  deletePropertyObject(obj) {
    Swal.fire({
      text: `Do you want to delete this property`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result: any) => {
      if (result.value) {
        var data = {
          id: obj.id,
        };
        this.apiservice
          .postRequest("/delete.php", data)
          .subscribe(async (result) => {
            this.alertnotification.successAlert("Deleted Successfully");
            this.propertiesListArray = [];
            var data = await new Promise((resolve, reject) => {
              setTimeout(function () {
                resolve(true);
              }, 1000);
            });
            this.getPropertiesData(0);
          }),
          (error) => {
            console.log(error);
            this.alertnotification.errorAlert(error.message);
          };
      } else {
        return;
      }
    });
  }

  checkCheckBoxesCount() {
    this.checkBoxBool = true;
    this.checkboxes.forEach((element) => {
      if (element.nativeElement.checked == false) {
        this.checkBoxBool = false;
      }
    });

    this.checkBoxBool == false
      ? (this.selectAllModel = false)
      : (this.selectAllModel = true);
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedPropertyIDs = [];
  }

  deleteproperties() {
    const text =
      this.selectedPropertyIDs.length > 1 ? "properties" : "property";

    Swal.fire({
      text: `Do you want to delete selected ${text}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result: any) => {
      if (result.value) {
        var data = {
          id: this.selectedPropertyIDs,
        };
        this.apiservice
          .postRequest(`/delete.php`, data)
          .subscribe(async (result) => {
            this.alertnotification.successAlert("Deleted Successfully");
            this.propertiesListArray = [];
            var data = await new Promise((resolve, reject) => {
              setTimeout(function () {
                resolve(true);
              }, 1000);
            });
            this.getPropertiesData(0);
          }),
          (error) => {
            console.log(error);
            this.alertnotification.errorAlert(error.message);
          };
      } else {
        return;
      }
    });
  }

  oncheckUserView() {
    if (this.ActiveUser.type != "Owner" && this.ActiveUser.type != "Editor") {
      return true;
    } else {
      return false;
    }
  }
  oncheckUserDelete() {
    if (this.ActiveUser.type != "Owner") {
      return true;
    } else {
      return false;
    }
  }

  oncheckUserEdit() {
    if (this.ActiveUser.type == "Viewer") {
      return true;
    } else {
      return false;
    }
  }

  checkImage(property) {
    if (property.image_type == "1") {
      var path = `${environment.imageUrl}/${property.image}`;
      return path;
    } else {
      return property.image;
    }
  }

  onCheckAll(evt) {
    if (evt.target.checked) {
      this.selectAllModel = true;
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = this.selectAllModel;
      });
      let limit = 100;
      let offset = this.p;
      let skip = limit * (offset - 1);
      let totalRecord = this.propertiesListArray;
      let currentRecord = totalRecord.slice(skip, limit + skip);
      this.selectedPropertyIDs = currentRecord.map((x) => {
        return x.id;
      });
    } else {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
      });
      this.selectedPropertyIDs = [];
    }
  }

  onSelectStaff(evt, userObject, i) {
    if (evt) {
      const data = {
        id: userObject.id,
        assignedStaff: {
          name: evt.name || "",
          email: evt.email || "",
          uid: evt.uid || "",
          assignedDate: new Date(),
        },
      };
      this.apiservice.postRequest("assignedStaff.php", data).subscribe(
        (response: any) => {
          this.alertnotification.successAlert(response.message);
          this.exportdata[i]["assigned_staff"] = JSON.stringify(
            data["assignedStaff"]
          );
          this.propertiesListArray[i]["assigned_staff"] = JSON.stringify(
            data["assignedStaff"]
          );
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    } else {
      const data = {
        id: userObject.id,
        assignedStaff: {
          name: this.removeOfficer.name,
          email: "",
          assignedDate: new Date(),
        },
      };
      this.apiservice.postRequest("assignedStaff.php", data).subscribe(
        (response: any) => {
          this.alertnotification.successAlert(response.message);
          this.exportdata[i]["assigned_staff"] = JSON.stringify([]);
          this.propertiesListArray[i]["assigned_staff"] = JSON.stringify([]);
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    }
  }

  checkAssignedStaff(object) {
    return object &&
      object.assigned_staff &&
      JSON.parse(object.assigned_staff) &&
      JSON.parse(object.assigned_staff)["name"]
      ? JSON.parse(object.assigned_staff)["name"]
      : null;
  }

  showFilters() {
    this.isFilterShow = !this.isFilterShow;
  }
  copyPropertyLink(object) {
    console.log(object);
    let data = {
      propertyId: object.id,
      details: JSON.stringify(object),
    };
    this.apiservice.postRequest_b2("addSharedProperty", data).subscribe(
      (response) => {
        try {
          var url = `https://public.mysocialhousing.co.uk/msh/propertydetail/${object.id}`;
          let textarea;
          let result;
          try {
            textarea = document.createElement("textarea");
            textarea.setAttribute("readonly", true);
            textarea.setAttribute("contenteditable", true);
            textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
            textarea.value = url;

            document.body.appendChild(textarea);

            textarea.focus();
            textarea.select();

            const range = document.createRange();
            range.selectNodeContents(textarea);

            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            textarea.setSelectionRange(0, textarea.value.length);
            result = document.execCommand("copy");
          } catch (err) {
            console.error(err);
            result = null;
          } finally {
            document.body.removeChild(textarea);
          }

          // manual copy fallback using prompt
          if (!result) {
            const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
            const copyHotkey = isMac ? "⌘C" : "CTRL+C";
            result = prompt(`Press ${copyHotkey}`, url); // eslint-disable-line no-alert
            if (!result) {
              return this.alertnotification.errorAlert("error");
            }
          }
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Property Profile link copied to clipboard",
            showConfirmButton: false,
            timer: 3000,
          });
        } catch (error) {
          this.alertnotification.errorAlert(error);
        }
      },
      (error) => {
        this.alertnotification.errorAlert(error.message);
      }
    );
  }
  onchangeCheckbox(evt) {
    if (evt.target.checked) {
      this.filters.closestFirst = true;
    } else {
      this.filters.closestFirst = false;
    }
  }
  onChangeMiles(evt) {
    this.filters.miles = evt.value;
    this.refreshButtonStatus = true;
  }
  onChangeDistance(value) {
    if (value == "asc") {
      this.filters.closestFirst = true;
    } else if (value == "desc") {
      this.filters.closestFirst = false;
    }
  }
  onClickReset() {
    (this.filters = {
      miles: 1000,
      closestFirst: true,
      bedroomFilter: {
        min: 0,
        max: 9,
        range: "range",
      },
    }),
      (this.refreshButtonStatus = false);
    (this.selectedProgressColor = null), (this.searchUser = null), (this.p = 1);
    this.sortvalue = ["created", "desc"];
    this.sort = "asc";
    this.onClickSave(false);
  }
  onClickSave(status) {
    if (!this.propertDetail) {
      return this.alertnotification.errorAlert("Please select property first!");
    }
    this.searchUser = true;
    this.sendFiltersData(status);
  }
  async sendFiltersData(refreshStatus) {
    this.allUsersPush = [];
    this.apiservice
      .postRequest("getShortListedByFilter.php", {
        filter: this.filters,
        propertyId: this.propertDetail.id,
        refresh: refreshStatus,
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          this.usersListArray = response;
          response = this.usersListArray.filter((x) =>
            this.RAuserlist.some((x2) => x.user_email == x2.email)
          );
          console.log("response=====>>>", response);
          response.forEach(async (element) => {
            let userData;
            await this.firebaseservice
              .getUserByEmail(element.user_email)
              .then((result) => {
                var records = [];
                result.forEach(function (object) {
                  var data = object.data();
                  data["id"] = object.id;
                  records.push(data);
                });
                if (records.length > 0) {
                  localStorage.setItem(
                    "userDetail",
                    JSON.stringify(records[0])
                  );
                  userData = records[0];
                }
              });

            element["address"] = userData.city;
            element["age"] = userData.age;
            element["uid"] = userData.uid;
            element["phone"] = userData.phoneNumber1
              ? userData.phoneNumber1
              : userData.phoneNumber2;
            userData.uid ? this.allUsersPush.push(userData.uid) : "";
            let d = new Date(1970, 0, 1); // Epoch
            d.setSeconds(userData.createdAt.seconds);
            let year = new Intl.DateTimeFormat("en", {
              year: "2-digit",
            }).format(d);
            let month = new Intl.DateTimeFormat("en", {
              month: "short",
            }).format(d);
            let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
              d
            );
            element["createDate"] = day + " " + month + " " + year;

            if (userData.proofOfIdFiles && userData.proofOfIncomeFiles) {
              await this.calculateUserProfileStatusCSV(
                userData.proofOfIdFiles,
                userData.proofOfIncomeFiles,
                element
              );
            } else {
              await this.calculateUserProfileStatus(
                userData.arrImgIdProof,
                userData.arrImgIncomeProof,
                userData.arrImgBankStatements,
                element
              );
            }
          });
          this.allUsersEmail = this.usersListArray.map((x) => {
            return x.user_email;
          });
          console.log(this.RAuserlist);
          // console.log("1=>>>>>>>", this.usersListArray);

          this.usersListArray = this.usersListArray.filter((x) =>
            this.RAuserlist.some((x2) => x.user_email == x2.email)
          );
          let hello = this.RAuserlist.map((x) =>
            this.usersListArray.filter((x2) => x.user_email == x2.email)
          );
          console.log({ hello: hello });
          console.log(this.usersListArray);

          this.refreshButtonStatus = false;
          this.loaderService.hide();
        },
        (error) => {
          this.loaderService.hide();
          this.alertnotification.errorAlert(error.message);
        }
      );
  }
  async calculateUserProfileStatusCSV(arrImgIdProof, arrImgIncomeProof, user) {
    user["documentCount"] = 0;
    let sum = 1;
    sum += arrImgIdProof ? Number(arrImgIdProof.length > 0) : 0;
    sum += arrImgIncomeProof ? Number(arrImgIncomeProof.length > 0) : 0;
    user["documentCount"] = sum;
  }
  async calculateUserProfileStatus(
    arrImgIdProof,
    arrImgIncomeProof,
    arrImgBankStatements,
    user
  ) {
    user["documentCount"] = 0;
    let sum = 0;
    sum += arrImgIdProof ? Number(arrImgIdProof.length > 0) : 0;
    sum += arrImgIncomeProof ? Number(arrImgIncomeProof.length > 0) : 0;
    sum += arrImgBankStatements ? Number(arrImgBankStatements.length > 0) : 0;
    user["documentCount"] = sum;
  }
  sortDotsListingArray(documentCount) {
    if (!this.sortvalue.includes(documentCount)) {
      this.sortvalue = [documentCount, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [documentCount, "desc"])
        : (this.sortvalue = [documentCount, "asc"]);
    }

    let tempuserListingArray = [],
      tempuserListingArray2 = [],
      tempuserListingArray3 = [],
      tempuserListingArray4 = [];

    this.usersListArray.forEach((value) => {
      if (value.documentCount == 3) {
        tempuserListingArray.push(value);
      } else if (value.documentCount == 2) {
        tempuserListingArray2.push(value);
      } else if (value.documentCount == 1) {
        tempuserListingArray3.push(value);
      } else {
        tempuserListingArray4.push(value);
      }
    });
    if (this.sortvalue[1] == "desc") {
      this.usersListArray = tempuserListingArray4.concat(
        tempuserListingArray3,
        tempuserListingArray2,
        tempuserListingArray
      );
    } else {
      this.usersListArray = tempuserListingArray.concat(
        tempuserListingArray2,
        tempuserListingArray3,
        tempuserListingArray4
      );
    }
    this.p = 1;
  }

  selectUserCheckBox(evt, email, documentCount, uid) {
    evt.target.checked
      ? this.selectedUsersEmail.indexOf(email) == -1
        ? this.selectedUsersEmail.push(email)
        : ""
      : this.selectedUsersEmail.splice(
          this.selectedUsersEmail.indexOf(email),
          1
        );
    evt.target.checked
      ? this.selectedUsersPush.indexOf(uid) == -1
        ? this.selectedUsersPush.push(uid)
        : ""
      : this.selectedUsersPush.splice(this.selectedUsersPush.indexOf(uid), 1);
    console.log(this.selectedUsersEmail, this.selectedUsersPush);
    let string = this.selectedUsersEmail?.toString();
    console.log("string", string);
    this.recipient = [];
    this.recipient.push(string);
  }

  clickOnUserEmail(email) {
    this.firebaseservice
      .getUserByEmail(email)
      .then((result) => {
        var records = [];

        result.forEach(function (object) {
          var data = object.data();
          data["id"] = object.id;
          records.push(data);
        });
        if (records.length > 0) {
          this.loaderService.hide();
          localStorage.setItem("userDetail", JSON.stringify(records[0]));
          this.router.navigate([]).then((result) => {
            window.open("/landlord/userdetail", "_blank");
          });
        } else {
          this.loaderService.hide();
          this.alertnotification.errorAlert("No User Found");
        }
      })
      .catch(function (error) {
        this.loaderService.hide();
        console.log(error);
        this.alertNotification.errorAlert(error.message);
      });
  }

  onSelectStaff1(evt) {
    if (evt) {
      const data = {
        id: this.propertDetail.id,
        assignedStaff: {
          name: evt.name || "",
          email: evt.email || "",
          uid: evt.uid || "",
          assignedDate: new Date(),
        },
      };
      this.apiservice.postRequest("assignedStaff.php", data).subscribe(
        (response: any) => {
          this.alertnotification.successAlert(response.message);
          this.propertDetail["assigned_staff"] = JSON.stringify(
            data["assignedStaff"]
          );
          localStorage.setItem(
            "propertyDetail",
            JSON.stringify(this.propertDetail)
          );
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    } else {
      const data = {
        id: this.propertDetail.id,
        assignedStaff: {
          name: this.removeOfficer.name,
          assignedDate: new Date(),
        },
      };
      this.apiservice.postRequest("assignedStaff.php", data).subscribe(
        (response: any) => {
          this.alertnotification.successAlert(response.message);
          this.propertDetail["assigned_staff"] = JSON.stringify([]);
          localStorage.setItem(
            "propertyDetail",
            JSON.stringify(this.propertDetail)
          );
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    }
  }
  setPropertyDetail(i) {
    this.showFilter = true;
    this.propertDetail = this.propertiesListArray[i];
    window.scroll(0, 0);
    console.log({ property: this.propertDetail });
  }

  sendmail() {
    let currentUserAllData = JSON.parse(localStorage.getItem("User"));
    const text = this.sendMailData.value.textcontent;
    const url = this.sendMailData.value.content.replace(/\n/g, "<br />");
    const body = text + "<br /> Property Links <br />" + url;
    console.log({ text }, { body });
    this.usersListArray;
    this.apiservice
      .postRequest("sendGridMailRA.php", {
        userEmail: currentUserAllData.user.email,
        email: this.usersListArray,
        subject: this.sendMailData.value.subject,
        content: body,
      })
      .subscribe(
        (response: any) => {
          if (response) {
            let d = document.getElementById("close-modal-mail");
            d.click();
            this.clearAllProperties();
            console.log("response", response);
            this.allcontentData = null;
            this.sendMailData.reset();
          }
          this.alertnotification.successAlert(
            "Email Sent to all selected users"
          );
        },
        (error) => {
          this.emailTemplateTitle = "";
          this.alertnotification.errorAlert(error.message);
        }
      );
  }
  clearAllProperties() {
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.propertiesListArray[element].selected = false;
    }
    this.tempSelectedPropertiesIndexes = [];
    this.selectedPropertiesIndexes = [];
    this.selectedPropertydata = [];
    let d = document.getElementById("close-property-modal");
    d.click();
  }

  getPropertiesData1() {
    console.log(this.selectedPropertiesIndexes);
    if (this.propertiesListArray.length < 1) {
      ///api.php?all=true&allproperty=true  getAllProperty.php
      this.apiservice
        .getRequest(`/api.php?all=true&allproperty=true`)
        .subscribe(
          (response: any) => {
            Object.keys(response).map((x) => {
              if (x == "totalpage") {
              } else if (x == "rowlimit") {
              } else if (x == "pagenum") {
              } else if (x == "totalcount") {
              } else if (x == "totalreturn") {
              } else {
                this.propertiesListArray.push(response[x]);
              }
            });
            console.log(this.propertiesListArray.length);
            console.log(this.propertiesListArray);
            for (
              let index = 0;
              index < this.propertiesListArray.length;
              index++
            ) {
              const element = this.propertiesListArray[index];
              element.selected = false;
            }
          },
          (error) => {
            // console.log(error.error);
            this.alertnotification.errorAlert(error.error.message);
          }
        );
    }
    this.tempSelectedPropertiesIndexes = [];
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.tempSelectedPropertiesIndexes.push(element);
      this.propertiesListArray[element].selected = true;
    }
    console.log(
      this.tempSelectedPropertiesIndexes,
      " Temp ",
      this.selectedPropertiesIndexes,
      "Indexes"
    );
  }

  copyUserEmail(email) {
    console.log(this.userEmailList);
    if (this.userEmailList?.includes(email)) {
      this.userEmailList = this.userEmailList.filter((item) => item !== email);
      console.log("Email Remove From Array", this.userEmailList);
    } else {
      console.log(email);
      this.userEmailList?.push(email);
      console.log("Email add into Array", this.userEmailList);
    }
    let string = this.userEmailList?.toString();
    console.log("string", string);
    this.recipient = [];
    this.recipient.push(string);
  }
  onPropertySelect() {
    console.log(this.propertDetail);
    this.selectedPropertydata = "";
    let property = this.propertDetail;
    this.PropertyPublicLink(property);
    this.selectedPropertydata =
      `${property.title} : ${property.property_address}` +
      "\n" +
      ` https://public.mysocialhousing.co.uk/msh/propertydetail/${property.id}` +
      "\n\n";
    this.allcontentData = this.selectedPropertydata;
    console.log(this.allcontentData);
  }
  PropertyPublicLink(object) {
    console.log(object);
    let data = {
      propertyId: object.id,
      details: JSON.stringify(object),
    };
    this.apiservice.postRequest_b2("addSharedProperty", data).subscribe(
      (response) => {
        console.log("public link", response);
      },
      (error) => {
        this.alertnotification.errorAlert(error.message);
      }
    );
  }

  clickPaginationPopUp() {
    window.scroll(0, 0);
    this.selectedPropertyIDs = [];
    this.selectedPropertiesIndexes = [];
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }
}
