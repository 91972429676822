import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ngx-cacheable';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'; 
import { Subject, BehaviorSubject } from 'rxjs';
// import { NgxSpinnerService } from 'ngx-spinner';

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})

export class ApiServiceService {

  private dataSource = new BehaviorSubject('');
  currentMessage = this.dataSource.asObservable();
 
  base_url = environment.baseUrl;
  base_url2 = environment.baseUrl2;

  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    // withCredentials: true
  };

  constructor(private http: HttpClient,
    // private loaderService: NgxSpinnerService
  ) {}

  @Cacheable({
    maxCacheCount: 20,
    maxAge: 90000,
    cacheBusterObserver: cacheBuster$
  })
  getRequest(url : string, param = {}) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params : param,
      withCredentials: false
    };
    return this.http.get(`${this.base_url}`+url,httpOptions);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  postRequest(url , request_data) {
    return this.http.post(`${this.base_url}/`+url, request_data,this.httpOptions);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  deleteRequest(url) {
    return this.http.delete(`${this.base_url}/`+url , this.httpOptions);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  uploadRequest(url , form_data) {
    return this.http.post(`${this.base_url}/`+url, form_data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  putRequest(url , form_data) {
    return this.http.put(`${this.base_url}/`+url, form_data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  postRequest_b2(url,request_data) {
    return this.http.post(`${this.base_url2}/`+url, request_data,this.httpOptions);
  }

  getRequest_b2(url,param={}) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params : param,
      withCredentials: false
    };
    return this.http.get(`${this.base_url2}`+url,httpOptions);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheBuster$
  })
  deleteRequest_b2(url) {
    return this.http.delete(`${this.base_url2}/`+url , this.httpOptions);
  }

}