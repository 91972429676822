import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";

@Component({
  selector: "app-send-push-email",
  templateUrl: "./send-push-email.component.html",
  styleUrls: ["./send-push-email.component.scss"],
})
export class SendPushEmailComponent implements OnInit {
  userList;
  type;
  sendEmailForm: any;
  formSubmitted = false;
  sendPushForm: any;
  emailForm = false;
  pushForm = false;
  sendBy;
  userType;
  uidList: any;
  checkedSendtoAll = false;
  user;
  emailTemplateTitle: any;
  emailBody: any;
  allEmailTemplates: any;
  showSendToAll: boolean = true;
  sendTo;
  precomposedMsgArr = [
    {
      msg: "precomposed message one",
    },
    {
      msg: "precomposed message two",
    },
    {
      msg: "precomposed message three",
    },
    {
      msg: "precomposed message four",
    },
  ];
  showBody: boolean = true;
  showPrecomposeMessage: boolean = false;
  SelectedMsg: any;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private loaderService: NgxSpinnerService,
    private route: ActivatedRoute,
    private firebaseservice: FirebaseQueryService
  ) {
    this.sendEmailForm = this.formBuilder.group({
      msgTemplate: [null],
      emails: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      content: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.type == "email") {
      this.emailForm = true;
      this.uidList = this.userList;
    } else {
      this.pushForm = true;
      this.uidList = this.userList;
    }
    if (this.user == "single") {
      this.showSendToAll = false;
    } else {
      this.showSendToAll = true;
    }

    this.sendPushForm = this.formBuilder.group({
      msgTemplate: [null],
      title: [null, [Validators.required]],
      body: [null, [Validators.required]],
    });
    this.getAllMessageTemplates();
    console.log(this.uidList);
  }

  onSubmitEmail() {
    if (this.uidList.length == 0) {
      this.loaderService.hide();
      Swal.fire("Hey user!", "No any receiver emails found", "warning");
    }
    let string = this.uidList.toString();
    let emailArr = string.split(",");
    let currentUserAllData = JSON.parse(localStorage.getItem("User"));
    const d = this.sendEmailForm.value.content.replace(/\n/g, "<br />");
    this.apiservice
      .postRequest("sendGridMailRA.php", {
        userEmail: currentUserAllData.user.email,
        email: emailArr,
        subject: this.sendEmailForm.value.subject,
        content: d,
      })
      .subscribe(
        (response: any) => {
          if (response) {
            this.emailTemplateTitle = "";
            this.emailBody = "";
            if (response.statusCode == 202) {
              this.loaderService.hide();
              this.bsModalRef.hide();
              this.alertnotification.successAlert(response.message);
            } else {
              this.loaderService.hide();
              this.alertnotification.errorAlert(response.message);
            }
          }
        },
        (error) => {
          this.loaderService.hide();
          this.emailTemplateTitle = "";
          this.emailBody = "";
          this.alertnotification.errorAlert(error.message);
        }
      );
  }

  getAllMessageTemplates() {
    this.apiservice.postRequest("getTemplate.php", { id: "all" }).subscribe(
      (result: any) => {
        this.allEmailTemplates = result.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onChangeEmailTemplate(index) {
    if (index && index >= 0) {
      this.emailTemplateTitle = this.allEmailTemplates[index].title;
      this.emailBody = this.allEmailTemplates[index].description;
    } else {
      this.emailTemplateTitle = "";
      this.emailBody = "";
    }
  }

  onSubmitPush() {
    this.formSubmitted = true;
    if (this.sendPushForm.invalid) {
      this.loaderService.hide();
      return;
    } else if (!this.userList && this.sendTo != "all") {
      this.loaderService.hide();
      Swal.fire("Hey user!", "No any receiver uids found", "warning");
    } else {
      if (this.sendTo != "all") {
        this.sendPushForm.value["uid"] = this.userList;
      }
      this.sendPushForm.value["sendTo"] = this.sendTo;
      this.sendPushForm.value["uidType"] = "auto";
      this.sendPushForm.value["sendBy"] = "Admin";
      console.log(this.sendPushForm.value);
      this.apiservice
        .postRequest_b2("sendpushnotification", this.sendPushForm.value)
        .subscribe(
          (response: any) => {
            this.loaderService.hide();
            this.emailTemplateTitle = "";
            this.emailBody = "";
            console.log(response);
            this.alertnotification.successAlert(response.message);

            this.bsModalRef.hide();
          },
          (error) => {
            this.loaderService.hide();
            this.emailTemplateTitle = "";
            this.emailBody = "";
            console.log(error.error);
            this.alertnotification.errorAlert(error.error.message);
          }
        );
    }
  }

  sendemailToAll() {
    Swal.fire({
      title: "Do you want to send email to all users ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      var records1 = [];
      if (result.value) {
        this.firebaseservice
          .getUserList()
          .then(
            function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                var data = doc.data();
                data["id"] = doc.id;
                records1.push(data);
              });
              this.userList = [];
              this.userList = records1
                .map((x) => {
                  return x.email;
                })
                .filter((y) => y !== undefined && y != "");
              this.loaderService.hide();
              this.onSubmitEmail();
            }.bind(this)
          )
          .catch(
            function (error) {
              this.loaderService.hide();
              console.log("Error getting documents: ", error);
            }.bind(this)
          );
      } else {
        return;
      }
    });
  }

  sendpushToAll() {
    Swal.fire({
      title: "Do you want to send push to all users ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value) {
        this.loaderService.hide();
        this.sendTo = "all";
        this.onSubmitPush();
      } else {
        return;
      }
    });
  }

  showPrecomposeMessagefn() {
    this.showBody = false;
    this.showPrecomposeMessage = true;
  }

  showCustomMessagefn() {
    this.showPrecomposeMessage = false;
    this.showBody = true;
  }
}
