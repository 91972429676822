<div *ngIf="showForm">
  <form
    [ngStyle]="{ height: '80vh' }"
    class="form-sample"
    [formGroup]="createUserForm"
    (submit)="onSubmit()"
  >
    <div class="form-group row">
      <div class="col-md-6">
        <label class="col-form-label"> Name </label>
        <input
          type="text"
          class="form-control"
          formControlName="name"
          placeholder="Name"
          name="name"
        />
        <div
          *ngIf="formSubmitted && createUserForm.controls.name.errors"
          class="has-error"
        >
          <span *ngIf="createUserForm.controls.name.errors.required"
            >name is required
          </span>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-3">
        <label class="col-form-label"> Email </label>
        <input
          type="text"
          class="form-control"
          formControlName="email"
          placeholder="Email"
          name="email"
        />
        <div
          *ngIf="formSubmitted && createUserForm.controls.email.errors"
          class="has-error"
        >
          <span *ngIf="createUserForm.controls.email.errors.required"
            >email is required
          </span>
        </div>
      </div>
      <div
        class="col-md-3"
        [ngStyle]="{
          'margin-top': '37px',
          'margin-left': '-20px'
        }"
      >
        <label>@{{ organization }}</label>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-5">
        <label class="col-form-label"> Password </label>
        <input
          autocomplete="off"
          [type]="show ? 'text' : 'password'"
          formControlName="password"
          class="form-control"
          placeholder="password"
          name="password"
        />

        <div
          *ngIf="formSubmitted && createUserForm.controls.password.errors"
          class="has-error"
        >
          <span *ngIf="createUserForm.controls.password.errors.required"
            >password is required
          </span>
        </div>
      </div>
      <div
        class="col-md-1"
        [ngStyle]="{
          'margin-top': '28px',
          'margin-left': '-20px'
        }"
      >
        <a
          class="btn btn-outline-danger showBtn btn-fw m-2"
          (click)="passwordHandler()"
        >
          {{ show ? "Hide" : "Show" }}
        </a>
      </div>
    </div>

    <div class="form-actions mt-4 mb-5">
      <button type="submit" class="btn btn-outline-success btn-fw m-2">
        Save
      </button>
      <button
        class="btn btn-outline-danger btn-fw m-2"
        (click)="cancel()"
        type="button"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
<div *ngIf="!showForm">
  <div class="col-12 flex-box" *ngIf="isAuthorized">
    <button class="btn-create" (click)="handleForm()">
      create new account
    </button>
  </div>
  <div class="col-md-12">
    <h6 *ngIf="accountsData.length == 0">No accounts created yet!</h6>
    <div class="table-responsive wh_table" *ngIf="accountsData?.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th style="cursor: pointer">Name<i class="fa"></i></th>

            <th style="cursor: pointer">Email<i class="fa"></i></th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="accountsData" let-i="index">
            <tr>
              <td>
                <div class="txt_overflow">
                  <span class="user--name">{{ item.name }}</span>
                </div>
              </td>
              <td>
                <div class="txt_overflow">
                  {{ item.email }}
                </div>
              </td>
              <td class="text-center">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="assets/images/three_dots.png" />
                  </button>
                  <!-- <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                    data-target="#filter_box_dropdown"
                  >
                    <a class="dropdown-item"
                      ><i class="far fa-eye"></i>View Detail</a
                    >
                    <a class="dropdown-item"
                      ><i class="far fa-filter"></i>Filter Users</a
                    >
                  </div> -->
                </div>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>
