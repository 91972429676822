import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

  ImageUrl;
  proofType;
  
  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    console.log(this.ImageUrl);
  }

}
