<!--new HTML-->

<div class="top_btn">
  <div
    *ngIf="showPropertyFilter"
    class="filter_btn"
    data-toggle="collapse"
    data-target="#filter_box_dropdown"
  >
    <span> <img src="assets/images/filter_blk.png" /> Filter Properties </span>
    <img src="assets/images/arrow-blk.png" />
  </div>
  <!-- <div class="search_input"><input type="text" placeholder="Search"></div> -->
  <div class="btns">
    <!-- <ng-select class="text-left selectBox mr-2" [clearable]="clearAssignedUser"
          [ngModel]="checkAssignedStaff(userDetail)" bindLabel="name" bindValue="name"
          (change)="onSelectStaff($event)" [items]="staffUserListArray" placeholder="Assign Staff"></ng-select>
      <!-- </div> --
      <button [disabled]="oncheckUserStatus()" class="btn_grey" (click)="addNote()"> Add
          Note</button>
      <button [disabled]="oncheckUserView()" type="button" (click)="sendEmail()" class="btn_grey">Send email</button>
      <button [disabled]="oncheckUserView()" type="button" (click)="sendPush()" class="btn_primary">Send push</button>
      <button [disabled]="oncheckUserView()" type="button" (click)="copyUserLink(userDetail)" class="btn_grey">Copy
          Link</button> -->
  </div>
</div>

<section id="filter_box_dropdown" class="collapse filter_box_dropdown">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 filter_left">
        <h6>
          Show closet property first
          <div class="custom-control custom-switch">
            <input
              [checked]="filters.closestFirst"
              (change)="onchangeCheckbox($event)"
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </h6>
        <h6>
          Nearest to city:
          <div class="custom-control custom-switch w-100">
            <ng-select
              [(ngModel)]="selectedCity"
              name="city"
              [items]="areaOfInterestList"
              placeholder="Select city"
              bindLabel="name"
              (change)="onChange(selectedCity)"
            >
            </ng-select>
          </div>
        </h6>
        <form>
          <div class="form-group">
            <h6 for="formControlRange">
              How far from you? <strong>{{ filters.miles }} miles</strong>
            </h6>
            <ng5-slider
              [(value)]="filters.miles"
              [options]="options"
              (userChangeEnd)="onChangeMiles($event)"
            ></ng5-slider>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-sm-6 filter_right">
        <h6>Minimum Beds</h6>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="0"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(0, 'min')"
            type="radio"
            id="customRadio1"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio1">Studio</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="1"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(1, 'min')"
            type="radio"
            id="customRadio2"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio2">1 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="2"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(2, 'min')"
            type="radio"
            id="customRadio3"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio3">2 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="3"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(3, 'min')"
            type="radio"
            id="customRadio4"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio4">3 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="4"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(4, 'min')"
            type="radio"
            id="customRadio5"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio5">4 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.min"
            [value]="5"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(5, 'min')"
            type="radio"
            id="customRadio6"
            name="customRadio"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio6">5 Bed</label>
        </div>

        <h6 class="mt-2">Maximum Beds</h6>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="0"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(0, 'max')"
            type="radio"
            id="customRadio11"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio11">Studio</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="1"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(1, 'max')"
            type="radio"
            id="customRadio12"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio12">1 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="2"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(2, 'max')"
            type="radio"
            id="customRadio13"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio13">2 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="3"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(3, 'max')"
            type="radio"
            id="customRadio14"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio14">3 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="4"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(4, 'max')"
            type="radio"
            id="customRadio15"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio15">4 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="5"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(5, 'max')"
            type="radio"
            id="customRadio16"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio16">5 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="6"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(6, 'max')"
            type="radio"
            id="customRadio17"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio17">6 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="7"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(7, 'max')"
            type="radio"
            id="customRadio18"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio18">7 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="8"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(8, 'max')"
            type="radio"
            id="customRadio19"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio19">8 Bed</label>
        </div>
        <div class="custom-control custom-radio btn-radio">
          <input
            [(ngModel)]="filters.bedroomFilter.max"
            [value]="9"
            (change)="onChangeBedType()"
            [disabled]="isDisabled(9, 'max')"
            type="radio"
            id="customRadio19"
            name="customRadio1"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="customRadio19">9 Bed</label>
        </div>

        <div class="text-right col-sm-12 mt-3">
          <button class="btn clear_btn" (click)="onClickReset()">
            Clear all filter
          </button>
          <button class="btn btn-primary" (click)="onClickSave()">
            Show Results
          </button>
        </div>
      </div>
    </div>
    <div class="property-filter-result">
      <div *ngIf="searchProperty">
        <div class="total_count">
          Total {{ propertiesListArrayCopy.length }}
        </div>

        <div class="table-responsive wh_table">
          <table class="table" *ngIf="propertiesListArrayCopy.length > 0">
            <thead>
              <tr>
                <th style="cursor: pointer">Image</th>
                <th style="cursor: pointer" (click)="sortLisitng('distance')">
                  distance<i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' && sortvalue[0] == 'distance',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' && sortvalue[0] == 'distance'
                    }"
                  ></i>
                </th>
                <th style="cursor: pointer">
                  Title<i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' && sortvalue[0] == 'title',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' && sortvalue[0] == 'title'
                    }"
                  ></i>
                </th>
                <!-- <th style="cursor: pointer;" (click)="sortLisitng('provider')">Provider<i class="fa"
                                  [ngClass]="{'fa-arrow-down' : (sortvalue[1] == 'asc' && sortvalue[0] == 'provider') , 'fa-arrow-up' : (sortvalue[1] == 'desc' && sortvalue[0] == 'provider') }"></i>
                          </th> -->
                <th style="cursor: pointer" (click)="sortLisitng('desc')">
                  Description<i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' && sortvalue[0] == 'desc',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' && sortvalue[0] == 'desc'
                    }"
                  ></i>
                </th>
                <th style="cursor: pointer" (click)="sortLisitng('amount')">
                  Amount<i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' && sortvalue[0] == 'amount',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' && sortvalue[0] == 'amount'
                    }"
                  ></i>
                </th>
                <th
                  style="cursor: pointer"
                  (click)="sortLisitng('property_address')"
                >
                  Property Address<i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' &&
                        sortvalue[0] == 'property_address',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' &&
                        sortvalue[0] == 'property_address'
                    }"
                  ></i>
                </th>
                <th style="cursor: pointer" (click)="sortLisitng('roomtype')">
                  Bedroom Size
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-arrow-down':
                        sortvalue[1] == 'asc' && sortvalue[0] == 'roomtype',
                      'fa-arrow-up':
                        sortvalue[1] == 'desc' && sortvalue[0] == 'roomtype'
                    }"
                  ></i>
                </th>
                <!-- <th style="cursor: pointer; min-width: 130px;" (click)="sortLisitng('created')">Added <i class="fa"
                                  [ngClass]="{'fa-arrow-down' : (sortvalue[1] == 'asc' && sortvalue[0] == 'created') , 'fa-arrow-up' : (sortvalue[1] == 'desc' && sortvalue[0] == 'created') }"></i>
                          </th> -->
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-template
                ngFor
                let-property
                [ngForOf]="
                  propertiesListArrayCopy
                    | SortingFilter: sortvalue
                    | paginate: { itemsPerPage: 10, currentPage: p }
                "
                let-i="index"
              >
                <tr [ngClass]="{ 'has-error': property.active == false }">
                  <td>
                    <div class="inline_box">
                      <img
                        class="img_logo"
                        *ngIf="property.image && property.image != 'null'"
                        [src]="checkImage(property)"
                      />
                      <img
                        class="img_logo"
                        *ngIf="!property.image || property.image === 'null'"
                        src="assets/images/icon_gradiant.png"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.distance }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      <span
                        class="user--name"
                        (click)="viewPropertyDetail(property, i)"
                        >{{ property.title }}</span
                      >
                    </div>
                  </td>
                  <!-- <td><div class="txt_overflow">{{property.provider}}</div></td> -->
                  <td>
                    <div class="txt_overflow">
                      {{ property.desc | limitTo: "200" }}
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.amount }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      {{ property.property_address }}
                    </div>
                  </td>
                  <td class="text-center">
                    {{ checkRoomType(property.roomtype) }}
                  </td>

                  <td>
                    <!-- {{convertPropertyDate(property.created)}} -->
                    <button
                      class="btn_primary"
                      (click)="shortListOrFavorite(property, i)"
                      [disabled]="
                        property.shortlist_by_RA == '1' ? true : false
                      "
                      [ngStyle]="{
                        'pointer-events':
                          property.shortlist_by_RA == '1' ? 'none' : 'auto',
                        'background-color':
                          property.shortlist_by_RA == '1'
                            ? '#bcc0c1'
                            : '#4C97A1',
                        'border-color':
                          property.shortlist_by_RA == '1'
                            ? '#bcc0c1'
                            : '#4C97A1'
                      }"
                    >
                      Shortlist
                    </button>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>

          <div
            *ngIf="propertiesListArrayCopy?.length > 0"
            class="pagination mt-4 mb-4 wh_pagination Pagiantion"
          >
            <pagination-controls
              (click)="clickPagination()"
              (pageChange)="p = $event"
              directionLinks="true"
              previousLabel="Previous"
              nextLabel="Next"
            ></pagination-controls>
          </div>
        </div>
        <div
          class="row wh_listing"
          *ngIf="propertiesListArrayCopy?.length == 0"
        >
          <p style="margin: 0 auto">This user has no location information</p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="box property_detail">
  <div class="row">
    <div class="col-sm-3">
      <div class="prop_img">
        <img
          style="cursor: pointer"
          (click)="
            showImage(
              userDetail?.imgProfile,
              userDetail?.firstName + ' Profile Image'
            )
          "
          *ngIf="userDetail?.imgProfile"
          [src]="userDetail.imgProfile"
        />
        <img
          *ngIf="!userDetail?.imgProfile"
          src="assets/images/icon_gradiant.png"
        />
      </div>

      <!-- <h4>Property Address</h4>
                  <p>Midnight Corner St. Suite 600 San
                      Francisco, CADGE 94107</p>

                  <div class="amount_sec">
                      <h4>Amount</h4>

                      <div class="border_sec">
                          <span>2 Rental Price</span>
                          <h3>$ 525.33/m</h3>
                          <span>Estimated monthly service charge</span>
                          <h6>$0.00</h6>
                      </div>

                      <a href="#" class="btn_primary">Ask me a question</a>
                  </div> -->
    </div>
    <div class="col-sm-4">
      <div>
        <h4 class="indicator-title">
          <span>Profile status</span><span>{{ indicatorPercentage }}%</span>
        </h4>
        <div class="indicator_div">
          <div
            class="indicator"
            [ngStyle]="{ to: indicatorPercentage }"
            [style.width]="indicatorPercentage + '%'"
            [style.background-color]="
              indicatorPercentage > 25
                ? indicatorPercentage > 25 && indicatorPercentage <= 50
                  ? '#e65010'
                  : indicatorPercentage > 50 && indicatorPercentage <= 80
                  ? '#e69710'
                  : '#62c91c'
                : '#e61010'
            "
          ></div>
        </div>
      </div>
      <div class="mt-4">
        <b>Status: </b
        ><span style="text-transform: capitalize">
          {{
            userDetail.propertyStatus ? userDetail.propertyStatus : "N/A"
          }}</span
        >
      </div>
    </div>
    <div class="col-sm-12 prop_right">
      <!-- Old Design user detail page start------------------->
      <accordion>
        <div class="row">
          <div class="col-md-6">
            <accordion-group heading="Profile Details" [isOpen]="openCloseBool">
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Account Details</h6> -->
                  <button
                    class="edit_btn"
                    (click)="editDetails('accountDetails', null)"
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Full Name</th>
                      <td>
                        <span
                          [ngStyle]="{
                            'background-color': userDetail.verified
                              ? '#BADC94'
                              : 'white',
                            'border-radius': '8px',
                            padding: '5px'
                          }"
                        >
                          {{ userDetail?.firstName }} {{ userDetail?.lastName }}
                        </span>
                        <span
                          *ngIf="userDetail.verifiedDate"
                          class="d-block"
                          [ngStyle]="{
                            color: userDetail.verified
                              ? 'hwb(87deg 27% 32%)'
                              : 'white',
                            'border-radius': '8px',
                            padding: '2px',
                            paddingTop: '15px',
                            fontStyle: 'italic'
                          }"
                        >
                          Verified on:
                          {{ changeDateFormat1(userDetail.verifiedDate) }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Age</th>
                      <td>{{ userDetail?.age }}</td>
                    </tr>
                    <tr>
                      <th>Date of Birth</th>
                      <td>{{ userDOB ? userDOB : "" }}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ userDetail?.email }}</td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>{{ userDetail?.phoneNumber1 }}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{{ userDetail?.city }}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{{ userDetail?.address }}</td>
                    </tr>
                    <tr *ngIf="userDetail.noOfMonthsAtAdress">
                      <th>Number of year at Address</th>
                      <td>{{ userDetail?.noOfMonthsAtAdress }}</td>
                    </tr>
                    <tr *ngIf="userDetail.noOfYearsAtAdress">
                      <th>Number of year at Address</th>
                      <td>{{ userDetail?.noOfYearsAtAdress }}</td>
                    </tr>
                    <tr>
                      <th>Postcode</th>
                      <td>{{ userDetail?.postCode }}</td>
                    </tr>
                    <!-- <tr>
                                          <th>Referring Agent</th>
                                          <td>{{userDetail?.referringAgent}}</td>
                                      </tr> -->
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group heading="Other Details" [isOpen]="openCloseBool">
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Other Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('otherDetails', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr *ngIf="userDetail.email1">
                      <th>Alternative Email</th>
                      <td>{{ userDetail?.email1 }}</td>
                    </tr>
                    <tr *ngIf="!userDetail.email1">
                      <th>Alternative Email</th>
                      <td>{{ userDetail?.email2 }}</td>
                    </tr>
                    <tr>
                      <th>Alternative Phone</th>
                      <td>{{ userDetail?.phoneNumber2 }}</td>
                    </tr>
                    <tr>
                      <th>Rent Arrears</th>
                      <td>{{ userDetail?.rentArrearsAmount }}</td>
                    </tr>
                    <tr>
                      <th>Medical Details</th>
                      <td>{{ userDetail?.medicalDetails }}</td>
                    </tr>
                    <tr>
                      <th>ASB</th>
                      <td *ngIf="userDetail.isASBDetailsProvided">
                        {{ userDetail?.asbDetails }}
                      </td>
                      <td *ngIf="!userDetail.isASBDetailsProvided">
                        {{ userDetail.isASBDetailsProvided }}
                      </td>
                    </tr>
                    <tr>
                      <th>Risk</th>
                      <td *ngIf="userDetail.isASBDetailsProvided">
                        {{ userDetail?.asbDetails }}
                      </td>
                      <td *ngIf="!userDetail.isASBDetailsProvided">
                        {{ userDetail.applicantDangerToStaff }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6" *ngIf="userDetail.public === true">
            <accordion-group
              heading="Address History"
              [isOpen]="
                userDetail?.addressHistory2 === ' ' ||
                userDetail?.addressHistory2 == ''
                  ? false
                  : openCloseBool
              "
            >
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Other Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('addressHistory', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Address 2</th>
                      <td>{{ userDetail?.addressHistory2 }}</td>
                    </tr>
                    <tr>
                      <th>Address 3</th>
                      <td>{{ userDetail?.addressHistory3 }}</td>
                    </tr>
                    <tr>
                      <th>Address 4</th>
                      <td>{{ userDetail?.addressHistory4 }}</td>
                    </tr>
                    <tr>
                      <th>Address 5</th>
                      <td>{{ userDetail?.addressHistory5 }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group
              heading="Referring Agent"
              [isOpen]="
                userDetail.emailRA || userDetail.referringAgent ? true : false
              "
            >
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Other Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('referringAgency', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr *ngIf="userDetail.referringAgent">
                      <th>Name</th>
                      <td>{{ userDetail.referringAgent }}</td>
                    </tr>
                    <tr>
                      <th>First Name</th>
                      <td>{{ userDetail?.firstNameRA }}</td>
                    </tr>
                    <tr>
                      <th>Last Name</th>
                      <td>{{ userDetail?.lastNameRA }}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ userDetail?.emailRA }}</td>
                    </tr>
                    <tr>
                      <th>Contact No</th>
                      <td>{{ userDetail?.phoneNumberRA }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group
              heading="Family Composition"
              [isOpen]="
                userDetail.firstNameFC !== undefined ||
                userDetail.firstNameFC !== '' ||
                userDetail.bedroomSize !== undefined ||
                userDetail.bedroomSize !== '' ||
                userDetail.currentAccommodation !== undefined ||
                userDetail.currentAccommodation !== ''
                  ? true
                  : false
              "
            >
              <div class="card card--info">
                <div class="card-header">
                  <!-- <h6>Family Composition</h6> -->
                  <button class="edit_btn" (click)="editDetails('familyComposition', null)"><i class="fa fa-pencil"
                                      aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Full Name</th>
                      <td>
                        {{ userDetail?.firstNameFC }}
                        {{ userDetail?.lastNameFC }}
                      </td>
                    </tr>
                    <tr>
                      <th>Date of Birth</th>
                      <td>{{ changeDOBFamily(userDetail?.dateOfBirthFC) }}</td>
                    </tr>
                    <tr>
                      <th>Relationship to You</th>
                      <td>{{ userDetail?.relationshipToYouFC }}</td>
                    </tr>
                    <tr>
                      <th>Bed room size require</th>
                      <td>{{ userDetail?.bedroomSize }}</td>
                    </tr>
                    <tr>
                      <th>Current Accommodation</th>
                      <td>{{ userDetail?.currentAccommodation }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group
              heading="Landlord Details"
              [isOpen]="
                userDetail.landlordName === undefined ? false : openCloseBool
              "
            >
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Landlord Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('landlordDetails', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table
                    class="table landlord--Info card--table m-0 detail_table"
                  >
                    <tr>
                      <th>Landlord Name</th>
                      <td>{{ userDetail?.landlordName }}</td>
                    </tr>
                    <tr>
                      <th>Landlord Phone Number</th>
                      <td>{{ userDetail?.landlordPhoneNumber }}</td>
                    </tr>
                    <tr>
                      <th>Landlord Email</th>
                      <td>{{ userDetail?.landlordEmail }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6" *ngIf="userDetail.public === true">
            <accordion-group
              heading="Accommodation Required"
              [isOpen]="openCloseBool"
            >
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Landlord Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('accommodationRequired', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Accommodation</th>
                      <td>{{ userDetail?.accommodationRequired }}</td>
                    </tr>
                    <tr>
                      <th>Bedroom Size</th>
                      <td>{{ userDetail?.bedroomSize }}</td>
                    </tr>
                    <tr>
                      <th>Current Accommodation</th>
                      <td>{{ userDetail?.currentAccommodation }}</td>
                    </tr>
                    <tr>
                      <th>Current Tenancy Type</th>
                      <td>{{ userDetail?.currentTenancyType }}</td>
                    </tr>
                    <!-- <tr>
                                          <th>Current Tenancy Type</th>
                                          <td>{{userDetail?.currentTenancyType}}</td>
                                      </tr> -->
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6" *ngIf="userDetail.public === true">
            <accordion-group heading="Income Details" [isOpen]="openCloseBool">
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <!-- <h6>Other Details</h6> -->
                  <button class="edit_btn" (click)="editDetails('incomeDetails', null)"><i class="fa fa-pencil"
                                          aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <table class="table card--table m-0 detail_table">
                    <tr>
                      <th>Employment Details</th>
                      <td>{{ userDetail?.employmentDetails }}</td>
                    </tr>
                    <tr>
                      <th>Income (£)</th>
                      <td>{{ userDetail?.income }}</td>
                    </tr>
                    <tr>
                      <th>National Insurance Number</th>
                      <td>{{ userDetail?.nationalInsuranceNumber }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group
              heading="Area of Interest"
              [isOpen]="openCloseBool"
            >
              <div class="card card--info">
                <div class="card-header d-flex justify-content-between">
                  <button class="edit_btn" (click)="editDetails('interest', null)"><i class="fa fa-pencil"
                                      aria-hidden="true"></i></button>
                </div>
                <div class="card-body">
                  <ng-template
                    *ngIf="
                      userDetail.areaOfInterest &&
                      userDetail.areaOfInterest.length > 0
                    "
                    ngFor
                    let-userInterest
                    [ngForOf]="userDetail.areaOfInterest"
                    let-i="index"
                  >
                    <table class="table card--table m-0 detail_table">
                      <!-- <p>{{userInterest}}</p> -->
                      <tr>
                        <td>{{ userInterest }}</td>
                      </tr>
                    </table>
                  </ng-template>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-6">
            <accordion-group
              heading="Referring Agent Notes"
              [isOpen]="openCloseBool"
              *ngIf="
                userDetail.referringAgentNotes &&
                userDetail.referringAgentNotes.length > 0
              "
            >
              <ng-template
                *ngIf="
                  userDetail.referringAgentNotes &&
                  userDetail.referringAgentNotes.length > 0
                "
                ngFor
                let-userNote
                [ngForOf]="userDetail.referringAgentNotes"
                let-i="index"
              >
                <div class="card card--Note card_box">
                  <div class="card-header">
                    <h6>{{ userNote.name }}</h6>
                    <b>{{ changeDateFormat(userNote.date) }}</b>
                  </div>
                  <div class="card-body">
                    <table class="table m-0 detail_table">
                      <p>{{ userNote.comment }}</p>
                    </table>
                  </div>
                  <div class="card-header d-flex justify-content-between">
                    <button class="edit_btn delete" (click)="onDelete(i)">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <button
                      class="edit_btn"
                      (click)="editDetails('notesDetail', i)"
                    >
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </ng-template>
            </accordion-group>
          </div>
          <div class="col-md-12">
            <accordion-group
              heading="Shortlisted Properties"
              [isOpen]="openCloseBool"
            >
              <div class="card card--info">
                <div class="card-header">
                  <!-- <h6>Shortlisted Properties</h6> -->
                  <!-- <div class="btns">
                                      <a type="button" class="btn_grey pull-right" (click)="getShortListedPropertyByEmail(userDetail.email)">View All</a>

                                  </div> -->
                </div>
                <div class="card-body table-responsive wh_table">
                  <table class="table card--table m-0 detail_table">
                    <thead>
                      <tr>
                        <th>Property address</th>
                        <th>Added On</th>
                        <th>Provider</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-template
                        ngFor
                        let-property
                        [ngForOf]="
                          userDetail?.shortlistedProperties
                            | paginate: { itemsPerPage: 10, currentPage: p }
                        "
                        let-i="index"
                      >
                        <tr *ngIf="property.shortlisted == 'true'">
                          <td>
                            {{ property.property_address }}
                          </td>
                          <td>
                            {{ convertDate(property.created) }}
                          </td>
                          <td>
                            {{ property.provider }}
                          </td>
                          <td>
                            {{ property.shortlisted_status || "" }}
                          </td>
                        </tr>
                      </ng-template>
                      <tr
                        *ngIf="userDetail?.shortlistedProperties?.length == 0"
                      >
                        <td colspan="4" class="text-center">
                          No documents found !
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-12">
            <accordion-group
              heading="Matched Properties"
              [isOpen]="openCloseBool"
            >
              <div class="card card--info">
                <div class="card-header">
                  <!-- <h6>Shortlisted Properties</h6>
                                  <div class="btns">
                                      <a type="button" class="btn_grey pull-right" (click)="getShortListedPropertyByEmail(userDetail.email)">View All</a>

                                  </div> -->
                </div>
                <div class="card-body table-responsive wh_table">
                  <table class="table card--table m-0 detail_table">
                    <thead>
                      <tr>
                        <th>Property address</th>
                        <th>Distance</th>
                        <th>Added On</th>
                        <th>Provider</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-template
                        ngFor
                        let-property
                        [ngForOf]="
                          userDetail?.matchedProperties
                            | paginate: { itemsPerPage: 10, currentPage: p }
                        "
                        let-i="index"
                      >
                        <tr *ngIf="property.shortlisted == 'true'">
                          <td>
                            {{ property.property_address }}
                          </td>
                          <td>{{ property.radius }} miles</td>
                          <td>
                            {{ convertDate(property.created) }}
                          </td>
                          <td>
                            {{ property.provider }}
                          </td>
                          <td>
                            {{ property.shortlisted_status || "" }}
                          </td>
                        </tr>
                      </ng-template>
                      <tr *ngIf="userDetail?.matchedProperties?.length == 0">
                        <td colspan="4" class="text-center">
                          No documents found !
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <div class="col-md-12">
            <accordion-group heading="My Documents" [isOpen]="openCloseBool">
              <tr>
                <th class="col-md-4" for="file">Upload user documentation</th>
                <td class="col-md-8">
                  <form [formGroup]="inputForm" (ngSubmit)="uploadFile()">
                    <input
                      type="file"
                      id="file"
                      formControlName="file"
                      accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.csv"
                      (change)="handleFileInput($event.target.files)"
                      #fileUploader
                    />
                    <select formControlName="fileType">
                      <option selected value="arrImgIdProof">
                        Proof of ID
                      </option>
                      <option value="arrImgIncomeProof">Proof of Income</option>
                      <option value="arrImgBankStatements">
                        Bank Statements
                      </option>
                    </select>
                    <input
                      style="margin-left: 10px"
                      class="btn_grey"
                      type="submit"
                      [disabled]="fileToUpload == null"
                      [ngClass]="{ hide: fileToUpload == null }"
                      value="Upload"
                    />
                  </form>
                </td>
              </tr>
              <br />
              <div class="card card--info">
                <div class="card-header">
                  <!-- <h6>My Documents</h6> -->
                </div>
                <div class="card-body">
                  <table
                    class="table card--table proof--table m-0 detail_table"
                  >
                    <tr>
                      <th>Proof of ID</th>
                      <td
                        *ngIf="
                          userDetail?.arrImgIdProof &&
                          userDetail?.arrImgIdProof?.length > 0
                        "
                        class="property--image col-12"
                      >
                        <ng-template
                          *ngIf="userDetail?.arrImgIdProof?.length > 0"
                          ngFor
                          let-proof
                          [ngForOf]="userDetail?.arrImgIdProofObj"
                        >
                          <div
                            *ngIf="
                              proof.type === 'png' ||
                              proof.type === 'jpg' ||
                              proof.type === 'jpeg'
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              style="cursor: pointer"
                              (click)="showImage(proof.url, 'Proof of ID')"
                              [src]="proof.url"
                            />
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIdProof')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc !== false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <a [href]="proof.url" target="_blank">
                              <img
                                *ngIf="proof.type === 'pdf'"
                                src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'doc' || proof.type === 'docx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'xls' || proof.type === 'xlsx'
                                "
                                src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'ppt' || proof.type === 'pptx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                              />
                              <img
                                *ngIf="proof.type === 'csv'"
                                src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                              />
                              <img
                                *ngIf="proof.type === 'zip'"
                                src="assets/images/zipicon.png"
                                style="width: auto"
                              />
                              <img
                                *ngIf="
                                  proof.type === '' ||
                                  proof.type === undefined ||
                                  proof.type === 'undefined'
                                "
                                src="assets/images/default.png"
                                style="width: auto"
                              />
                              <div
                                class="uploaded-file"
                                (click)="trackDownloadDocument(proof.url)"
                              >
                                {{ proof.name }}
                              </div>
                            </a>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIdProof')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc === false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              *ngIf="proof.type === 'pdf'"
                              src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                            />
                            <img
                              *ngIf="
                                proof.type === 'doc' || proof.type === 'docx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'xls' || proof.type === 'xlsx'
                              "
                              src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'ppt' || proof.type === 'pptx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                            />
                            <img
                              *ngIf="proof.type === 'csv'"
                              src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                            />
                            <img
                              *ngIf="proof.type === 'zip'"
                              src="assets/images/zipicon.png"
                              style="width: auto"
                            />
                            <img
                              *ngIf="
                                proof.type === '' ||
                                proof.type === undefined ||
                                proof.type === 'undefined'
                              "
                              src="assets/images/default.png"
                              style="width: auto"
                            />
                            <div
                              class="uploaded-file"
                              (click)="trackDownloadDocument(proof.url)"
                            >
                              {{ proof.name }}
                            </div>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIdProof')" class="delete-div">X</div> -->
                          </div>
                        </ng-template>
                      </td>
                      <td
                        *ngIf="
                          !userDetail?.arrImgIdProof ||
                          userDetail?.arrImgIdProof?.length == 0
                        "
                      >
                        <span *ngIf="!userDetail?.imgIdProof"> N/A </span>
                        <div
                          *ngIf="userDetail?.imgIdProof"
                          class="col-sm-2 mb-3 image--div text-center"
                          style="float: left"
                        >
                          <img
                            style="cursor: pointer"
                            (click)="
                              showImage(userDetail?.imgIdProof, 'Proof of ID')
                            "
                            [src]="userDetail?.imgIdProof"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Proof of Income</th>
                      <td
                        *ngIf="
                          userDetail?.arrImgIncomeProof &&
                          userDetail?.arrImgIncomeProof?.length > 0
                        "
                        class="property--image col-12"
                      >
                        <ng-template
                          *ngIf="userDetail?.arrImgIncomeProof?.length > 0"
                          ngFor
                          let-proof
                          [ngForOf]="userDetail.arrImgIncomeProofObj"
                        >
                          <div
                            *ngIf="
                              proof.type === 'png' ||
                              proof.type === 'jpg' ||
                              proof.type === 'jpeg'
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              style="cursor: pointer"
                              (click)="showImage(proof.url, 'Proof of Income')"
                              [src]="proof.url"
                            />
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIncomeProof')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc !== false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <a [href]="proof.url" target="_blank">
                              <img
                                *ngIf="proof.type === 'pdf'"
                                src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'doc' || proof.type === 'docx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'xls' || proof.type === 'xlsx'
                                "
                                src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'ppt' || proof.type === 'pptx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                              />
                              <img
                                *ngIf="proof.type === 'csv'"
                                src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                              />
                              <img
                                *ngIf="proof.type === 'zip'"
                                src="assets/images/zipicon.png"
                                style="width: auto"
                              />
                              <img
                                *ngIf="
                                  proof.type === '' ||
                                  proof.type === undefined ||
                                  proof.type === 'undefined'
                                "
                                src="assets/images/default.png"
                                style="width: auto"
                              />
                              <div
                                class="uploaded-file"
                                (click)="trackDownloadDocument(proof.url)"
                              >
                                {{ proof.name }}
                              </div>
                            </a>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIncomeProof')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc === false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              *ngIf="proof.type === 'pdf'"
                              src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                            />
                            <img
                              *ngIf="
                                proof.type === 'doc' || proof.type === 'docx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'xls' || proof.type === 'xlsx'
                              "
                              src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'ppt' || proof.type === 'pptx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                            />
                            <img
                              *ngIf="proof.type === 'csv'"
                              src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                            />
                            <img
                              *ngIf="proof.type === 'zip'"
                              src="assets/images/zipicon.png"
                              style="width: auto"
                            />
                            <img
                              *ngIf="
                                proof.type === '' ||
                                proof.type === undefined ||
                                proof.type === 'undefined'
                              "
                              src="assets/images/default.png"
                              style="width: auto"
                            />
                            <div
                              class="uploaded-file"
                              (click)="trackDownloadDocument(proof.url)"
                            >
                              {{ proof.name }}
                            </div>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgIncomeProof')" class="delete-div">X</div> -->
                          </div>
                        </ng-template>
                      </td>
                      <td
                        *ngIf="
                          !userDetail?.arrImgIncomeProof ||
                          userDetail?.arrImgIncomeProof?.length == 0
                        "
                      >
                        <span *ngIf="!userDetail?.imgIncomeProof"> N/A </span>
                        <div
                          *ngIf="userDetail?.imgIncomeProof"
                          class="col-sm-2 mb-3 image--div text-center"
                          style="float: left"
                        >
                          <img
                            style="cursor: pointer"
                            (click)="
                              showImage(
                                userDetail?.imgIncomeProof,
                                'Proof of Income'
                              )
                            "
                            [src]="userDetail?.imgIncomeProof"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Bank Statements</th>
                      <td
                        *ngIf="
                          userDetail?.arrImgBankStatements &&
                          userDetail?.arrImgBankStatements?.length > 0
                        "
                        class="property--image col-12"
                      >
                        <ng-template
                          *ngIf="userDetail?.arrImgBankStatements?.length > 0"
                          ngFor
                          let-proof
                          [ngForOf]="userDetail.arrImgBankStatementsObj"
                        >
                          <div
                            *ngIf="
                              proof.type === 'png' ||
                              proof.type === 'jpg' ||
                              proof.type === 'jpeg'
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              style="cursor: pointer"
                              (click)="showImage(proof.url, 'Bank Statement')"
                              [src]="proof.url"
                            />
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgBankStatements')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc !== false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <a [href]="proof.url" target="_blank">
                              <img
                                *ngIf="proof.type === 'pdf'"
                                src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'doc' || proof.type === 'docx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'xls' || proof.type === 'xlsx'
                                "
                                src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === 'ppt' || proof.type === 'pptx'
                                "
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                              />
                              <img
                                *ngIf="proof.type === 'csv'"
                                src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                              />
                              <img
                                *ngIf="
                                  proof.type === '' ||
                                  proof.type === undefined ||
                                  proof.type === 'undefined'
                                "
                                src="assets/images/default.png"
                                style="width: auto"
                              />
                              <div
                                class="uploaded-file"
                                (click)="trackDownloadDocument(proof.url)"
                              >
                                {{ proof.name }}
                              </div>
                            </a>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgBankStatements')" class="delete-div">X</div> -->
                          </div>
                          <div
                            *ngIf="
                              proof.type !== 'png' &&
                              proof.type !== 'jpg' &&
                              proof.type !== 'jpeg' &&
                              ActiveUser.downloadDoc === false
                            "
                            class="col-sm-2 mb-3 image--div text-center ml-1"
                            style="float: left"
                          >
                            <img
                              *ngIf="proof.type === 'pdf'"
                              src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"
                            />
                            <img
                              *ngIf="
                                proof.type === 'doc' || proof.type === 'docx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'xls' || proof.type === 'xlsx'
                              "
                              src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"
                            />
                            <img
                              *ngIf="
                                proof.type === 'ppt' || proof.type === 'pptx'
                              "
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"
                            />
                            <img
                              *ngIf="proof.type === 'csv'"
                              src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"
                            />
                            <img
                              *ngIf="
                                proof.type === '' ||
                                proof.type === undefined ||
                                proof.type === 'undefined'
                              "
                              src="assets/images/default.png"
                              style="width: auto"
                            />
                            <div
                              class="uploaded-file"
                              (click)="trackDownloadDocument(proof.url)"
                            >
                              {{ proof.name }}
                            </div>
                            <!-- <div *ngIf="ActiveUser.downloadDoc != false" (click)="deleteImage(proof.url, 'arrImgBankStatements')" class="delete-div">X</div> -->
                          </div>
                        </ng-template>
                      </td>
                      <td
                        *ngIf="
                          !userDetail?.arrImgBankStatements ||
                          userDetail?.arrImgBankStatements?.length == 0
                        "
                      >
                        <!-- <span> N/A </span> -->
                        <span *ngIf="!userDetail?.ImgBankStatements">
                          N/A
                        </span>
                        <div
                          *ngIf="userDetail?.ImgBankStatements"
                          class="col-sm-2 mb-3 image--div text-center"
                          style="float: left"
                        >
                          <img
                            style="cursor: pointer"
                            (click)="
                              showImage(
                                userDetail?.ImgBankStatements,
                                'Bank Statement'
                              )
                            "
                            [src]="userDetail?.ImgBankStatements"
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>
          <!-- <div class="col-md-12">
                      <accordion-group heading="Other Users" [isOpen]="openCloseBool">
                          <div class="card card--info">
                              <div class="card-header">

                              </div>
                              <div class="card-body">
                                  <table class="table m-0 detail_table">
                                      <tr>
                                          <th>First Name</th>
                                          <th>Last Name</th>
                                          <th>Address</th>
                                          <th>Date Of Birth</th>
                                          <th>City</th>
                                          <th>Post Code</th>
                                      </tr>
                                      <ng-template ngFor let-otherUser [ngForOf]="otherUserListingArray" let-i="index">
                                          <tr *ngIf="otherUserListingArray?.length > 0">
                                              <td>{{otherUser.firstName}}</td>
                                              <td>{{otherUser.lastName}}</td>
                                              <td>{{otherUser.address}}</td>
                                              <td>{{changeDOB(otherUser.dateOfBirth)}}</td>
                                              <td>{{otherUser.city}}</td>
                                              <td>{{otherUser.postCode}}</td>
                                          </tr>
                                      </ng-template>
                                      <tr *ngIf="otherUserListingArray?.length == 0">
                                          <td colspan="6" class="text-center">
                                              No Users found !
                                          </td>
                                      </tr>
                                  </table>
                              </div>
                          </div>
                      </accordion-group>
                  </div> -->
          <div class="col-md-12">
            <accordion-group
              heading="Additional Members"
              [isOpen]="openCloseBool"
            >
              <div class="card card--info">
                <div class="card-header" style="text-align: right">
                  <!-- <button class="edit_btn" style='font-size:24px' data-toggle="modal" data-target="#addAddtionalMember" > <i class='fas fa-user-plus'></i></button> -->
                  <!-- <h6>Additional Members</h6> -->
                </div>
                <div class="card-body table-responsive wh_table">
                  <table class="table">
                    <!-- <thead> -->
                    <tr>
                      <th class="checkwidth">First Name</th>
                      <th>Last Name</th>
                      <th>Date Of Birth</th>
                      <th>Relationship</th>
                      <th>Proof Of ID</th>
                      <th>Proof Of Income</th>
                      <th>Action</th>
                    </tr>
                    <!-- </thead> -->
                    <!-- <tbody> -->
                    <ng-template
                      ngFor
                      let-additionalMember
                      [ngForOf]="userDetail.additionalMembers"
                      let-i="index"
                    >
                      <tr>
                        <td>{{ additionalMember.firstName }}</td>
                        <td>{{ additionalMember.lastName }}</td>
                        <td>
                          {{
                            changeDOBadditionalMember(
                              additionalMember.dateOfBirth
                            )
                          }}
                        </td>
                        <td>{{ additionalMember.relationshipToYou }}</td>
                        <td class="property--image">
                          <img
                            style="cursor: pointer"
                            (click)="
                              showImage(
                                additionalMember?.imgIncomeProofMember,
                                'Proof of Income'
                              )
                            "
                            *ngIf="additionalMember?.imgIncomeProofMember"
                            [src]="additionalMember.imgIncomeProofMember"
                          />
                          <span *ngIf="!additionalMember?.imgIncomeProofMember">
                            N/A
                          </span>
                        </td>
                        <td class="property--image">
                          <img
                            style="cursor: pointer"
                            (click)="
                              showImage(
                                additionalMember?.imgIdProofMember,
                                'Proof of Income'
                              )
                            "
                            *ngIf="additionalMember?.imgIdProofMember"
                            [src]="additionalMember.imgIdProofMember"
                          />
                          <span *ngIf="!additionalMember?.imgIdProofMember">
                            N/A
                          </span>
                        </td>
                        <td>
                          <div
                            class="card-header d-flex justify-content-between"
                          >
                            <button class="edit_btn" (click)="editDetails('additionalMember' , i)"><i class="fa fa-pencil"
                                                          aria-hidden="true"></i></button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                    <!-- </tbody> -->
                  </table>
                </div>
              </div>
            </accordion-group>
          </div>

          <div
            class="col-md-12 d-flex justify-content-center"
            *ngIf="userDetail.referringAgent == 'N/A' ? false : true"
          >
            <div class="text-center">
              <button
                class="btn"
                [ngClass]="{
                  btn_primary: true
                }"
                [ngStyle]="{
                  'pointer-events': userDetail.verified ? 'none' : 'auto',
                  'background-color': userDetail.verified ? '#bcc0c1' : '',
                  'border-color': userDetail.verified ? '#bcc0c1' : '#4C97A1'
                }"
                (click)="markUserAsVerified(true)"
              >
                Mark User As Verified
              </button>
            </div>
            <div class="text-center ml-5">
              <button
                class="btn btn_danger"
                (click)="markUserAsVerified(false)"
              >
                Mark User As Unverified
              </button>
            </div>
          </div>
        </div>
      </accordion>
      <!-- Old Design user detail page End-->
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addAddtionalMember"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Add new additional member
        </h5>
        <div class="d-flex">
          <button
            type="button"
            class="close"
            id="close-additionalMember-modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="additionalMemberData"
          (ngSubmit)="addAdditionalMember()"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="firstName" class="col-form-label">First Name</label>
              <input
                type="text"
                class="form-control"
                id="firstName"
                formControlName="firstName"
              />
            </div>
            <div class="col-md-6">
              <label for="lastName" class="col-form-label">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="lastName"
                formControlName="lastName"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="dob" class="col-form-label">Date Of Birth</label>
              <input
                type="date"
                class="form-control"
                id="dob"
                formControlName="DOB"
              />
            </div>
            <div class="col-md-6">
              <label for="relationship" class="col-form-label"
                >Relationship</label
              >
              <input
                type="text"
                class="form-control"
                id="relationship"
                formControlName="relationship"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="proofOfId" class="col-form-label">Proof Of ID: </label
              ><br />
              <input
                type="file"
                id="proofOfId"
                formControlName="proofOfId"
                accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.csv"
                (change)="
                  additionalMemberProofsUpload('ID', $event.target.files)
                "
                #fileUploader
              />
            </div>
            <div class="col-md-6">
              <label for="proofOfIncome" class="col-form-label"
                >Proof Of Income: </label
              ><br />
              <input
                type="file"
                id="proofOfIncome"
                formControlName="proofOfIncome"
                accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.ppt,.pptx,.doc,.docx,.csv"
                (change)="
                  additionalMemberProofsUpload('Income', $event.target.files)
                "
                #fileUploader
              />
            </div>
          </div>
          <div class="row">
            <div class="text-right col-12">
              <button type="submit" class="btn btn_primary">Add</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
