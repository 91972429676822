import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CanActivateAdmin {

  constructor( private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var nowDate = new Date();
    const currentDate = nowDate.getDate()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getFullYear();
    const key = CryptoJS.enc.Base64.parse("253D3FB468A0E24677C28A624BE0F939");
    const iv  = CryptoJS.enc.Base64.parse("                ");
    const encrypted = localStorage.getItem('login_session') ? localStorage.getItem('login_session') : false ;
    if(!encrypted){
      localStorage.clear();
    }
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {iv: iv});
    const decryptedDate = decrypted.toString(CryptoJS.enc.Utf8);
    if(currentDate != decryptedDate){
      localStorage.clear();
    }
    if(localStorage.getItem('User') && localStorage.getItem('UserType')){
      return true;
    }else{
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    
  }
}