<div class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">{{staffData && staffData.id ? 'Edit' : 'Add'}} staff for {{providerName}}</h4>
                    <form class="form-sample" [formGroup]="Add_HA_Staff_Form" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Name </label>
                                <input type="text" class="form-control" placeholder="name" formControlName="name" name="name">
                                <div *ngIf="formSubmitted && Add_HA_Staff_Form.controls.name.errors" class="has-error">
                                    <span *ngIf="Add_HA_Staff_Form.controls.name.errors.required">Name is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Email </label>
                                <input type="email" class="form-control" placeholder="email" formControlName="email" name="email">
                                <div *ngIf="formSubmitted && Add_HA_Staff_Form.controls.email.errors" class="has-error">
                                    <span *ngIf="Add_HA_Staff_Form.controls.email.errors.required">Email is required</span>
                                    <span *ngIf="Add_HA_Staff_Form.controls.email.errors.email">Enter valid email</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Phone </label>
                                <input type="text" class="form-control" placeholder="phone" formControlName="phone" name="phone">
                                <div *ngIf="formSubmitted && Add_HA_Staff_Form.controls.phone.errors" class="has-error">
                                    <span *ngIf="Add_HA_Staff_Form.controls.phone.errors.required">Phone is required</span>
                                    <span *ngIf="Add_HA_Staff_Form.controls.phone.errors.pattern">Enter valid phone</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Address </label>
                                <input type="text" class="form-control" placeholder="Address" formControlName="address" name="address">
                                <div *ngIf="formSubmitted && Add_HA_Staff_Form.controls.address.errors" class="has-error">
                                    <span *ngIf="Add_HA_Staff_Form.controls.address.errors.required">Address is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()" type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>