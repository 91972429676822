import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-add-user-note',
  templateUrl: './add-user-note.component.html',
  styleUrls: ['./add-user-note.component.scss']
})
export class AddUserNoteComponent implements OnInit {

  formSubmitted = false;
  AddNoteForm: FormGroup;
  userObject;
  loggedInStaff;
  ActiveAgent;
  staffUserName;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder : FormBuilder,
    private router : Router,
    private apiservice : ApiServiceService,
    private alertnotification : AlertNotification,
    private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService

  ) {
    this.loggedInStaff = JSON.parse(localStorage.getItem("UserType"));
    this.ActiveAgent = JSON.parse(localStorage.getItem("ActiveAgent"));
    this.staffUserName = this.loggedInStaff.name + " ("+this.ActiveAgent.name+")";
    this.AddNoteForm = this.formBuilder.group({
      userName : [null,[Validators.required]],
      staffName : [null,[Validators.required]],
      note : [null,[Validators.required]]
    })
   }

  ngOnInit(): void {
    console.log(this.userObject)
    this.userObject['userName'] = `${this.userObject && this.userObject.firstName ? this.userObject.firstName : ''} ${this.userObject && this.userObject.lastName ? this.userObject.lastName : ''}`;
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.AddNoteForm.invalid) {
      return;
    }else{
      const data =  {
        name: this.AddNoteForm.value.staffName,
        date: new Date(),
        comment: this.AddNoteForm.value.note
      }
      this.firebaseservice.addNotesArray(this.userObject.id,data).then(async () => {
        console.log("Added Successfully");
        this.alertnotification.successAlert("Added Successfully");
        const updateUser = await this.updateUserInfo(this.userObject.email);
        this.alertnotification.updateNoteData();
        this.loaderService.hide();
        this.bsModalRef.hide();
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  updateUserInfo(email) {
    return new Promise((resolve,reject) => {
      var records1= [];
      this.firebaseservice.getUserByEmail(email).then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          var data = doc.data();
          data['id'] = doc.id
          records1.push(data);
        })
        localStorage.setItem("userDetail" ,JSON.stringify(records1[0]));
        resolve(true);
        this.loaderService.hide();
      }.bind(this))
      .catch(function(error) {
        this.loaderService.hide();
        console.log("Error getting documents: ", error);
        reject(false);
      }.bind(this));
    })
  }

}
