import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
// import {AccordionModule} from "ngx-accordion";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/** Modules */

import { SharedModule } from './shared/shared.module';
import { PublicLinkModule } from './public-link/public-link.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AuthModule } from './auth/auth.module';


/** services */
import { FirebaseQueryService } from './core/api-service/firebase-query';
import { ApiServiceService } from './core/api-service/api-service';
import { AlertNotification } from './core/alertnotification/alertNotification';
import { HttpErrorInterceptor } from '../app/core/interceptor-files/interceptor';
import { CanActivateLogin } from '../app/core/guards/authguard';
import { CanActivateAdmin } from '../app/core/guards/loginguard';
import { LoaderService } from '../app/core/common-service/loader-service';
import { CsvFileService } from '../app/core/common-service/exportCSV';

/** firebase */
import {firebaseConfig} from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
// import { LandlordRaComponent } from './landlord-ra/landlord-ra.component';
import { LandlordModule } from './landlord/landlord.module';

@NgModule({
  declarations: [
    AppComponent,
    // LandlordRaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // AdminModule,
    LandlordModule,
    AuthModule,
    SharedModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    NgSelectModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    PublicLinkModule,
    // AdminAuthModule,

  ],
  providers: [FirebaseQueryService,AlertNotification,ApiServiceService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor , multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService , multi: true},
    CanActivateAdmin,
    CanActivateLogin,
    CsvFileService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
