<div class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Change Account Type</h4>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
            <form class="forms-sample" [formGroup]="changeAccountTypeForm" (submit)="onSubmit()">
                <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Account Type</label>
                    <div class="col-sm-10">
                        <!-- <input [(ngModel)]="userObject.type" type="text" class="form-control" formControlName="type" name="type" placeholder="type">
                        <div *ngIf="formSubmitted && changeAccountTypeForm.controls.type.errors" class="has-error">
                            <span *ngIf="changeAccountTypeForm.controls.type.errors.required">type is required </span>
                        </div> -->

                        <ng-select formControlName="type" name="type" [items]="typeArr"
                            placeholder="Select User Type" maxSelectedItems=3 [(ngModel)]="selectedType">
                        </ng-select>
                        <div *ngIf="formSubmitted && changeAccountTypeForm.controls.type.errors" class="has-error">
                            <span *ngIf="changeAccountTypeForm.controls.type.errors.required">type is required </span>
                        </div>
                    </div>
                </div>
                <div class="float-right">
                    <button type="submit" class="btn btn-outline-success btn-fw mr-2">Save</button>
                    <button type="button" (click)="bsModalRef.hide()"
                        class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>