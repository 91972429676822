import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import Swal from "sweetalert2";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AlertNotification {
  constructor() {}

  private dataSource = new BehaviorSubject(0);
  private arrayData = new BehaviorSubject("");
  private noteArray = new BehaviorSubject("");

  currentMessage = this.dataSource.asObservable();
  filteredData = this.arrayData.asObservable();
  getArrayData = this.noteArray.asObservable();

  nextData(data) {
    this.dataSource.next(data);
  }

  nextExport(data) {
    this.arrayData.next(data);
  }

  updateNoteData() {
    this.noteArray.next("true");
  }

  successAlert(message) {
    return Swal.fire({
      position: "center",
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  errorAlert(message) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  failedEmail(title, message) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title: title,
      html: message,
      showConfirmButton: false,
      timer: 5000,
    });
  }

  logout(message) {
    return Swal.fire({
      position: "center",
      icon: "success",
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }
}
