<div class="row m-2">
    <div class="row m-1" style="width: 100%;">
        <div class="col-lg-12 cancel--btn">
            <button (click)="bsModalRef.hide()" class="pull-right">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
    <div class="row" style="width: 100%;">
        <span style="margin: 0 auto;" class="image--title">
            {{proofType}}
        </span>
    </div>
    <div class="row m-2 p-3 image--model">
        <img [src]="ImageUrl" />
    </div>
</div>