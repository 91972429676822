<div class="top_btn">
  <h4>Change Password</h4>
</div>
<div class="staffForm">
  <div class="row">
    <div class="col-md-8">
      <form [formGroup]="createForm" (submit)="onSubmit()">
          <div class="input">
          <label for="">Old Password</label>
          <input
            type="text"
            class="form-control"
            name="oldPassword"
            formControlName="oldPassword"
          />
          <div class="has-error" *ngIf="(createForm.controls.oldPassword.dirty || createForm.controls.oldPassword.touched) && createForm.controls.oldPassword.errors">
            <small *ngIf="createForm.controls.oldPassword.errors.required">Old Password is required</small>
          </div>
        </div>
        <div class="input">
          <label for="">New Password</label>
          <input
            type="password"
            class="form-control"
            name="newPassword"
            formControlName="newPassword"
          />
          <div class="has-error" *ngIf="(createForm.controls.newPassword.dirty || createForm.controls.newPassword.touched) && createForm.controls.newPassword.errors">
            <small *ngIf="createForm.controls.newPassword.errors.required">Password is required</small>
          </div>
        </div>
        <div class="input">
          <label for="">Repeat Password</label>
          <input
            type="password"
            class="form-control"
            name="rPassword"
            formControlName="rPassword"
          />
          <div class="has-error" *ngIf="(createForm.controls.rPassword.dirty || createForm.controls.rPassword.touched) && createForm.controls.rPassword.errors">
            <small *ngIf="createForm.controls.rPassword.errors.required">Confirm Password is required</small>
            <small *ngIf="createForm.controls.rPassword.errors.mustMatch">Passwords confirmation must match with password</small>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <!-- <button class="btn cancel" type="button" >Cancel</button> -->
          <button class="btn createAccount" type="submit">
            Change Password
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
