<div class="userview_header">
    <div class="row justify-content-between align-items-center">
        <div class="col-4"></div>
        <div class="col-4">
            <div class="user--view-header">
                <h4 class="m-0"><i class="fa fa-user-circle"></i> USER PROFILE</h4>
            </div>
        </div>
        <div class="col-4 text-right">
        </div>
    </div>
</div>
<div class="content-wrapper">
    <div class="row">
        <div *ngIf="loginUID" class="col-lg-12 user-view--card grid-margin stretch-card">
            <div class="card mrr-card">
                <div class="card-body">
                    <div class="row mb-5">
                        <div class="userview_notification">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-4"></div>
                                <div class="col-4">
                                    <!-- <div class="user--view-header">
                                        <h4 class="m-0"><i class="fa fa-user-circle"></i> {{currentLandlord}}</h4>
                                    </div> -->
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <button type="button" (click)="sendEmail()" class="btn btn-outline-info btn-fw mr-2">Send email</button>
                                    <button type="button" (click)="sendPush()" class="btn btn-outline-info btn-fw mr-2">Send push</button> -->
                                    <!-- <button type="button" (click)="closePage()" class="btn btn-outline-danger btn-fw mr-2"><i class="fa fa-times"></i></button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2 mb-5 cover--photo">
                            <img style="cursor: pointer;" (click)="showImage(userDetail?.imgProfile,userDetail?.firstName+' Profile Image')" *ngIf="userDetail?.imgProfile" [src]="userDetail.imgProfile" />
                            <img *ngIf="!userDetail?.imgProfile" src="assets/images/icon_gradiant.png" />
                        </div>
                    </div>
                    <accordion>
                        <div class="row">
                            <div class="col-sm-6">
                                <accordion-group heading="Profile Details" [isOpen]="openCloseBool">
                                    <div class="card card--info">
                                        <div class="card-body">
                                            <table class="table card--table m-0 detail_table">
                                                <tr>
                                                    <th>Full Name</th>
                                                    <td>{{userDetail?.firstName}} {{userDetail?.lastName}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Age</th>
                                                    <td>{{userDetail?.age}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Birth</th>
                                                    <td>{{changeDOB(userDetail.dateOfBirth)}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{{userDetail?.email}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone Number</th>
                                                    <td>{{userDetail?.phoneNumber1}}</td>
                                                </tr>
                                                <tr>
                                                    <th>City</th>
                                                    <td>{{userDetail?.city}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>{{userDetail?.address}}</td>
                                                </tr>
                                                <tr *ngIf="userDetail.noOfMonthsAtAdress">
                                                    <th>Number of year at Address</th>
                                                    <td>{{userDetail?.noOfMonthsAtAdress}}</td>
                                                </tr>
                                                <tr *ngIf="userDetail.noOfYearsAtAdress">
                                                    <th>Number of year at Address</th>
                                                    <td>{{userDetail?.noOfYearsAtAdress}}</td>
                                                </tr>
                                                <tr>
                                                    <th>Postcode</th>
                                                    <td>{{userDetail?.postCode}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </accordion-group>
                            </div>


                        <div class="col-sm-6">
                            <accordion-group heading="Other Details" [isOpen]="openCloseBool">
                            <div class="card card--info">
                                <div class="card-body">
                                    <table class="table card--table m-0 detail_table">

                                        <tr *ngIf="userDetail.email1">
                                            <th>Alternative Email</th>
                                            <td>{{userDetail?.email1}}</td>
                                        </tr>
                                        <tr *ngIf="!userDetail.email1">
                                            <th>Alternative Email</th>
                                            <td>{{userDetail?.email2}}</td>
                                        </tr>
                                        <tr>
                                            <th>Alternative Phone</th>
                                            <td>{{userDetail?.phoneNumber2}}</td>
                                        </tr>
                                        <tr>
                                            <th>Rent Arrears</th>
                                            <td>{{userDetail?.rentArrearsAmount}}</td>
                                        </tr>
                                        <tr>
                                            <th>Medical Details</th>
                                            <td>{{userDetail?.medicalDetails}}</td>
                                        </tr>
                                        <tr>
                                            <th>ASB</th>
                                            <td *ngIf="userDetail.isASBDetailsProvided">{{userDetail?.asbDetails}}</td>
                                            <td *ngIf="!userDetail.isASBDetailsProvided">
                                                {{userDetail.isASBDetailsProvided}}</td>
                                        </tr>
                                        <tr>
                                            <th>Risk</th>
                                            <td *ngIf="userDetail.isASBDetailsProvided">{{userDetail?.asbDetails}}</td>
                                            <td *ngIf="!userDetail.isASBDetailsProvided">
                                                {{userDetail.applicantDangerToStaff}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </accordion-group>
                        </div>
                        <div class="col-sm-6">
                            <accordion-group heading="Family Composition" [isOpen]="userDetail.firstNameFC === undefined || userDetail.firstNameFC === '' ? false :openCloseBool">
                            <div class="card card--info">
                                <div class="card-body">
                                    <table class="table card--table m-0 detail_table">
                                        <tr>
                                            <th>Full Name</th>
                                            <td>{{userDetail?.firstNameFC}} {{userDetail?.lastNameFC}}</td>
                                        </tr>
                                        <tr>
                                            <th>Date of Birth</th>
                                            <td>{{changeDOB(userDetail?.dateOfBirthFC)}}</td>
                                        </tr>
                                        <tr>
                                            <th>Relationship to You</th>
                                            <td>{{userDetail?.relationshipToYouFC}}</td>
                                        </tr>
                                        <tr>
                                            <th>Bed room size require</th>
                                            <td>{{userDetail?.bedroomSize}}</td>
                                        </tr>
                                        <tr>
                                            <th>Current Accommodation</th>
                                            <td>{{userDetail?.currentAccommodation}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <th>ASB</th>
                                            <td *ngIf="userDetail.isASBDetailsProvided">{{userDetail?.asbDetails}}</td>
                                            <td *ngIf="!userDetail.isASBDetailsProvided">{{userDetail.isASBDetailsProvided}}</td>
                                        </tr> -->
                                    </table>
                                </div>
                            </div>
                        </accordion-group>
                        </div>
                        <div class="col-sm-6">
                            <accordion-group heading="Landlord Details" [isOpen]="userDetail.landlordName === undefined ? false :openCloseBool">
                            <div class="card card--info">
                                <div class="card-body">
                                    <table class="table landlord--Info card--table m-0 detail_table">
                                        <tr>
                                            <th>Landlord Name</th>
                                            <td>{{userDetail?.landlordName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Landlord Phone Number</th>
                                            <td>{{userDetail?.landlordPhoneNumber}}</td>
                                        </tr>
                                        <tr>
                                            <th>Landlord Email</th>
                                            <td>{{userDetail?.landlordEmail}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            </accordion-group>
                        </div>
                        <div class="col-md-6" *ngIf="userDetail.public === true">
                            <accordion-group heading="Address History" [isOpen]="userDetail?.addressHistory2 === ' ' || userDetail?.addressHistory2 == '' ? false : openCloseBool">
                                <div class="card card--info">
                                    <div class="card-header d-flex justify-content-between">
                                        <!-- <h6>Other Details</h6> -->
                                        <!-- <button class="edit_btn" (click)="editDetails('otherDetails', null)"><i class="fa fa-pencil"
                                                aria-hidden="true"></i></button> -->
                                    </div>
                                    <div class="card-body">
                                        <table class="table card--table m-0 detail_table">
                                            <tr>
                                                <th>Address 2</th>
                                                <td>{{userDetail?.addressHistory2}}</td>
                                            </tr>
                                            <tr>
                                                <th>Address 3</th>
                                                <td>{{userDetail?.addressHistory3}}</td>
                                            </tr>
                                            <tr>
                                                <th>Address 4</th>
                                                <td>{{userDetail?.addressHistory4}}</td>
                                            </tr>
                                            <tr>
                                                <th>Address 5</th>
                                                <td>{{userDetail?.addressHistory5}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </accordion-group>
                        </div>
                        <div class="col-md-6" *ngIf="userDetail.public === true">
                            <accordion-group heading="Income Details" [isOpen]="openCloseBool">
                                <div class="card card--info">
                                    <div class="card-header d-flex justify-content-between">
                                        <!-- <h6>Other Details</h6> -->
                                        <!-- <button class="edit_btn" (click)="editDetails('otherDetails', null)"><i class="fa fa-pencil"
                                                aria-hidden="true"></i></button> -->
                                    </div>
                                    <div class="card-body">
                                        <table class="table card--table m-0 detail_table">
                                            <tr>
                                                <th>Employment Details</th>
                                                <td>{{userDetail?.employmentDetails}}</td>
                                            </tr>
                                            <tr>
                                                <th>Income (£) </th>
                                                <td>{{userDetail?.income}}</td>
                                            </tr>
                                            <tr>
                                                <th>National Insurance Number</th>
                                                <td>{{userDetail?.nationalInsuranceNumber}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </accordion-group>
                        </div>
                        <div class="col-md-12">
                            <accordion-group heading="Shortlisted Properties" [isOpen]="openCloseBool">
                                <div class="card card--info">
                                    <div class="card-header">
                                        <!-- <h6>Shortlisted Properties</h6> -->
                                        <!-- <div class="btns">
                                            <a type="button" class="btn_grey pull-right" (click)="getShortListedPropertyByEmail(userDetail.email)">View All</a>

                                        </div> -->
                                    </div>
                                    <div class="card-body  table-responsive wh_table">
                                        <table class="table card--table m-0 detail_table">
                                            <thead>
                                                <tr>
                                                    <th>Property address</th>
                                                    <th>Added On </th>
                                                    <th>Provider </th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-template ngFor let-property
                                                    [ngForOf]="userDetail?.shortlistedProperties | paginate: { itemsPerPage: 10, currentPage: p }"
                                                    let-i="index">
                                                    <tr *ngIf="property.shortlisted == 'true'">
                                                        <td>
                                                            {{property.property_address}}
                                                        </td>
                                                        <td>
                                                            <!-- {{convertDate(property.created)}} -->
                                                        </td>
                                                        <td>
                                                            {{property.provider}}
                                                        </td>
                                                        <td>
                                                            {{property.shortlisted_status || ''}}
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <tr *ngIf="userDetail?.shortlistedProperties?.length == 0">
                                                    <td colspan="4" class="text-center">
                                                        No documents found !
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </accordion-group>
                        </div>
                        <div class="col-md-12">
                            <accordion-group heading="Matched Properties" [isOpen]="openCloseBool">
                                <div class="card card--info">
                                    <div class="card-header">
                                        <!-- <h6>Shortlisted Properties</h6>
                                        <div class="btns">
                                            <a type="button" class="btn_grey pull-right" (click)="getShortListedPropertyByEmail(userDetail.email)">View All</a>

                                        </div> -->
                                   </div>
                                    <div class="card-body table-responsive wh_table">
                                        <table class="table card--table m-0 detail_table">
                                            <thead>
                                                <tr>
                                                    <th>Property address</th>
                                                    <th>Distance</th>
                                                    <th>Added On </th>
                                                    <th>Provider </th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-template ngFor let-property
                                                    [ngForOf]="userDetail?.matchedProperties | paginate: { itemsPerPage: 10, currentPage: p }"
                                                    let-i="index">
                                                    <tr *ngIf="property.shortlisted == 'true'">
                                                        <td>
                                                            {{property.property_address}}
                                                        </td>
                                                        <td>
                                                            {{property.radius}} miles
                                                        </td>
                                                        <td>
                                                            {{convertDate(property.created)}}
                                                        </td>
                                                        <td>
                                                            {{property.provider}}
                                                        </td>
                                                        <td>
                                                            {{property.shortlisted_status || ''}}
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <tr *ngIf="userDetail?.matchedProperties?.length == 0">
                                                    <td colspan="4" class="text-center">
                                                        No documents found !
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </accordion-group>
                        </div>
                        <div class="col-sm-12">
                            <accordion-group heading="My Documents" [isOpen]="openCloseBool">
                            <div class="card card--info">

                                <div class="card-body">
                                    <table class="table card--table proof--table m-0 detail_table">
                                        <tr>
                                            <th>Proof of ID</th>
                                            <td *ngIf="userDetail?.arrImgIdProof && userDetail?.arrImgIdProof?.length > 0"
                                                class="property--image col-12">
                                                <ng-template *ngIf="userDetail?.arrImgIdProof?.length > 0" ngFor let-proof
                                                    [ngForOf]="userDetail?.arrImgIdProofObj">
                                                    <div *ngIf="proof.type === 'png' || proof.type === 'jpg' || proof.type === 'jpeg'" class="col-sm-2 mb-3 image--div text-center ml-1" style="float:left">
                                                        <img style="cursor: pointer;" (click)="showImage(proof.url,'Proof of ID')"
                                                            [src]="proof.url" />
                                                    </div>
                                                    <div *ngIf="proof.type !== 'png' && proof.type !== 'jpg' && proof.type !== 'jpeg'" class="col-sm-2 mb-3 image--div text-center  ml-1" style="float:left">
                                                        <a [href]="proof.url" target="_blank">
                                                            <img *ngIf="proof.type === 'pdf'" src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"/>
                                                            <img *ngIf="proof.type === 'doc' || proof.type === 'docx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"/>
                                                            <img *ngIf="proof.type === 'xls' || proof.type === 'xlsx'" src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"/>
                                                            <img *ngIf="proof.type === 'ppt' || proof.type === 'pptx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"/>
                                                            <img *ngIf="proof.type === 'csv'" src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"/>
                                                            <img *ngIf="proof.type === 'zip'" src="assets/images/zipicon.png" style="width: auto;"/>
                                                            <div class="uploaded-file">{{proof.name}}</div>
                                                        </a>
                                                    </div>
                                                </ng-template>
                                            </td>
                                            <td *ngIf="!userDetail?.arrImgIdProof || userDetail?.arrImgIdProof?.length == 0">
                                                <span *ngIf="!userDetail?.imgIdProof"> N/A </span>
                                                <div *ngIf="userDetail?.imgIdProof" class="col-sm-2 mb-3 image--div text-center"
                                                    style="float:left">
                                                    <img style="cursor: pointer;"
                                                        (click)="showImage(userDetail?.imgIdProof,'Proof of ID')"
                                                        [src]="userDetail?.imgIdProof" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Proof of Income</th>
                                            <td *ngIf="userDetail?.arrImgIncomeProof && userDetail?.arrImgIncomeProof?.length > 0"
                                                class="property--image col-12">
                                                <ng-template *ngIf="userDetail?.arrImgIncomeProof?.length > 0" ngFor let-proof
                                                    [ngForOf]="userDetail.arrImgIncomeProofObj">
                                                    <div *ngIf="proof.type === 'png' || proof.type === 'jpg' || proof.type === 'jpeg'" class="col-sm-2 mb-3 image--div text-center  ml-1" style="float:left">
                                                        <img style="cursor: pointer;" (click)="showImage(proof.url,'Proof of Income')"
                                                            [src]="proof.url" />
                                                    </div>
                                                    <div *ngIf="proof.type !== 'png' && proof.type !== 'jpg' && proof.type !== 'jpeg'" class="col-sm-2 mb-3 image--div text-center  ml-1" style="float:left">
                                                        <a [href]="proof.url" target="_blank">
                                                            <img *ngIf="proof.type === 'pdf'" src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"/>
                                                            <img *ngIf="proof.type === 'doc' || proof.type === 'docx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"/>
                                                            <img *ngIf="proof.type === 'xls' || proof.type === 'xlsx'" src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"/>
                                                            <img *ngIf="proof.type === 'ppt' || proof.type === 'pptx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"/>
                                                            <img *ngIf="proof.type === 'csv'" src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"/>
                                                            <img *ngIf="proof.type === 'zip'" src="assets/images/zipicon.png" style="width: auto;"/>
                                                            <div class="uploaded-file">{{proof.name}}</div>
                                                        </a>
                                                    </div>
                                                </ng-template>
                                            </td>
                                            <td
                                                *ngIf="!userDetail?.arrImgIncomeProof || userDetail?.arrImgIncomeProof?.length == 0">
                                                <span *ngIf="!userDetail?.imgIncomeProof"> N/A </span>
                                                <div *ngIf="userDetail?.imgIncomeProof"
                                                    class="col-sm-2 mb-3 image--div text-center" style="float:left">
                                                    <img style="cursor: pointer;"
                                                        (click)="showImage(userDetail?.imgIncomeProof,'Proof of Income')"
                                                        [src]="userDetail?.imgIncomeProof" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Bank Statements</th>
                                            <td *ngIf="userDetail?.arrImgBankStatements && userDetail?.arrImgBankStatements?.length > 0"
                                                class="property--image col-12">
                                                <ng-template *ngIf="userDetail?.arrImgBankStatements?.length > 0" ngFor
                                                    let-proof [ngForOf]="userDetail.arrImgBankStatementsObj">
                                                    <div *ngIf="proof.type === 'png' || proof.type === 'jpg' || proof.type === 'jpeg'" class="col-sm-2 mb-3 image--div text-center  ml-1" style="float:left">
                                                        <img style="cursor: pointer;" (click)="showImage(proof.url,'Bank Statement')"
                                                            [src]="proof.url" />
                                                    </div>
                                                    <div *ngIf="proof.type !== 'png' && proof.type !== 'jpg' && proof.type !== 'jpeg'" class="col-sm-2 mb-3 image--div text-center  ml-1" style="float:left">
                                                        <a [href]="proof.url" target="_blank">
                                                            <img *ngIf="proof.type === 'pdf'" src="https://play-lh.googleusercontent.com/9XKD5S7rwQ6FiPXSyp9SzLXfIue88ntf9sJ9K250IuHTL7pmn2-ZB0sngAX4A2Bw4w"/>
                                                            <img *ngIf="proof.type === 'doc' || proof.type === 'docx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8d/Microsoft_Word_2013-2019_logo.svg/782px-Microsoft_Word_2013-2019_logo.svg.png"/>
                                                            <img *ngIf="proof.type === 'xls' || proof.type === 'xlsx'" src="https://www.freeiconspng.com/thumbs/xls-icon/excel-png-office-xlsx-icon-3.png"/>
                                                            <img *ngIf="proof.type === 'ppt' || proof.type === 'pptx'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/.pptx_icon_%282016-2019%29.svg/1799px-.pptx_icon_%282016-2019%29.svg.png"/>
                                                            <img *ngIf="proof.type === 'csv'" src="https://cdn-icons-png.flaticon.com/512/4911/4911248.png"/>
                                                            <div class="uploaded-file">{{proof.name}}</div>
                                                        </a>
                                                    </div>
                                                </ng-template>
                                            </td>
                                            <td
                                                *ngIf="!userDetail?.arrImgBankStatements || userDetail?.arrImgBankStatements?.length == 0">
                                                <!-- <span> N/A </span> -->
                                                <span *ngIf="!userDetail?.ImgBankStatements"> N/A </span>
                                                <div *ngIf="userDetail?.ImgBankStatements"
                                                    class="col-sm-2 mb-3 image--div text-center" style="float:left">
                                                    <img style="cursor: pointer;"
                                                        (click)="showImage(userDetail?.ImgBankStatements,'Bank Statement')"
                                                        [src]="userDetail?.ImgBankStatements" />
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            </accordion-group>
                        </div>
                        <div class="col-sm-12" *ngIf="userDetail.additionalMembers && userDetail.additionalMembers.length > 0 ">
                            <accordion-group heading="Additional Members" [isOpen]="openCloseBool">
                            <div class="card card--info">
                                <div class="card-body">
                                    <table class="table">
                                        <!-- <thead> -->
                                        <tr>
                                            <th class="checkwidth">First Name</th>
                                            <th>Last Name</th>
                                            <th>Date Of Birth</th>
                                            <th>Relationship</th>
                                            <th>Proof Of ID</th>
                                            <th>Proof Of Income</th>
                                        </tr>
                                        <!-- </thead> -->
                                        <!-- <tbody> -->
                                        <ng-template ngFor let-additionalMember [ngForOf]="userDetail.additionalMembers">
                                            <tr>
                                                <td>{{additionalMember.firstName}}</td>
                                                <td>{{additionalMember.lastName}}</td>
                                                <td>{{additionalMember.dateOfBirth}}</td>
                                                <td>{{additionalMember.relationshipToYou}}</td>
                                                <td class="property--image">
                                                    <img style="cursor: pointer;" (click)="showImage(additionalMember?.imgIncomeProofMember,'Proof of ID')" *ngIf="additionalMember?.imgIncomeProofMember" [src]="additionalMember.imgIncomeProofMember" />
                                                    <span *ngIf="!additionalMember?.imgIncomeProofMember"> N/A </span>
                                                </td>
                                                <td class="property--image">
                                                    <img style="cursor: pointer;" (click)="showImage(additionalMember?.imgIdProofMember,'Proof of Income')" *ngIf="additionalMember?.imgIdProofMember" [src]="additionalMember.imgIdProofMember" />
                                                    <span *ngIf="!additionalMember?.imgIdProofMember"> N/A </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <!-- </tbody> -->
                                    </table>
                                </div>
                            </div>
                            </accordion-group>
                        </div>
                    </div>
                    </accordion>

                </div>
            </div>
        </div>
    </div>
</div>
