import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-account-type',
  templateUrl: './change-account-type.component.html',
  styleUrls: ['./change-account-type.component.scss']
})
export class ChangeAccountTypeComponent implements OnInit {
  formSubmitted = false;
  changeAccountTypeForm: FormGroup;
  userObject
  typeArr = ["Owner", "Editor", "Viewer"];
  selectedType: string[];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {
    this.changeAccountTypeForm = this.formBuilder.group({
      type: [null, [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.selectedType = this.userObject.type;
  }


  onSubmit() {
    console.log(this.changeAccountTypeForm.value, 'value')
    this.formSubmitted = true;
    if (this.changeAccountTypeForm.invalid) {
      return;
    } else {
      let data = {
        "email": this.userObject.email,
        "type": this.changeAccountTypeForm.value.type
      }
      this.apiservice.postRequest_b2(`/updateUserType`, data).subscribe(async (response: any) => {
        // this.authUserListArray = response.data;
        this.loaderService.hide();
        this.bsModalRef.hide();
        this.alertnotification.successAlert("Updated Successfully");

      },
        error => {
          this.alertnotification.errorAlert(error.error.message);
        });
    }
  }

}
