import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {
  title = new BehaviorSubject('Initial Title');
  private notificationCount: BehaviorSubject<any> = new BehaviorSubject<any>('')
  setTitle(title: string) {
    this.title.next(title);
  }
  setNotification(notificationCount){
    this.notificationCount.next(notificationCount)
    localStorage.setItem('notification', notificationCount)
  }
  getNotification() {
    return this.notificationCount.asObservable();
  }

  constructor() {
    if(localStorage.getItem('notification')){
      let value = localStorage.getItem('notification')
      this.notificationCount.next(value);
      
    }
   }
}

