import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicLinkComponent } from './public-link.component';
import { UserdetailComponent } from './userdetail/userdetail.component';
import { PropertydetailComponent } from './propertydetail/propertydetail.component';

const routes: Routes = [
  { path : 'msh' , component: PublicLinkComponent ,
    children : [
      { path: 'userdetail/:id', component : UserdetailComponent, pathMatch: 'full'},
      { path: 'propertydetail/:id', component : PropertydetailComponent, pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicLinkRoutingModule { }
