<div class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Add Note</h4>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
            <form class="forms-sample" [formGroup]="AddNoteForm" (submit)="onSubmit()">
                <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">User Name</label>
                    <div class="col-sm-10">
                        <input [(ngModel)]="userObject.userName" type="text" class="form-control" formControlName="userName" name="userName" placeholder="User Name" readonly>
                        <div *ngIf="formSubmitted && AddNoteForm.controls.userName.errors" class="has-error">
                            <span *ngIf="AddNoteForm.controls.userName.errors.required">userName is required </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Staff Name</label>
                    <div class="col-sm-10">
                        <input [(ngModel)]="staffUserName" type="text" class="form-control" formControlName="staffName" name="staffName" placeholder="staffName" readonly>
                        <div *ngIf="formSubmitted && AddNoteForm.controls.staffName.errors" class="has-error">
                            <span *ngIf="AddNoteForm.controls.staffName.errors.required">staffName is required </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Note</label>
                    <div class="col-sm-10">
                        <textarea type="text" class="form-control" formControlName="note" name="note" placeholder="note" rows="5"></textarea>
                        <div *ngIf="formSubmitted && AddNoteForm.controls.note.errors" class="has-error">
                            <span *ngIf="AddNoteForm.controls.note.errors.required">note is required </span>
                        </div>
                    </div>
                </div>
                <div class="float-right">
                    <button type="submit" class="btn btn-outline-success btn-fw mr-2">Add</button>
                    <button type="button" (click)="bsModalRef.hide()" class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>
