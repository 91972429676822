<!--new design-->

<div class="top_bar_block" *ngIf="showFilter">
  <div class="filter_btn" data-target="#filter_box_dropdown"></div>

  <section
    id="filter_box_dropdown"
    class="filter_box_dropdown position-relative"
  >
    <div class="close_icon">
      <i class="fa fa-close" (click)="showFilter = false"></i>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6 filter_left">
          <h6>
            Show closet user first
            <div class="custom-control custom-switch">
              <input
                [checked]="filters.closestFirst"
                (change)="onchangeCheckbox($event)"
                type="checkbox"
                class="custom-control-input"
                id="customSwitch1"
              />
              <label class="custom-control-label" for="customSwitch1"></label>
            </div>
          </h6>
          <form>
            <div class="form-group">
              <h6 for="formControlRange">
                How far from you? <strong>{{ filters.miles }} miles</strong>
              </h6>
              <ng5-slider
                [(value)]="filters.miles"
                [options]="options"
                (userChangeEnd)="onChangeMiles($event)"
              ></ng5-slider>
            </div>
          </form>
        </div>

        <div class="col-md-6 col-sm-6 filter_right">
          <h6>
            Distance
            <div>
              <div class="custom-control custom-radio btn-radio">
                <input
                  [checked]="filters.closestFirst"
                  [value]="1"
                  (change)="onChangeDistance('asc')"
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  class="custom-control-input"
                  checked
                />
                <label class="custom-control-label" for="customRadio1"
                  >Min to max</label
                >
              </div>
              <div class="custom-control custom-radio btn-radio">
                <input
                  [checked]="!filters.closestFirst"
                  [value]="0"
                  (change)="onChangeDistance('desc')"
                  type="radio"
                  id="customRadio2"
                  name="customRadio"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="customRadio2"
                  >Max to min</label
                >
              </div>
            </div>
          </h6>

          <div class="text-right col-sm-12 mt-3 submit_filter_section">
            <button class="btn clear_btn" (click)="onClickReset()">
              Clear all filter
            </button>
            <button class="btn btn-primary" (click)="onClickSave(false)">
              Show Results
            </button>
          </div>
        </div>
      </div>
      <div class="property-filter-result">
        <div *ngIf="usersListArray?.length > 0">
          <div class="total_count">Total {{ usersListArray.length }}</div>
          <div class="table-responsive wh_table">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th style="cursor: pointer">
                    <div class="d-flex">
                      <i
                        class="fa fa-sort mr-1"
                        (click)="sortDotsListingArray('documentCount')"
                      ></i>
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-arrow-down':
                            sortvalue[1] == 'asc' &&
                            sortvalue[0] == 'documentCount',
                          'fa-arrow-up':
                            sortvalue[1] == 'desc' &&
                            sortvalue[0] == 'documentCount'
                        }"
                      ></i>
                    </div>
                  </th>
                  <th style="cursor: pointer">Distance</th>
                  <th style="cursor: pointer" (click)="sortLisitng('name')">
                    Name<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'name',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'name'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer">Age</th>
                  <th style="cursor: pointer" (click)="sortLisitng('date')">
                    Date<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'date',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'date'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('phone')">
                    Phone<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'phone',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'phone'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('address')">
                    Address
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'address',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'address'
                      }"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  ngFor
                  let-user
                  [ngForOf]="
                    usersListArray
                      | SortingFilter: sortvalue
                      | paginate: { itemsPerPage: 20, currentPage: p }
                  "
                  let-i="index"
                >
                  <tr [ngClass]="{ 'has-active-error': user.new_added == 1 }">
                    <td>
                      <input
                        type="checkbox"
                        id="exampleCheck1"
                        (click)="
                          selectUserCheckBox(
                            $event,
                            user.user_email,
                            user.documentCount,
                            user.uid
                          )
                        "
                        [checked]="userEmailList?.includes(user.user_email)"
                      />
                    </td>
                    <td>
                      <span
                        class="user-document-count"
                        [ngStyle]="{
                          'background-color':
                            user.documentCount == 3
                              ? '#62c91c'
                              : user.documentCount == 2
                              ? '#f4c76e'
                              : user.documentCount == 1
                              ? '#e61010'
                              : '#D3D3D3'
                        }"
                        >{{ user.documentCount }}</span
                      >
                    </td>
                    <td>{{ user.radius | number: "1.0-1" }} miles</td>
                    <td>
                      <a
                        (click)="clickOnUserEmail(user.user_email)"
                        target="_blank"
                        class="link"
                        >{{ user.name }}</a
                      >
                    </td>
                    <td>{{ user.age }}</td>
                    <td>{{ user.createDate }}</td>
                    <td>{{ user.phone }}</td>
                    <td>{{ user.address }}</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>

            <div
              *ngIf="usersListArray?.length > 0"
              class="pagination mt-4 mb-4 wh_pagination Pagiantion"
            >
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="p = $event"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>

            <div class="modal-footer" style="justify-content: flex-start">
              <button
                [disabled]="usersListArray.length == 0"
                type="button"
                class="btn_grey"
                data-toggle="modal"
                data-target="#sendMailProperties"
                data-dismiss="modal"
                (click)="onPropertySelect()"
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
        <div class="row wh_listing" *ngIf="usersListArray?.length == 0">
          <p style="margin: 0 auto">
            No user from {{ ActiveAgent.name }} found nearby this property
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="total_count">Total {{ propertiesListArray.length }}</div>
</div>

<!-- Modal to send email-->
<div
  class="modal fade"
  id="sendMailProperties"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog mg-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Send Mail to Users</h5>
        <div class="d-flex">
          <button
            type="button"
            class="close"
            id="close-modal-mail"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="sendMailData" (ngSubmit)="sendmail()">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              >Emails</label
            >
            <div class="col-sm-10">
              <input
                type="email"
                class="form-control"
                id="inputEmail3"
                value="{{ recipient }}"
                formControlName="emails"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="title" class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                id="title"
                formControlName="subject"
                [(ngModel)]="emailTemplateTitle"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="textarea" class="col-sm-2 col-form-label"
              >Content</label
            >
            <div class="col-sm-10" id="textarea-c">
              <textarea
                name=""
                id="textarea-c"
                class="form-control"
                cols="30"
                rows="6"
                formControlName="textcontent"
                [(ngModel)]="textcontentData"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="textarea" class="col-sm-2 col-form-label"
              >Property URls</label
            >
            <div class="col-sm-10" id="textarea-c">
              <textarea
                name=""
                id="textarea-c"
                class="form-control"
                cols="30"
                rows="4"
                formControlName="content"
                [(ngModel)]="allcontentData"
                >{{ allcontentData }}</textarea
              >
            </div>
          </div>
          <div class="row">
            <div class="text-right col-6">
              <button
                type="submit"
                class="btn btn_primary btn-lg"
                [ngStyle]="{
                  padding: '20px',
                  fontSize: '25px',
                  marginRight: '-48px'
                }"
                [disabled]="
                  !emailTemplateTitle || !allcontentData || !textcontentData
                "
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isFilterShow" class="property_filter">
  <div class="row">
    <div class="col-sm-12 text-right filter--Div">
      <div class="filter--age col-sm-4 text-left">
        <label class="filter--label mr-2 ml-2">Bedroom</label>
        <ng-select
          class="mr-2"
          [(ngModel)]="bedroom1"
          [items]="bedFilterArray"
          (change)="onChangeBed($event, 0)"
          placeholder="Min"
        ></ng-select>
        <ng-select
          class="mr-2"
          [(ngModel)]="bedroom2"
          [items]="bedFilterArray"
          (change)="onChangeBed($event, 1)"
          placeholder="Max"
        ></ng-select>
      </div>
      <div class="filter--bed col-sm-3 text-left">
        <input
          type="text"
          (keyup)="typeSearch()"
          class="form-control"
          aria-label="Text input with dropdown button"
          [(ngModel)]="searchValue"
          placeholder="search"
        />
        <div class="input-group-append">
          <button
            [disabled]="!searchValue"
            class="ml-2 btn btn-outline-danger btn-fw"
            (click)="clearSearch()"
          >
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="filter--agent text-left col-sm-3">
        <ng-select
          [(ngModel)]="providerCheck"
          [searchable]="false"
          [clearable]="true"
          [items]="providerArrayList"
          (change)="onChangeProviderFilter($event)"
          placeholder="Filter by Provider"
        ></ng-select>
      </div>
      <div class="filter--bed text-left col-sm-2 border-none">
        <ng-select
          bindLabel="name"
          bindValue="name"
          style="width: 100%"
          [searchable]="true"
          [clearable]="true"
          [items]="filterCaseofficerArr"
          (change)="onChangeAssignedUsers($event)"
          placeholder="Filter by Case Officer"
        ></ng-select>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="selectedPropertyIDs.length > 0"
  class="row delete--banner mb-3 mt-3"
>
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <span class="delete--banner-text"
        >Selected properties {{ selectedPropertyIDs.length }}</span
      >
      <div>
        <button class="delete--btn btn-lg" (click)="uncheckAll()">
          <i style="color: #ffff" class="fa fa-times" aria-hidden="true"></i>
        </button>
        <button
          [disabled]="oncheckUserDelete()"
          class="delete--btn btn-lg"
          (click)="deleteproperties()"
        >
          <i style="color: #ffff" class="fa fa-trash-o" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive wh_table" *ngIf="propertiesListArray?.length > 0">
  <table class="table">
    <thead>
      <tr>
        <th style="cursor: pointer">Image</th>
        <th style="cursor: pointer" (click)="sortLisitng('title')">
          Title<i
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortvalue[1] == 'asc' && sortvalue[0] == 'title',
              'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'title'
            }"
          ></i>
        </th>
        <th style="cursor: pointer" (click)="sortLisitng('desc')">
          Description<i
            class="fa"
            [ngClass]="{
              'fa-arrow-down': sortvalue[1] == 'asc' && sortvalue[0] == 'desc',
              'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'desc'
            }"
          ></i>
        </th>
        <th style="cursor: pointer" (click)="sortLisitng('amount')">
          Amount<i
            class="fa"
            [ngClass]="{
              'fa-arrow-down':
                sortvalue[1] == 'asc' && sortvalue[0] == 'amount',
              'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'amount'
            }"
          ></i>
        </th>
        <th style="cursor: pointer" (click)="sortLisitng('property_address')">
          Property Address<i
            class="fa"
            [ngClass]="{
              'fa-arrow-down':
                sortvalue[1] == 'asc' && sortvalue[0] == 'property_address',
              'fa-arrow-up':
                sortvalue[1] == 'desc' && sortvalue[0] == 'property_address'
            }"
          ></i>
        </th>
        <th style="cursor: pointer" (click)="sortLisitng('roomtype')">
          Bedroom Size
          <i
            class="fa"
            [ngClass]="{
              'fa-arrow-down':
                sortvalue[1] == 'asc' && sortvalue[0] == 'roomtype',
              'fa-arrow-up':
                sortvalue[1] == 'desc' && sortvalue[0] == 'roomtype'
            }"
          ></i>
        </th>
        <th class="text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-template
        ngFor
        let-property
        [ngForOf]="
          propertiesListArray
            | LisitingFilter: searchValue
            | SortingFilter: sortvalue
            | paginate: { itemsPerPage: 100, currentPage: p }
        "
        let-i="index"
      >
        <tr [ngClass]="{ 'has-active-error': property.active == false }">
          <td>
            <div class="inline_box">
              <img
                class="img_logo"
                *ngIf="property.image && property.image != 'null'"
                [src]="checkImage(property)"
              />
              <img
                class="img_logo"
                *ngIf="!property.image || property.image === 'null'"
                src="assets/images/icon_gradiant.png"
              />
            </div>
          </td>
          <td>
            <div class="txt_overflow">
              <span
                class="user--name"
                (click)="viewPropertyDetail(property, i)"
                >{{ property.title }}</span
              >
            </div>
          </td>
          <td>
            <div class="txt_overflow">
              {{
                (property.desc == "null" ? "N/A" : property.desc)
                  | limitTo: "200"
              }}
            </div>
          </td>
          <td>
            <div class="txt_overflow">
              {{ property.amount == "null" ? "N/A" : property.amount }}
            </div>
          </td>
          <td>
            <div class="txt_overflow">
              {{
                property.property_address == "null"
                  ? "N/A"
                  : property.property_address
              }}
            </div>
          </td>
          <td class="text-center">
            {{ checkRoomType(property.roomtype) }}
          </td>

          <td class="text-center">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src="assets/images/three_dots.png" />
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                data-target="#filter_box_dropdown"
              >
                <a
                  class="dropdown-item"
                  (click)="viewPropertyDetail(property, i)"
                  ><i class="far fa-eye"></i>View Detail</a
                >
                <a class="dropdown-item" (click)="setPropertyDetail(i)"
                  ><i class="far fa-filter"></i>Filter Users</a
                >
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
<div class="table-responsive wh_table" *ngIf="propertiesListArray?.length == 0">
  <div
    style="height: '70vh'"
    class="d-flex justify-content-center align-items-center"
  >
    <h6>We are processing properties for this page</h6>
  </div>
</div>
<div
  *ngIf="propertiesListArray?.length > 0"
  class="pagination mt-4 mb-4 wh_pagination Pagiantion"
>
  <pagination-controls
    (click)="clickPagination()"
    (pageChange)="p = $event"
    directionLinks="true"
    previousLabel="Previous"
    nextLabel="Next"
  ></pagination-controls>
</div>

<!-----------------Select Property------------------>
<!-- <div
  class="modal fade modalMargin"
  id="exampleModal3"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content changeInModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select Property</h5>
        <div class="d-flex">
          <button
            type="button"
            class="close"
            id="close-property-modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="table-responsive wh_table">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th style="cursor: pointer">Image</th>
                <th style="cursor: pointer">Title</th>
                <th style="cursor: pointer">Provider</th>
                <th style="cursor: pointer">Description</th>
                <th style="cursor: pointer">Amount</th>
                <th style="cursor: pointer">Property Address</th>
                <th style="cursor: pointer">Bedroom Size</th>
              </tr>
            </thead>
            <tbody>
              <ng-template
                ngFor
                let-property
                [ngForOf]="
                  propertiesListArray
                    | SortingFilter: sortvalue
                    | paginate: { itemsPerPage: 100, currentPage: p_popup }
                "
                let-i="index"
              >
                <tr
                  [ngClass]="{ 'has-active-error': property.active == false }"
                >
                  <td>
                    <input
                      [(ngModel)]="property.selected"
                      type="checkbox"
                      (click)="selectObject($event, i, property)"
                    />
                  </td>
                  <td>
                    <div class="inline_box">
                      <img
                        class="img_logo"
                        *ngIf="property.image && property.image != 'null'"
                      />
                      <img
                        class="img_logo"
                        *ngIf="!property.image || property.image === 'null'"
                        src="assets/images/icon_gradiant.png"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      <span class="user--name">{{ property.title }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.provider }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      {{ property.desc | limitTo: "200" }}
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.amount }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      {{ property.property_address }}
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.roomtype }}</div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div
          *ngIf="propertiesListArray?.length > 0"
          class="pagination mt-4 mb-4 wh_pagination Pagiantion"
        >
          <pagination-controls
            (click)="clickPaginationPopUp()"
            (pageChange)="p_popup = $event"
            directionLinks="true"
            previousLabel="Previous"
            nextLabel="Next"
          ></pagination-controls>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="clearAllProperties()"
        >
          Clear
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="saveCheckedProperties()"
        >
          Add Selected Properties
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->
