import { Pipe, PipeTransform } from "@angular/core";
import { AlertNotification } from "../../core/alertnotification/alertNotification";
import * as moment from "moment";

@Pipe({
  name: "SortingFilter",
})
export class SortUserListPipe implements PipeTransform {
  constructor(private notifier: AlertNotification) {}

  transform(allUsers: any, term: any) {
    console.log(term);
    if (term.length > 1 && allUsers && allUsers.length) {
      var users = allUsers.sort(function (a, b) {
        const field = term[0];
        // else if(term[0] == 'reward' || term[0] == 'amount' || term[0] == 'transactionAmount' || term[0] == 'commission' || term[0] == 'sortNumber' || term[0] == 'user'){
        //     var field1 :any = a[field] || 0;
        //     var field2 :any = b[field] || 0;
        //     if(term[1] == 'asc'){
        //         return field1 > field2 ? 1 : -1
        //     }else{
        //         return field2 > field1 ? 1 : -1
        //     }
        // }
        if (term[0] == "created" || term[0] == "createdAt") {
          if (a.created && b.created) {
            var field1: any = moment(a.created).format();
            var field2: any = moment(b.created).format();
            if (term[1] == "asc") {
              return field1 > field2 ? 1 : -1;
            } else {
              return field2 > field1 ? 1 : -1;
            }
          } else {
            return;
          }
        } else if (term[0] == "notes") {
          if (a.notes && b.notes) {
            var field1: any = a.notes.length;
            var field2: any = b.notes.length;
            if (term[1] == "asc") {
              return field1 > field2 ? 1 : -1;
            } else {
              return field2 > field1 ? 1 : -1;
            }
          }
        } else if (term[0] == "documentCount") {
          return;
        } else if (term[0] == "sendTo") {
          console.log("fghjk");
          if (a && b) {
            if (a.type == "all") {
              var field1: any = "all";
            } else if (a.type !== "all" && !a.users.users) {
              var field1: any = "";
            } else if (a.type !== "all" && a.users && a.users.users) {
              if (a.users.users.length > 1) {
                var field1: any = "group";
              } else {
                var field1: any = a.users.users[0].email;
              }
            }

            if (b.type == "all") {
              var field2: any = "all";
            } else if (b.type !== "all" && !b.users.users) {
              var field2: any = "";
            } else if (b.type !== "all" && b.users && b.users.users) {
              if (b.users.users.length > 1) {
                var field2: any = "group";
              } else {
                var field2: any = b.users.users[0].email;
              }
            }
          }
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        } else {
          if (field == "roomtype") {
            var field1 = a[field];
            var field2 = b[field];
          } else if (field == "amount") {
            var tempfield1 = a[field] ? a[field].match(/[\d\.\,]+/)[0] : "0";
            var tempfield2: any = b[field]
              ? b[field].match(/[\d\.\,]+/)[0]
              : "0";

            if (tempfield1.includes(",")) {
              var field1: any = Number(tempfield1.replace(/,/g, ""));
            } else {
              var field1: any = Number(tempfield1);
            }
            if (tempfield2.includes(",")) {
              var field2: any = Number(tempfield2.replace(/,/g, ""));
            } else {
              var field2: any = Number(tempfield2);
            }
          } else {
            var field1 = a[field] ? a[field].toLowerCase() : "";
            var field2 = b[field] ? b[field].toLowerCase() : "";
            field1 = field1.replace(/\s/g, "");
            field2 = field2.replace(/\s/g, "");
          }
          if (term[1] == "asc") {
            return field1 > field2 ? 1 : -1;
          } else {
            return field2 > field1 ? 1 : -1;
          }
        }
      });
      return users;
    } else {
      return allUsers;
    }
  }

  filterValueSendTo(field) {
    if (field.type == "All") {
      return "All";
    } else if (field.type !== "All" && !field.users.users) {
      return "";
    } else if (field.type !== "All" && field.users && field.users.users) {
      if (field.users.users.length > 1) {
        return "Group";
      } else {
        return field.users.users[0].email;
      }
    }
  }
}
