import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';

@Component({
  selector: 'app-add-ha-staff',
  templateUrl: './add-ha-staff.component.html',
  styleUrls: ['./add-ha-staff.component.scss']
})
export class AddHAStaffComponent implements OnInit {

  formSubmitted = false;
  Add_HA_Staff_Form: FormGroup;
  providerName;
  staffData;
  
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder : FormBuilder, 
    private router : Router,
    private apiservice : ApiServiceService,
    private alertnotification : AlertNotification,
  ) { 
    this.Add_HA_Staff_Form = this.formBuilder.group({
      name : [null,[Validators.required]],
      email : [null,[Validators.required,Validators.email]],
      phone : [null,[Validators.required,Validators.pattern(/^[0-9]\d*$/)]],
      address : [null,[Validators.required]]
    })
  }

  ngOnInit(): void {
    if(this.staffData && this.staffData.id){
      this.Add_HA_Staff_Form.controls['name'].setValue(this.staffData.name);
      this.Add_HA_Staff_Form.controls['email'].setValue(this.staffData.email);
      this.Add_HA_Staff_Form.controls['phone'].setValue(this.staffData.phone);
      this.Add_HA_Staff_Form.controls['address'].setValue(this.staffData.address);
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.Add_HA_Staff_Form.invalid) {
      return;
    }else{
      this.Add_HA_Staff_Form.value['provider'] = this.providerName;
      if(this.staffData && this.staffData.id){
        this.apiservice.postRequest('updateStaff.php',this.Add_HA_Staff_Form.value).subscribe((response:any) => {
          this.alertnotification.successAlert(response.messeage);
          this.bsModalRef.content.onClose.next(true);

          this.bsModalRef.hide();
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
          this.bsModalRef.content.onClose.next(true);

          this.bsModalRef.hide();
        })
      }else{
        this.apiservice.postRequest('addStaff.php',this.Add_HA_Staff_Form.value).subscribe((response:any) => {
          this.alertnotification.successAlert(response.messeage);
          this.bsModalRef.hide();
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
          this.bsModalRef.hide();
        })
      }
      
    }
  }

}
