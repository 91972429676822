import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandlordRaComponent } from "./landlord-ra/landlord-ra.component";
import { LandlordComponent } from "./landlord.component";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { SettingsComponent } from "./settings/settings.component";
import { PropertiesComponent } from "./properties/properties.component";
import { AccountsComponent } from "./accounts/accounts.component";

const routes: Routes = [
  {
    path: "landlord",
    component: LandlordComponent,
    children: [
      { path: "user", component: LandlordRaComponent },
      { path: "userdetail", component: UserDetailComponent },
      { path: "properties", component: PropertiesComponent },
      { path: "settings", component: SettingsComponent },
      { path: "accounts", component: AccountsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandlordRoutingModule {}
