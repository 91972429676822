<div class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Change Account Navigation</h4>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
            <form class="forms-sample" [formGroup]="changeAccountNavigationForm" (submit)="onSubmit()">

                <div class="form-group row">
                    <div class="col-md-12">
                        <label class="col-form-label"> Navigations </label>
                        <div class="col-md-12 col-form-label">
                            <div class="row">
                                <div *ngFor="let nav of navigationArray; let i=index" class="col-md-2 custom-control custom-checkbox mb-2">
                                    <input class="custom-control-input" (change)="onCheckboxChange($event, i)" id="{{i}}" type="checkbox" [checked]="nav.checked">
                                    <label class="custom-control-label" for="{{i}}">{{nav.navigation}}</label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formSubmitted && navigationError" class="has-error">
                            <span> navigation is required </span>
                        </div>
                    </div>
                </div>
                <div class="float-right">
                    <button type="submit" class="btn btn-outline-success btn-fw mr-2">Save</button>
                    <button type="button" (click)="bsModalRef.hide()"
                        class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>