import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderTitleService } from "src/app/core/headerTitle/header-title.service";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent implements OnInit {
  createUserForm: FormGroup;
  formSubmitted = false;
  agentid;
  agentData;
  password;
  accountCreated = false;
  showForm = false;
  activeAgent;
  userType;
  accountsData = [];
  organization;
  navigationArray = [
    { navigation: "Users" },
    { navigation: "Properties" },
    { navigation: "Area of Interest" },
    { navigation: "Matched" },
    { navigation: "Referring Agents" },
    { navigation: "Shortlisted" },
    { navigation: "Notifications" },
    { navigation: "Authorizations" },
    { navigation: "Housing Association" },
    { navigation: "Messaging Templates" },
    { navigation: "Accounts" },
    { navigation: "Consents" },
    // { navigation: "test"}
  ];
  show: boolean = false;
  isAuthorized;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private headerTitleService: HeaderTitleService
  ) {
    this.agentid = location.pathname.split("/")[3];
  }

  async ngOnInit(): Promise<any> {
    this.headerTitleService.setTitle("Accounts");
    this.createUserForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      navigation: this.fb.array([]),
    });
    this.activeAgent = JSON.parse(localStorage.getItem("ActiveAgent"));
    this.userType = JSON.parse(localStorage.getItem("UserType"));
    await this.getaccountData();
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.createUserForm.value.email?.indexOf("+") !== -1) {
      this.alertnotification.errorAlert("invalid email address!");
      return;
    }
    if (this.createUserForm.invalid) {
      return;
    } else {
      console.log(this.accountsData);
      this.createUserForm.value["navigation"] = ["User"];
      this.createUserForm.value["type"] = "RA";
      let allEmail = [];
      this.accountsData.map((item) => {
        allEmail.push(item.email);
      });
      this.createUserForm.value.email = `${this.createUserForm.value.email}@${this.organization}`;
      allEmail.push(this.createUserForm.value.email);
      this.apiservice
        .postRequest_b2("createUser", this.createUserForm.value)
        .subscribe(
          async (response: any) => {
            console.log(response.data);
            this.accountsData.map;
            this.apiservice
              .postRequest("updateRefferingAgent.php", {
                id: this.activeAgent.id,
                email: JSON.stringify(allEmail),
              })
              .subscribe(
                (response: any) => {
                  this.createUserForm.reset();
                  console.log(response);
                  this.alertnotification.successAlert(response.message);
                  this.getaccountData();
                  this.showForm = false;
                  console.log(this.accountsData);
                },
                (error) => {
                  console.log(error);
                }
              );
          },
          (error) => {
            this.alertnotification.errorAlert(error.error.message);
          }
        );
    }
  }

  getaccountData() {
    this.apiservice
      .postRequest("getRefferingAgent.php", { agentID: this.activeAgent.id })
      .subscribe((response: any) => {
        if (response) {
          this.accountCreated =
            response.data[0].is_account_created == 1 ? true : false;
          if (this.accountCreated) {
            this.headerTitleService.setTitle(
              "Account details for " + response.data[0].name
            );
          } else {
            this.headerTitleService.setTitle(
              "Create account for " + response.data[0].name
            );
          }
          this.agentData = response.data[0];
          let data = {
            email: this.agentData.email,
            name: this.agentData.name,
          };
          let emails = JSON.parse(response.data[0].email);
          this.accountsData = [];
          this.organization = emails[0].split("@")[1];
          this.isAuthorized = emails[0] === this.userType.email;
          console.log("email", this.organization);
          emails.forEach((element) => {
            this.apiservice
              .postRequest_b2("getUserbyemail", { email: element })
              .subscribe(
                async (response: any) => {
                  console.log(response.data);
                  // this.password = response.data.encryptPassword;
                  this.accountsData.push({
                    name: response.data.name,
                    email: response.data.email,
                  });
                },
                (error) => {
                  this.alertnotification.errorAlert(error.error.message);
                }
              );
          });
        } else {
        }
      });
    console.log(this.accountsData);
  }
  handleForm() {
    this.showForm = true;
  }
  cancel() {
    this.showForm = false;
    this.createUserForm.reset();
    console.log(this.createUserForm.controls);
  }
  passwordHandler() {
    this.show = !this.show;
  }
}
