<div class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Add Navigations</h4>
                    <form class="form-sample" [formGroup]="navigationForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-10">
                                <div class="form-row">
                                    <div formArrayName="navigation" *ngFor="let id of navigationForm.get('navigation')['controls']; let i = index;" class="col-sm-8 mb-2">
                                        <div [formGroupName]="i">
                                            <input type="text" *ngIf="queryData[i].type == 'old'" formControlName="navigation" class="form-control fiedlSelect mr-2" placeholder="Navigation" readonly>
                                            <input type="text" *ngIf="queryData[i].type == 'new'" formControlName="navigation" class="form-control fiedlSelect mr-2" placeholder="Navigation">
                                        </div>
                                        <div *ngIf="(formSubmitted || (id.dirty && id.controls.navigation.touched)) && id.controls.navigation.errors" class="has-error">
                                            <span *ngIf="id.controls.navigation.errors.required">value is required </span>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="d-flex align-items-center add--btns">
                                            <button type="button" (click)="addUserField()" class="mr-2 btn btn-outline-success btn-fw"><i class="fa fa-plus"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button type="button" class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()" type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>