import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandlordComponent } from './landlord.component';
import { LandlordRoutingModule } from './landlord-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandlordRaComponent } from './landlord-ra/landlord-ra.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { SettingsComponent } from './settings/settings.component';
import { PropertiesComponent } from './properties/properties.component';
import { AccountsComponent } from './accounts/accounts.component';



@NgModule({
  declarations: [
    LandlordComponent,
    LandlordRaComponent,
    UserDetailComponent,
    SettingsComponent,
    PropertiesComponent,
    AccountsComponent,
  ],
  imports: [
    LandlordRoutingModule,
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    AccordionModule.forRoot(),
  ]
})
export class LandlordModule { }
