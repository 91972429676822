<div class="col-12 grid-margin stretch-card">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="card-title">Add Status</h4>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
            <form class="forms-sample" [formGroup]="AddStatusForm" (submit)="onSubmit()">
                <div class="form-group row">
                    <label for="exampleInputEmail2" class="col-sm-2 col-form-label">Status</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" formControlName="statusName" name="statusName" placeholder="Status Name">
                        <div *ngIf="formSubmitted && AddStatusForm.controls.statusName.errors" class="has-error">
                            <span *ngIf="AddStatusForm.controls.statusName.errors.required">Status Name is required </span>
                        </div>
                    </div>
                </div>
               
                <div class="float-right">
                    <button type="submit" class="btn btn-outline-success btn-fw mr-2">Add</button>
                    <button type="button" (click)="bsModalRef.hide()" class="btn btn-outline-danger btn-fw">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>