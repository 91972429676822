import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class CanActivateLogin {

  constructor( private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(localStorage.getItem('User') && localStorage.getItem('UserType')){
      console.log(localStorage.getItem('User'))
      this.router.navigate(['/landlord/user']);
      return false;
    }else{
      return true;
    }
  }
}

// export class CanActivateAutoLogin {

//   constructor( private router: Router ) { }

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     localStorage.clear();
//     // if(localStorage.getItem('User') && localStorage.getItem('UserType')){
//     //   console.log(localStorage.getItem('User'))
//     //   this.router.navigate(['/landlord/user']);
//     //   return false;
//     // }else{
//       return true;
//     // }
//   }
// }
