import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-navigation-form',
  templateUrl: './navigation-form.component.html',
  styleUrls: ['./navigation-form.component.scss']
})
export class NavigationFormComponent implements OnInit {
  
  navigationForm: FormGroup;
  queryData: any;
  demoAray = ["1","2","3"];
  formSubmitted = false;
  navigationId: any = null;
  addedNavigators: any = [];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder : FormBuilder, 
    private router : Router,
    private apiservice : ApiServiceService,
    private alertnotification : AlertNotification,
    private loaderService: NgxSpinnerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.navigationForm = this.formBuilder.group({
      navigation : this.formBuilder.array([])
    });
    this.apiservice.getRequest_b2(`/getnavigation`).subscribe(async (response : any )=>{
      console.log(response.data);
      this.addedNavigators = response.data.length > 0 ? response.data[0].navigation : [];
      // this.queryData = response.data.length > 0 ? response.data[0].navigation : [];
      var items = this.navigationForm.get('navigation') as FormArray;
      this.navigationId = response.data.length > 0 ? response.data[0]._id : null;
      response.data.length > 0 ? response.data[0].navigation.map((x) => {
        items.push(this.createItem(x.navigation,'old'));
      }) : items.push(this.createItem('','new'));
      this.queryData = this.navigationForm.get('navigation').value;
    }, 
    error =>{
      this.alertnotification.errorAlert(error.error.message);
    });
  }

  createItem(value,type): FormGroup {
    return this.formBuilder.group({
      navigation : [value,[Validators.required]],
      type : [type]
    });
  }

  addUserField() {
    var items = this.navigationForm.get('navigation') as FormArray;
    items.push(this.createItem('','new'));
    this.queryData = this.navigationForm.get('navigation').value;
  }

  onSubmit() {
    this.formSubmitted = true;

    if(this.navigationForm.invalid) {
      return;
    }else{
      this.navigationForm.value['navigation'] = this.navigationForm.value.navigation.filter((x) => {
        if(this.addedNavigators.findIndex(y => y.navigation == x.navigation) < 0) {
          return x;
        }
      }) 
      this.navigationForm.value['id'] = this.navigationId ? this.navigationId : null;
      this.apiservice.postRequest_b2('addnavigation',this.navigationForm.value).subscribe(async (response : any )=>{
        // console.log(response.data);
        this.alertnotification.successAlert(response.message);
        this.bsModalRef.hide();
      }, 
      error =>{
        this.alertnotification.errorAlert(error.error.message);
      });
    }
  }

}
