import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-account-navigation',
  templateUrl: './change-account-navigation.component.html',
  styleUrls: ['./change-account-navigation.component.scss']
})
export class ChangeAccountNavigationComponent implements OnInit {
  formSubmitted = false;
  changeAccountNavigationForm: FormGroup;
  userObject
  navigationError = false;
  navigationArray = [
    {navigation: "Users", checked: false },
    {navigation: "Properties", checked: false},
    {navigation: "Area of Interest", checked: false},
    {navigation: "Matched", checked: false},
    {navigation: "Referring Agents", checked: false},
    {navigation: "Shortlisted", checked: false},
    {navigation: "Notifications", checked: false},
    {navigation: "Authorizations", checked: false},
    {navigation: "Housing Association", checked: false},
    {navigation: "Messaging Templates", checked: false},
    {navigation: "Accounts", checked: false}
    // { navigation: "test"}
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {
    this.changeAccountNavigationForm = this.formBuilder.group({
      navigation: this.formBuilder.array([])
    })
  }

  ngOnInit(): void {
    for (let index = 0; index < this.navigationArray.length; index++) {
      const element = this.navigationArray[index];
      if(this.userObject.navigation.includes(element.navigation)){
        element.checked = true;
      }
    }
  }


  onSubmit() {
    let data = [];
    for (let index = 0; index < this.navigationArray.length; index++) {
      const element = this.navigationArray[index];
      if(element.checked){
        data.push(element.navigation);
      }
    }
    this.formSubmitted = true;
    
    this.apiservice.postRequest_b2(`/updateUserNavigation`, { email: this.userObject.email, navigation: data }).subscribe(async (response: any) => {
      // this.authUserListArray = response.data;
      this.loaderService.hide();
      this.bsModalRef.hide();
      this.alertnotification.successAlert("Updated Successfully");

    },
      error => {
        this.alertnotification.errorAlert(error.error.message);
      });
  }
  onCheckboxChange(e, index) {
    this.navigationError = false;
    this.navigationArray[index].checked = e.target.checked;
  }

}
