import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateAdmin } from './core/guards/loginguard';
import { CanActivateLogin } from './core/guards/authguard';
// import { LandlordRaComponent } from './landlord-ra/landlord-ra.component';


const routes: Routes = [
  {
    path: "msh",
    loadChildren: () => import("./public-link/public-link.module").then(mod => mod.PublicLinkModule)
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then(mod => mod.AuthModule)
  },
  {
    path: "landlord",
    loadChildren: () => import("./landlord/landlord.module").then(mod => mod.LandlordModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
