import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landlord',
  templateUrl: './landlord.component.html',
  styleUrls: ['./landlord.component.scss']
})
export class LandlordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
