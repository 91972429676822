import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { HeaderTitleService } from "src/app/core/headerTitle/header-title.service";
import * as bcrypt from "bcryptjs";
import Swal from "sweetalert2";
import { CsvFileService } from "src/app/core/common-service/exportCSV";
import { FormBuilder, Validators } from "@angular/forms";
import { AddUserNoteComponent } from "src/app/shared/add-user-note/add-user-note.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UserSharedDataServices } from "src/app/core/api-service/user-shared-service";

@Component({
  selector: "app-landlord-ra",
  templateUrl: "./landlord-ra.component.html",
  styleUrls: ["./landlord-ra.component.scss"],
})
export class LandlordRaComponent implements OnInit {
  modalRef: BsModalRef;

  // modal variables
  emailTemplateTitle = "";
  usersListArray = [];
  selectedPropertiesIndexes = [];
  allcontentData;
  sendMailData;
  recipient;
  propertiesListArray = [];
  tempSelectedPropertiesIndexes;
  selectedPropertyIDs;
  checkboxes;
  textcontentData;
  universalSearchValue;
  userListingArrayCopy: any[] = [];
  // user lisiting  variable

  ActiveUser;
  userListingArray = [];
  referringAgent;
  refreshUser;
  searchValue;
  sortvalue = ["createdAt", "asc"];
  userStatusListingArray: any[] = [];
  sort: string = "asc";
  p: number = 1;
  p_popup: number = 1;
  exportdata;
  selectedPropertydata;
  fileToUpload: File | null = null;
  includeOther;
  failedEmails = [];
  @ViewChild("fileUploader") fileUploader: ElementRef;

  constructor(
    private headerTitleService: HeaderTitleService,
    private apiservice: ApiServiceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private loaderService: NgxSpinnerService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private csvfiledownloader: CsvFileService,
    private modalService: BsModalService,
    private userServices: UserSharedDataServices
  ) {
    this.sendMailData = this.formBuilder.group({
      emails: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      content: ["", [Validators.required]],
      textcontent: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.headerTitleService.setTitle("Users ");
    this.ActiveUser = JSON.parse(localStorage.getItem("UserType"));
    console.log(this.ActiveUser);
    var data = {
      email: this.ActiveUser.email,
    };
    this.apiservice.postRequest("getRefferingAgent.php", data).subscribe(
      (result: any) => {
        console.log(result.data);
        this.referringAgent = result.data[0];
        console.log("this.referringAgent", this.referringAgent);
        this.getRAUser(false);
      },
      (error) => {}
    );
  }
  clickPagination() {
    window.scroll(0, 0);
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [value, "desc"])
        : (this.sortvalue = [value, "asc"]);
    }
    this.p = 1;
  }

  universalSearch() {
    if (this.userListingArrayCopy.length == 0) {
      this.userListingArray = this.userListingArray.filter((x) => {
        if (!x.deleted || x.deleted == false) {
          return x;
        }
      });

      this.userListingArray = this.userListingArray.filter((x) => {
        if (!x.archived || x.archived == false) {
          return x;
        }
      });

      this.userListingArrayCopy = this.userListingArray.slice(0);
    } else {
      this.userListingArrayCopy = this.userListingArrayCopy.filter((x) => {
        if (!x.deleted || x.deleted == false) {
          return x;
        }
      });

      this.userListingArrayCopy = this.userListingArrayCopy.filter((x) => {
        if (!x.archived || x.archived == false) {
          return x;
        }
      });

      this.userListingArray = this.userListingArrayCopy.slice(0);
    }

    if (this.universalSearchValue.length > 0) {
      this.userListingArray = this.userListingArray.filter((obj) => {
        return Object.keys(obj).some((key) => {
          if (typeof obj[key] == "string") {
            return obj[key]
              .toLowerCase()
              .includes(this.universalSearchValue.toLowerCase());
          }
        });
      });
    }
    this.p = 1;
  }

  async getRAUser(refresh, shouldReload = true) {
    this.p = 1;
    var records1 = [];
    this.loaderService.show();
    this.userServices
      .getUserListServices(
        this.referringAgent.name,
        (this.refreshUser = refresh)
      )
      .then(
        async function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records1.push(data);
          });

          this.userListingArray = records1.filter((x) => {
            if (!x.deleted || x.deleted == false) {
              return x;
            }
          });

          this.userListingArray = this.userListingArray.filter((x) => {
            if (!x.archived || x.archived == false) {
              return x;
            }
          });

          this.userListingArray = _.orderBy(
            this.userListingArray,
            ["createdAt.seconds"],
            ["desc"]
          );
          this.searchData = this.userListingArray;
          console.log("userListingArray");
          console.log(this.userListingArray);
          for (const user of this.userListingArray) {
            user["userName"] = user.firstName + " " + user.lastName;
            user["arrImgBankStatementsObj"] = [];
            user["phone"] = user.phoneNumber1
              ? user.phoneNumber1
              : user.phoneNumber2;
            if (
              user.arrImgBankStatements &&
              user.arrImgBankStatements.length > 0
            ) {
              for (const file of user.arrImgBankStatements) {
                let newFile = file && file.url ? file.url : file;
                if (newFile.search(".com:443/") == -1) {
                  let name = await this.firebaseservice.getFileNameAndType(
                    newFile
                  );
                  var re = /(?:\.([^.]+))?$/;
                  let type = re.exec(name)[1];
                  if (typeof type != "undefined") {
                    user["arrImgBankStatementsObj"].push({
                      url: file,
                      name,
                      type:
                        type.startsWith("png") ||
                        type.startsWith("jpeg") ||
                        type.startsWith("jpg")
                          ? type.substring(0, type.indexOf("-"))
                          : type,
                    });
                  }
                } else {
                  console.log("user=>", user);
                }
              }
            }

            user["arrImgIdProofObj"] = [];
            if (user.arrImgIdProof && user.arrImgIdProof.length > 0) {
              for (const file of user.arrImgIdProof) {
                let newFile = file && file.url ? file.url : file;
                if (newFile.search(".com:443/") == -1) {
                  let name = await this.firebaseservice.getFileNameAndType(
                    newFile
                  );
                  var re = /(?:\.([^.]+))?$/;
                  let type = re.exec(name)[1];
                  if (typeof type != "undefined") {
                    user["arrImgIdProofObj"].push({
                      url: file,
                      name,
                      type:
                        type.startsWith("png") ||
                        type.startsWith("jpeg") ||
                        type.startsWith("jpg")
                          ? type.substring(0, type.indexOf("-"))
                          : type,
                    });
                  }
                } else {
                  console.log("user =>", user);
                }
              }
            }
            user["arrImgIncomeProofObj"] = [];
            if (user.arrImgIncomeProof && user.arrImgIncomeProof.length > 0) {
              for (const file of user.arrImgIncomeProof) {
                let newFile = file && file.url ? file.url : file;
                if (newFile.search(".com:443/") == -1) {
                  let name = await this.firebaseservice.getFileNameAndType(
                    newFile
                  );
                  var re = /(?:\.([^.]+))?$/;
                  let type = re.exec(name)[1];
                  if (typeof type != "undefined") {
                    user["arrImgIncomeProofObj"].push({
                      url: file,
                      name,
                      type:
                        type.startsWith("png") ||
                        type.startsWith("jpeg") ||
                        type.startsWith("jpg")
                          ? type.substring(0, type.indexOf("-"))
                          : type,
                    });
                  }
                } else {
                  console.log("user =>", user);
                }
              }
            }
            if (user.proofOfIdFiles && user.proofOfIncomeFiles) {
              await this.calculateUserProfileStatusCSV(
                user.proofOfIdFiles,
                user.proofOfIncomeFiles,
                user
              );
            } else {
              await this.calculateUserProfileStatus(
                user.arrImgIdProof,
                user.arrImgIncomeProof,
                user.arrImgBankStatements,
                user
              );
            }

            let d = new Date(1970, 0, 1); // Epoch
            d.setSeconds(user.createdAt.seconds);
            let year = new Intl.DateTimeFormat("en", {
              year: "2-digit",
            }).format(d);
            let month = new Intl.DateTimeFormat("en", {
              month: "short",
            }).format(d);
            let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
              d
            );
            user["createDate"] = day + " " + month + " " + year;
          }
          // if(this.filters.bedFilter){
          //   await this.onChangebedFilter(this.filterValues.bedFilter, shouldReload)
          // }
          // if(this.filters.ageFilter){
          //   await this.onChangeagerFilter2(this.filterValues.ageFilter.range,this.filterValues.ageFilter.values, shouldReload)
          // }
          // if(this.filters.statusFilter){
          //   await this.onChangestatusFilter(this.filterValues.statusFilter, shouldReload)
          // }
          // if(this.filters.dateFilter){
          //   await this.onValueChange(this.filterValues.dateFilter.ev,this.filterValues.dateFilter.offset, shouldReload)
          // }
          // if(this.filters.areaOfIntrestFilter){
          //   await this.onChangeAreaOfInterest(this.filterValues.areaOfIntrestFilter, shouldReload)
          // }
          // if(this.filters.caseOfficerFilter){
          //   await this.onChangeAssignedUsers(this.filterValues.caseOfficerFilter, shouldReload)
          // }
          // if(this.filters.referringAgentExsistFilter){
          //   await this.filterByReferignAgent(this.filterValues.referringAgentExsistFilter, shouldReload)
          // }
          // if(this.filters.form123BuilderFilter){
          //   await this.onChangeRentArrears(this.filterValues.form123BuilderFilter, shouldReload)
          // }

          this.exportdata = this.userListingArray;
          this.loaderService.hide();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }

  async calculateUserProfileStatus(
    arrImgIdProof,
    arrImgIncomeProof,
    arrImgBankStatements,
    user
  ) {
    user["documentCount"] = 0;
    let sum = 0;
    sum += arrImgIdProof ? Number(arrImgIdProof.length > 0) : 0;
    sum += arrImgIncomeProof ? Number(arrImgIncomeProof.length > 0) : 0;
    sum += arrImgBankStatements ? Number(arrImgBankStatements.length > 0) : 0;
    user["documentCount"] = sum;
  }

  async calculateUserProfileStatusCSV(arrImgIdProof, arrImgIncomeProof, user) {
    user["documentCount"] = 0;
    let sum = 1;
    sum += arrImgIdProof ? Number(arrImgIdProof.length > 0) : 0;
    sum += arrImgIncomeProof ? Number(arrImgIncomeProof.length > 0) : 0;
    user["documentCount"] = sum;
  }
  clickOnUserEmail(email) {
    this.firebaseservice
      .getUserByEmail(email)
      .then((result) => {
        var records = [];

        result.forEach(function (object) {
          var data = object.data();
          data["id"] = object.id;
          records.push(data);
        });
        if (records.length > 0) {
          this.loaderService.hide();
          localStorage.setItem("userDetail", JSON.stringify(records[0]));
          // here ==========>>>>>> add a log for staff members 0n viewing user profile
          let loggedInUserData = JSON.parse(localStorage.getItem("UserType"));
          let logData = {
            staffName: loggedInUserData.name,
            staffEmail: loggedInUserData.email,
            actionPerformed:
              "Viewed " +
              records[0].firstName +
              " " +
              records[0].lastName +
              " profile",
            dateTime: new Date(),
            userId: records[0].id,
          };
          this.firebaseservice.uploadStaffActivity(logData);
          this.router.navigate([]).then((result) => {
            window.open("/landlord/userdetail", "_blank");
          });
        } else {
          this.loaderService.hide();
          this.alertnotification.errorAlert("No User Found");
        }
      })
      .catch(function (error) {
        this.loaderService.hide();
        console.log(error);
        this.alertNotification.errorAlert(error.message);
      });
  }

  sortDotsListingArray(documentCount) {
    console.log(this.userListingArray);
    if (!this.sortvalue.includes(documentCount)) {
      this.sortvalue = [documentCount, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [documentCount, "desc"])
        : (this.sortvalue = [documentCount, "asc"]);
    }

    let tempuserListingArray = [],
      tempuserListingArray2 = [],
      tempuserListingArray3 = [],
      tempuserListingArray4 = [];

    this.userListingArray.forEach((value) => {
      if (value.documentCount == 3) {
        tempuserListingArray.push(value);
      } else if (value.documentCount == 2) {
        tempuserListingArray2.push(value);
      } else if (value.documentCount == 1) {
        tempuserListingArray3.push(value);
      } else {
        tempuserListingArray4.push(value);
      }
    });
    if (this.sortvalue[1] == "desc") {
      this.userListingArray = tempuserListingArray4.concat(
        tempuserListingArray3,
        tempuserListingArray2,
        tempuserListingArray
      );
    } else {
      this.userListingArray = tempuserListingArray.concat(
        tempuserListingArray2,
        tempuserListingArray3,
        tempuserListingArray4
      );
    }
  }
  sortRefferingListingArray(documentCount) {
    console.log(documentCount);
    if (!this.sortvalue.includes(documentCount)) {
      this.sortvalue = [documentCount, this.sort];
    } else {
      this.sortvalue[1] == this.sort
        ? (this.sortvalue = [documentCount, "desc"])
        : (this.sortvalue = [documentCount, "asc"]);
    }

    let tempuserListingArray = [],
      tempuserListingArray2 = [];

    this.userListingArray.forEach((value) => {
      if (value.referringAgentNotes) {
        tempuserListingArray.push(value);
      } else {
        tempuserListingArray2.push(value);
      }
    });
    if (this.sortvalue[1] == "desc") {
      this.userListingArray =
        tempuserListingArray2.concat(tempuserListingArray);
    } else {
      this.userListingArray = tempuserListingArray.concat(
        tempuserListingArray2
      );
    }
    console.log(this.userListingArray);
  }

  viewUserDetail(object, i) {
    localStorage.setItem("userDetail", JSON.stringify(object));
    // here ==========>>>>>> add a log for staff members 0n viewing user profile
    let loggedInUserData = JSON.parse(localStorage.getItem("UserType"));
    let logData = {
      staffName: loggedInUserData.name,
      staffEmail: loggedInUserData.email,
      actionPerformed:
        "Viewed " + object.firstName + " " + object.lastName + " profile",
      dateTime: new Date(),
      userId: object.id,
    };
    this.firebaseservice.uploadStaffActivity(logData);
    this.router.navigate([]).then((result) => {
      window.open("/landlord/userdetail", "_blank");
    });
  }

  generatePassword(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  copyUserLink(object) {
    if (object && object.email) {
      var password = this.generatePassword(10);
      var data = {
        object: JSON.stringify(object),
        email: object.email,
        id: object.id,
        password: password,
      };
      this.apiservice.postRequest_b2("addSharedUser", data).subscribe(
        (response) => {
          // var hashPwd = this.simpleCrypto.encrypt(password).toString();
          const salt = bcrypt.genSaltSync(10);
          var hashPwd = bcrypt.hashSync(password, salt);
          hashPwd = hashPwd.split("/").join(" ");
          console.log(hashPwd);
          try {
            var url = `https://public.mysocialhousing.co.uk/msh/userdetail/${hashPwd}`;
            let textarea;
            let result;
            console.log(url);
            try {
              textarea = document.createElement("textarea");
              textarea.setAttribute("readonly", true);
              textarea.setAttribute("contenteditable", true);
              textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
              textarea.value = url;

              document.body.appendChild(textarea);

              textarea.focus();
              textarea.select();

              const range = document.createRange();
              range.selectNodeContents(textarea);

              const sel = window.getSelection();
              sel.removeAllRanges();
              sel.addRange(range);

              textarea.setSelectionRange(0, textarea.value.length);
              result = document.execCommand("copy");
            } catch (err) {
              console.error(err);
              result = null;
            } finally {
              document.body.removeChild(textarea);
            }

            // manual copy fallback using prompt
            if (!result) {
              const isMac =
                navigator.platform.toUpperCase().indexOf("MAC") >= 0;
              const copyHotkey = isMac ? "⌘C" : "CTRL+C";
              result = prompt(`Press ${copyHotkey}`, url); // eslint-disable-line no-alert
              if (!result) {
                return this.alertnotification.errorAlert("error");
              }
            }
            Swal.fire({
              position: "center",
              icon: "success",
              title: "User Profile link copied to clipboard",
              showConfirmButton: false,
              timer: 3000,
            });
          } catch (error) {
            this.alertnotification.errorAlert(error);
          }
        },
        (error) => {
          this.alertnotification.errorAlert(error.message);
        }
      );
    } else {
      this.alertnotification.successAlert("No any user email found");
    }
  }

  oncheckUserView() {
    if (this.ActiveUser.type != "RA") {
      return true;
    } else {
      return false;
    }
  }

  getStatusList() {
    var records1 = [];
    this.firebaseservice
      .getStatus()
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records1.push(data);
          });
          this.userStatusListingArray = records1;
          this.statusFilterArray = records1;
          this.userStatusListingArray = records1.filter((x) => {
            if (!x.deleted || x.deleted == false) {
              return x;
            }
          });
          this.statusFilterArray = this.userStatusListingArray;
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }

  oncheckUserSend() {
    if (this.ActiveUser.type != "RA") {
      return true;
    } else {
      return false;
    }
  }

  async exportUserList() {
    let checkPswrd = JSON.parse(localStorage.getItem("countersign"));
    const result = await Swal.fire({
      title: "Enter account password to continue",
      input: "password",
      inputPlaceholder: "enter password",
    });
    if (result.value !== checkPswrd) {
      this.alertnotification.errorAlert("Invalid password entered!");
      return;
    }
    var headerList = [
      "firstName",
      "lastName",
      "email",
      "address",
      "status",
      "city",
      "asbDetails",
      "bedroomSize",
      "consent",
      "createDate",
      "currentAccommodation",
      "documentCount",
      "email1",
      "email2",
      "firstNameFC",
      "isMedicalDetailsProvided",
      "isProfileSaved",
      "isRentArrearsProvided",
      "landlordEmail",
      "landlordName",
      "landlordPhoneNumber",
      "lastNameFC",
      "latitude",
      "longitude",
      "medicalDetails",
      "phoneNumber1",
      "postCode",
      "referringAgent",
      "rentArrearsAmount",
      "userName",
    ];
    this.csvfiledownloader.downloadFile(
      this.exportdata,
      headerList,
      "userlist"
    );
  }

  openModal() {
    this.emailTemplateTitle = "";
    this.usersListArray = [];
    this.selectedPropertiesIndexes = [];
  }
  copyUserEmail(email) {
    if (this.usersListArray.includes(email)) {
      this.usersListArray = this.usersListArray.filter(
        (item) => item !== email
      );
      console.log("Email Remove From Array", this.usersListArray);
    } else {
      this.usersListArray.push(email);
      console.log("Email add into Array", this.usersListArray);
    }
    let string = this.usersListArray.toString();
    console.log("string", string);
    this.recipient = [];
    this.recipient.push(string);
  }

  getPropertiesData() {
    console.log(this.selectedPropertiesIndexes);
    if (this.propertiesListArray.length < 1) {
      this.apiservice
        .getRequest(`/api.php?all=true&allproperty=true`)
        .subscribe(
          (response: any) => {
            Object.keys(response).map((x) => {
              if (x == "totalpage") {
              } else if (x == "rowlimit") {
              } else if (x == "pagenum") {
              } else if (x == "totalcount") {
              } else if (x == "totalreturn") {
              } else {
                this.propertiesListArray.push(response[x]);
              }
            });
            console.log(this.propertiesListArray.length);
            console.log(this.propertiesListArray);
            for (
              let index = 0;
              index < this.propertiesListArray.length;
              index++
            ) {
              const element = this.propertiesListArray[index];
              element.selected = false;
            }
          },
          (error) => {
            this.alertnotification.errorAlert(error.error.message);
          }
        );
    }
    this.tempSelectedPropertiesIndexes = [];
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.tempSelectedPropertiesIndexes.push(element);
      this.propertiesListArray[element].selected = true;
    }
    console.log(
      this.tempSelectedPropertiesIndexes,
      " Temp ",
      this.selectedPropertiesIndexes,
      "Indexes"
    );
  }

  selectObject(evt, i, id, property) {
    evt.target.checked
      ? this.tempSelectedPropertiesIndexes.indexOf(i) == -1
        ? this.tempSelectedPropertiesIndexes.push(i)
        : ""
      : this.tempSelectedPropertiesIndexes.splice(
          this.tempSelectedPropertiesIndexes.indexOf(i),
          1
        );
  }

  clearAllProperties() {
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.propertiesListArray[element].selected = false;
    }
    this.tempSelectedPropertiesIndexes = [];
    this.selectedPropertiesIndexes = [];
    this.selectedPropertydata = [];
    let d = document.getElementById("close-property-modal");
    d.click();
  }

  saveCheckedProperties() {
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.propertiesListArray[element].selected = false;
    }
    this.selectedPropertiesIndexes = [];
    for (
      let index = 0;
      index < this.tempSelectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.tempSelectedPropertiesIndexes[index];
      this.selectedPropertiesIndexes.push(element);
    }
    for (
      let index = 0;
      index < this.selectedPropertiesIndexes.length;
      index++
    ) {
      const element = this.selectedPropertiesIndexes[index];
      this.propertiesListArray[element].selected = true;
    }
    this.onPropertySelect();
    let d = document.getElementById("close-property-modal");
    d.click();
  }
  clickPaginationPopUp() {
    window.scroll(0, 0);
    this.selectedPropertyIDs = [];
    this.selectedPropertiesIndexes = [];
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  onPropertySelect() {
    this.selectedPropertydata = "";
    if (
      this.selectedPropertiesIndexes &&
      this.selectedPropertiesIndexes.length > 0
    ) {
      this.selectedPropertiesIndexes.forEach((element) => {
        console.log(element);
        let property = this.propertiesListArray[element];
        this.PropertyPublicLink(property);
        this.selectedPropertydata +=
          `${property.title} : ${property.property_address}` +
          "\n" +
          ` https://public.mysocialhousing.co.uk/msh/propertydetail/${property.id}` +
          "\n\n";
      });
      +"\n\n";
      this.allcontentData = this.selectedPropertydata
        ? this.selectedPropertydata + "\n\n"
        : "";
    } else {
      this.allcontentData = this.selectedPropertydata
        ? this.selectedPropertydata + "\n\n"
        : "";
    }
  }

  PropertyPublicLink(object) {
    console.log(object);
    let data = {
      propertyId: object.id,
      details: JSON.stringify(object),
    };
    this.apiservice.postRequest_b2("addSharedProperty", data).subscribe(
      (response) => {
        console.log("public link", response);
      },
      (error) => {
        this.alertnotification.errorAlert(error.message);
      }
    );
  }

  sendmail() {
    let currentUserAllData = JSON.parse(localStorage.getItem("User"));
    const text = this.sendMailData.value.textcontent;
    const url = this.sendMailData.value.content.replace(/\n/g, "<br />");
    const body = text + "<br /> Property Links <br />" + url;
    console.log({ text }, { body });
    this.usersListArray;
    this.apiservice
      .postRequest("sendGridMailRA.php", {
        userEmail: currentUserAllData.user.email,
        email: this.usersListArray,
        subject: this.sendMailData.value.subject,
        content: body,
      })
      .subscribe(
        (response: any) => {
          if (response) {
            let d = document.getElementById("close-modal-mail");
            d.click();
            this.clearAllProperties();
            console.log("response", response);
            this.allcontentData = null;
            this.sendMailData.reset();
          }
          this.alertnotification.successAlert(
            "Email Sent to all selected users"
          );
        },
        (error) => {
          this.emailTemplateTitle = "";
          this.alertnotification.errorAlert(error.message);
        }
      );
  }

  async addNote(object) {
    var initialState = { userObject: object };
    this.modalRef = await this.modalService.show(AddUserNoteComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static",
    });
  }

  async uploadCsvFile(files: FileList) {
    let checkPswrd = JSON.parse(localStorage.getItem("countersign"));
    const result = await Swal.fire({
      title: "Enter account password to continue",
      input: "password",
      inputPlaceholder: "enter password",
    });
    if (result.value !== checkPswrd) {
      this.alertnotification.errorAlert("Invalid password entered!");
      this.fileToUpload = null;
      this.fileUploader.nativeElement.value = null;
      return;
    }
    if (!files.item(0).name.endsWith(".csv")) {
      this.alertnotification.errorAlert("Incorrect file format");
      this.fileToUpload = null;
      this.fileUploader.nativeElement.value = null;
      return;
    }

    this.fileToUpload = files.item(0);
    Swal.fire({
      title: `Do you want to upload users from CSV file?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value == true) {
        let reader: FileReader = new FileReader();
        reader.readAsText(this.fileToUpload);
        reader.onload = async () => {
          let csv: any = reader.result;
          let allTextLines = [];

          allTextLines = csv.split(/\r?\n/);

          let headerIndex = -1;
          for (let i = 0; i < allTextLines.length; i++) {
            let tlc = _.lowerCase(allTextLines[i]);

            if (
              tlc.includes("email") &&
              tlc.includes("first name") &&
              tlc.includes("last name")
            ) {
              headerIndex = i;
              break;
            }
          }
          if (headerIndex == -1) {
            this.alertnotification.errorAlert("Invalid CSV file");
            return;
          }

          let headers = allTextLines[headerIndex].split(",");

          let countFail = 0;
          this.loaderService.show();
          for (let i = headerIndex + 1; i < allTextLines.length; i++) {
            let values = allTextLines[i].split(",");
            let newObject = {};
            for (let i = 1; i < headers.length; i++) {
              if (!values[i]) continue;

              if (headers[i] == "areaOfInterest") {
                newObject = {
                  ...newObject,
                  [_.camelCase(headers[i])]: values[i].split("|"),
                };
                continue;
              }

              newObject = {
                ...newObject,
                [_.camelCase(headers[i])]: values[i],
                createdAt: new Date(),
                public: true,
              };
            }
            newObject = {
              ...newObject,
              referringAgent: this.referringAgent?.name,
            };
            if (newObject["email"]) {
              console.log("newObject", newObject);
              const res = await this.firebaseservice.userUpload(newObject);
              countFail += Number(res.isFailed);
              if (res.isFailed) {
                this.failedEmails.push(res.failedEmail);
              }
            }
          }

          let li = "";
          if (countFail > 0) {
            this.failedEmails.map((item) => {
              li += `<li>${item}</li>`;
            });
            console.log(`<ul>${li}</ul>`);
            this.alertnotification.failedEmail(
              `${countFail} emails already exists`,
              `<ul>${li}</ul>`
            );

            li = "";
            this.failedEmails = [];
            await this.getRAUser(false);
            this.loaderService.hide();
          } else {
            this.alertnotification.successAlert(
              `CSV users upload successfully`
            );
            this.loaderService.hide();
          }
          this.loaderService.hide();
        };
      }
      this.fileToUpload = null;
      this.fileUploader.nativeElement.value = null;
      return;
    });
  }

  checkRFNotes(ary) {
    let refName = `${this.ActiveUser.name} (${
      JSON.parse(localStorage.getItem("ActiveAgent")).name
    })`;
    let check;
    if (ary !== undefined) {
      check = ary.filter((item) => {
        if (item.name !== refName) {
          return item.name;
        }
      });
      if (check.length > 0) return true;
      return false;
    }
    return false;
  }
}
