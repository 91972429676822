<!-- <div class="site-header">
    <div class="container">
        <nav class="navbar navbar-expand-lg site-navbar navbar-light justify-content-between">
            <a class="navbar-brand brand-logo">
                <img src="assets/logo/5e6f470a5473202bd1d06f0c_Frame 1 (2).svg" alt="logo" />
            </a>
            <div class="website--name">
                My Social Housing
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="logoutButton">
                <a class="btn btn-outline-default btn-fw" (click)="logoutUser()">
                    <i class="fa fa-lock"></i> Logout
                </a>
            </div>
        </nav>
    </div>
</div> -->

<div class="top_sec">
    <div class="top_left">
        <div class="toggle_icon" (click)="toggleSidbar()"><img src="assets/images/toggle.png" ></div>
        <h4>{{title}}</h4>
        <!-- <div class="search_bar">
            <input type="text" (keyup)="typeUniversalSearch()" [(ngModel)]="universalSearchValue" placeholder="search here...">
            <button><img src="assets/images/search_icon.png"></button>
        </div> -->
    </div>
    <div class="top_right">
       <!-- <div class="search_bar">
            <form>
                <input type="text" placeholder="Search Here">
                <button><img src="assets/images/search_icon.png"></button>
            </form>
        </div> -->
         <div class="notification" style="cursor: pointer;">
            <div  class="noti_icon" (click)="markAsRead()"  data-toggle="modal" data-target="#staffActivities" data-dismiss="modal"><img src="assets/images/notification.png">
                <div class="counters">{{notificationCount}}</div>
            </div>
        </div>


        <div class="dropdown wH_dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="avatar_img"><img src="assets/images/no-user-img.png"></div>
                <div class="login_txt">
                    <h4>{{loggedInUser.name}}</h4>
                    <!-- <span>{{loggedInUser.type}}</span> -->
                </div>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="logoutUser()">Logout</a>

            </div>
        </div>


    </div>
</div>



   <!-- Modal to show staff activities -->
   <div class="modal fade" id="staffActivities" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog mg-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">User Activities</h5>
         <div class="d-flex justify-content-between">
          <!-- <button class="btn btn_primary w-100"  (click)="markAsRead()">All Mark as Read</button> -->
          <div class="d-flex">
            <button type="button" class="close" id="close-modal" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         </div>
        </div>
        <div class="modal-body">
            <div class="table-responsive wh_table">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="cursor: pointer;">Name</th>
                            <th style="cursor: pointer;" >Status</th>
                            <th style="cursor: pointer; width: 200px;" >Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-activity
                            [ngForOf]="staffActivitiesArray |  SortingFilter : sortvalue | paginate: { itemsPerPage: 100, currentPage: p_popup }"
                            let-i="index">
                            <tr>
                                <td><div class="txt_overflow">{{activity.userName}}</div></td>
                                <td><div class="txt_overflow"style="text-transform: capitalize;">{{activity.status}}</div></td>
                                <td><div class="txt_overflow"> <button class="btn btn_primary" (click)="viewUserDetails(activity.userId)">View</button></div></td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
            <div *ngIf="staffActivitiesArray?.length > 0" class="pagination mt-4 mb-4 wh_pagination Pagiantion">
                <pagination-controls (click)="clickPagination()" (pageChange)="p_popup = $event" directionLinks="true"
                    previousLabel="Previous" nextLabel="Next"></pagination-controls>
            </div>
        </div>
      </div>
    </div>
  </div>
