import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Ng5SliderModule } from 'ng5-slider';
import { NgxPaginationModule } from 'ngx-pagination';

import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedRoutingModule } from './shared-routing.module';
import { SortUserListPipe } from './pipes-function/sortingPipe';
import { TruncatePipe } from './pipes-function/truncatePipe';
import { UserListPipe } from './pipes-function/lisitngPipe';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { SendPushEmailComponent } from './send-push-email/send-push-email.component';

import { FooterComponent } from '../common/footer/footer.component';
import { HeaderComponent } from '../common/header/header.component';
import { NavBarTopComponent } from '../common/nav-bar-top/nav-bar-top.component';
import { NavigationFormComponent } from './navigation-form/navigation-form.component';
import { AddUserNoteComponent } from './add-user-note/add-user-note.component';
import { AddHAStaffComponent } from './add-ha-staff/add-ha-staff.component';
import { UpdateUserDetailsComponent } from './update-user-details/update-user-details.component';
import { AddStatusComponent } from './add-status/add-status.component';
import { AddRaStaffComponent } from './add-ra-staff/add-ra-staff.component';
import { ChangeAccountTypeComponent } from './change-account-type/change-account-type.component';
import { ChangeAccountNavigationComponent } from './change-account-navigation/change-account-navigation.component';
import { ChangeAccountDownloadDocComponent } from './change-account-download-doc/change-account-download-doc.component';



@NgModule({
  declarations: [
    SortUserListPipe , 
    TruncatePipe, 
    ImagePreviewComponent, 
    SendPushEmailComponent,
    UserListPipe,
    FooterComponent,
    HeaderComponent,
    NavBarTopComponent,
    NavigationFormComponent,
    AddUserNoteComponent,
    AddHAStaffComponent,
    UpdateUserDetailsComponent,
    AddStatusComponent,
    AddRaStaffComponent,
    ChangeAccountTypeComponent,
    ChangeAccountNavigationComponent,
    ChangeAccountDownloadDocComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    Ng5SliderModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot()
  ],
  exports : [
    SortUserListPipe , 
    TruncatePipe, 
    ImagePreviewComponent, 
    SendPushEmailComponent,
    UserListPipe,
    FooterComponent,
    HeaderComponent,
    NavBarTopComponent,
    NavigationFormComponent,
    BsDatepickerModule,
    Ng5SliderModule
    
  ]
})
export class SharedModule { }
