import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class CsvFileService {
  async downloadFile(data, headerList, filename) {
    let csvData;
    // if(filename == 'covid19-users'){
    //     csvData = await this.convertCovidUsersToCSV(data,headerList);
    // }else{
    csvData = await this.ConvertToCSV(data, headerList);
    // }
    console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  async ConvertToCSV(objArray, headerList) {
    // console.log("here")
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";
    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];
        if (
          (head == "createdAt" && array[i][head] != undefined) ||
          (head == "dateofbirth" && array[i][head] != undefined)
        ) {
          array[i][head].seconds !== undefined
            ? (line +=
                "," + moment.unix(array[i][head].seconds).format("MM/DD/YYYY"))
            : (line += "," + "-");
        } else if (head == "address" && array[i]["city"] != undefined) {
          line += "," + array[i]["city"].split(",").join(" ");
        } else if (head == "status" && array[i]["status"] != undefined) {
          array[i]["status"] == "rehoused"
            ? (line += "," + "Rehoused")
            : array[i]["status"] == "noContact"
            ? (line += "," + "No Contact")
            : array[i]["status"] == "refused"
            ? (line += "," + "Refused")
            : (line += "," + "-");
        } else {
          array[i][head] === undefined
            ? (line += "," + "-")
            : (line += "," + array[i][head]);
        }
      }
      str += line + "\r\n";
    }
    return str;
  }

  covidResponseAnalyzer(list) {
    var sortedList = list.sort((a, b) => {
      if (a.dateOfResponse && b.dateOfResponse) {
        const date1 = moment.unix(a.dateOfResponse.seconds).unix();
        const date2 = moment.unix(b.dateOfResponse.seconds).unix();
        return date2 > date1 ? 1 : -1;
      }
    });
    return sortedList[0];
  }
}
