<div class="userview_header">
  <div class="row justify-content-between align-items-center">
    <div class="col-4"></div>
    <div class="col-4">
      <div class="user--view-header">
        <h4 class="m-2"><i class="fa fa-home"></i> Property Details </h4>
      </div>
    </div>
    <div class="col-4 text-right">
    </div>
  </div>
</div>
<section class="property_detail_page">
  <div class="container">
    <div class="row">
      <div class="col-md-5 col-sm-5 property_img">
        <div class="property_img_inner" (click)="openImage(template1 ,propertyDetails.image)" style="cursor: pointer;">
          <img *ngIf="propertyDetails.image" [src]="checkImageUrl(propertyDetails.image)">
          <img *ngIf="!propertyDetails.image" src="assets/images/bg1.png">
        </div>
        <div class="map">
          <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(msrc)" frameborder="0" style="border:0;"
            allowfullscreen height="480px" width="450px"></iframe>
        </div>
      </div>
      <div class="col-md-7 col-sm-7 property_text">
        <div class="property_text_inner">
          <h3>{{propertyDetails.title}}</h3>
          <h6>{{propertyDetails.roomtype ? propertyDetails.roomtype + ' Bedroom' : ''}}</h6>
          <span>{{propertyDetails.property_address}}</span>

          <h5>Full Description</h5>
          <p>{{propertyDetails.desc}}</p>

          <h5>Location</h5>
          <p>{{propertyDetails.property_address }}</p>
        </div>
        <div class="tab_sec">
          <ul class="tabs">
            <li class="tab-link current" data-tab="tab-1" (click)="shuffleTabs('')">Super Markets</li>
            <li class="tab-link" data-tab="tab-2" (click)="shuffleTabs('')">Schools</li>
            <li class="tab-link" data-tab="tab-3" (click)="shuffleTabs('medical')">Medical Centres</li>
            <li class="tab-link" data-tab="tab-4" (click)="shuffleTabs('transport')">Transport</li>
            <li class="tab-link" data-tab="tab-5" (click)="shuffleTabs('worship')">Place of worship</li>
          </ul>

          <div id="tab-1" class="tab-content current">
            <div *ngIf="nearbySupermarket && nearbySupermarket.length == 0" class="d-flex justify-content-center mt-5">
              <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngFor="let market of nearbySupermarket let i = index">
                <p>&nbsp; <img [src]="market.icon" alt="" width="20px" > <a [href]="market.link" target="_blank">&nbsp; {{market.name}} &nbsp;</a> ({{market.distance.toFixed(2)}} miles)</p>
            </div>
          </div>
          <div id="tab-2" class="tab-content">
            <div *ngIf="nearbySchools && nearbySchools.length == 0" class="d-flex justify-content-center mt-5">
              <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngFor="let school of nearbySchools let i = index">
                <p>&nbsp; <img [src]="school.icon" alt="" width="20px" ><a [href]="school.link" target="_blank">&nbsp; {{school.name}} &nbsp;</a> ({{school.distance.toFixed(2)}} miles)</p>
            </div>
          </div>

          <div id="tab-3" class="tab-content">
            <div *ngIf="nearbyMedical && nearbyMedical.length == 0" class="d-flex justify-content-center mt-5">
              <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngFor="let medical of nearbyMedical let i = index">
                <p>&nbsp; <img [src]="medical.icon" alt="" width="20px" ><a [href]="medical.link" target="_blank">&nbsp; {{medical.name}} &nbsp;</a> ({{medical.distance.toFixed(2)}} miles)</p>
            </div>
          </div>
          <div id="tab-4" class="tab-content">
            <div *ngIf="nearbyTransport && nearbyTransport.length == 0" class="d-flex justify-content-center mt-5">
              <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngFor="let transport of nearbyTransport let i = index">
                <p>&nbsp; <img [src]="transport.icon" alt="" width="20px" ><a [href]="transport.link" target="_blank">&nbsp; {{transport.name}} &nbsp;</a> ({{transport.distance.toFixed(2)}} miles)</p>
            </div>
          </div>
          <div id="tab-5" class="tab-content">
            <div *ngIf="nearbyWorship && nearbyWorship.length == 0" class="d-flex justify-content-center mt-5">
              <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngFor="let worship of nearbyWorship let i = index">
                <p>&nbsp; <img [src]="worship.icon" alt="" width="20px" ><a [href]="worship.link" target="_blank">&nbsp; {{worship.name}} &nbsp;</a> ({{worship.distance.toFixed(2)}} miles)</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>
<ng-template #template1>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="dialog_img_inner">
      <img *ngIf="propertyDetails.image" [src]="viewImage" style="width: 100%">
      <img *ngIf="!propertyDetails.image" src="assets/images/bg1.png" style="min-width: 500px;" s>
    </div>
  </div>

</ng-template>
