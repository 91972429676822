import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
declare var $: any;

@Component({
  selector: 'app-propertydetail',
  templateUrl: './propertydetail.component.html',
  styleUrls: ['./propertydetail.component.scss']
})
export class PropertydetailComponent implements OnInit {

  propertyDetails: any;
  msrc: any;
  viewImage : any
  modalRef: BsModalRef;
  nearbySchools = [];
  nearbySupermarket = [];
  nearbyMedical = [];
  nearbyTransport = [];
  nearbyWorship = [];
  latitude:any;
  longitude:any;
  radiusInMiles: any;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    public  sanitizer: DomSanitizer,
    private alertnotification : AlertNotification,
    private apiservice : ApiServiceService,
    private loaderService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      let propertyId = params.id;
      let data = {
        propertyId: propertyId
      };
      var latitude = '';
      var longitude = '';
      this.apiservice.postRequest_b2('getSharedProperty',data).subscribe(async (response : any) => {
        this.propertyDetails = JSON.parse(response.data.details)
        // console.log(this.propertyDetails);
        latitude = this.propertyDetails.lat;
        longitude = this.propertyDetails.lng
        this.latitude = latitude;
        this.longitude = longitude;
        this.msrc = `https://www.google.com/maps?q=${latitude},${longitude}&output=embed`;


        let radiusInMiles = 3*1609.344; // 3 miles
        this.radiusInMiles = radiusInMiles;
        this.apiservice.postRequest('getAllPlaces.php',{lat: latitude, lng: longitude, radius: radiusInMiles, type: 'supermarket'}).subscribe(async(response) => {
          this.loaderService.show();
          this.nearbySupermarket = response['results'];
          // this.nearbySupermarket = response['results'].slice(0,10);
          this.loaderService.hide();
          // console.log(this.nearbySupermarket, 'supermarket');
        }),
        error => {
          console.log(error);
          this.alertnotification.errorAlert(error.message);
        }
        this.apiservice.postRequest('getAllPlaces.php',{lat: latitude, lng: longitude, radius: radiusInMiles, type: 'school'}).subscribe(async(response) => {
          this.loaderService.show();
          this.nearbySchools = response['results'];
          // this.nearbySchools = response['results'].slice(0,10);
          this.loaderService.hide();
          console.log(this.nearbySchools,'school');
        }),
        error => {
          console.log(error);
          this.alertnotification.errorAlert(error.message);
        }
      });
    });
  }

  openImage(template1: TemplateRef<any> , url){
    if(url.includes("https") || url.includes("http")) {
      this.viewImage = url
      // return url
    }
    this.modalRef = this.modalService.show(template1, {class: 'modal-show-image'});

  }

  checkImageUrl(url) {
    if(url.includes("https") || url.includes("http")) {
      return url
    } else{
      return('assets/images/bg1.png');
    }
  }
  decline(): void {
    this.modalRef.hide();
  }
  shuffleTabs(list){
    $('ul.tabs li').click(function(){
      var tab_id = $(this).attr('data-tab');

      $('ul.tabs li').removeClass('current');
      $('.tab-content').removeClass('current');

      $(this).addClass('current');
      $("#"+tab_id).addClass('current');
    })


    if(list === 'medical' && this.nearbyMedical.length === 0){
      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'pharmacy'}).subscribe(async(response) => {
        this.nearbyMedical.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }

      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'hospital'}).subscribe(async(response) => {
        this.nearbyMedical.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }

      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'veterinary_care'}).subscribe(async(response) => {
        this.nearbyMedical.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }
    }

    if(list === 'transport' && this.nearbyTransport.length == 0){
      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'bus_station'}).subscribe(async(response) => {
        this.nearbyTransport.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }

      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'train_station'}).subscribe(async(response) => {
        this.nearbyTransport.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }
    }

    if(list === 'worship' && this.nearbyWorship.length == 0){
      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'mosque'}).subscribe(async(response) => {
        this.nearbyWorship.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }

      this.apiservice.postRequest('getAllPlaces.php',{lat: this.latitude, lng: this.longitude, radius: this.radiusInMiles, type: 'church'}).subscribe(async(response) => {
        this.nearbyWorship.push(...response['results']);
      }),
      error => {
        console.log(error);
        this.alertnotification.errorAlert(error.message);
      }
    }


  }

}
