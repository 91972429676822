import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.scss']
})
export class AddStatusComponent implements OnInit {
  formSubmitted = false;
  AddStatusForm: FormGroup;
  userObject;
  loggedInStaff;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder : FormBuilder, 
    private router : Router,
    private apiservice : ApiServiceService,
    private alertnotification : AlertNotification,
    private firebaseservice : FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {
    this.loggedInStaff = JSON.parse(localStorage.getItem("UserType"));
    this.AddStatusForm = this.formBuilder.group({
      statusName : [null,[Validators.required]]
    })
   }

  ngOnInit(): void {
  }

  onSubmit() {
    this.formSubmitted = true;
    if(this.AddStatusForm.invalid) {
      return;
    }else{
      const data =  {
        statusName: this.AddStatusForm.value.statusName,
        date: new Date()
      }
      this.firebaseservice.addStatusArray(data).then(async () => {
        console.log("Added Successfully");
        this.alertnotification.successAlert("Added Successfully");
        // const updateUser = await this.updateUserInfo();
        // this.alertnotification.updateNoteData();
        this.loaderService.hide();
        this.bsModalRef.hide();
      }).catch((error) => {
        console.log(error);
      })
    }
  }

}
