import { Component, OnInit , ComponentRef, ComponentFactoryResolver} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ImagePreviewComponent } from '../../shared/image-preview/image-preview.component';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { SendPushEmailComponent } from '../../shared/send-push-email/send-push-email.component';
import Swal from 'sweetalert2';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import * as bcrypt from 'bcryptjs';
import { async } from '@angular/core/testing';

@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.scss']
})
export class UserdetailComponent implements OnInit {
  
  popoversMap = new Map<any, ComponentRef<ImagePreviewComponent>>();
  popoverFactory = this.resolver.resolveComponentFactory(ImagePreviewComponent);
  modalRef: BsModalRef;
  openCloseBool: Boolean = true;
  userDetail;
  _id: any;
  p:any;
  loginUID :any = false;

  constructor(
    private router : Router,
    private modalService: BsModalService,
    private resolver: ComponentFactoryResolver,
    private firebaseservice : FirebaseQueryService,
    private alertnotification : AlertNotification,
    private route: ActivatedRoute,
    private loaderService: NgxSpinnerService,
    private apiservice : ApiServiceService
  ) { }
  
  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this._id = params.id.split(" ").join("/");
      // console.log(this._id);
      this.loginUID = await Swal.mixin({
        showCancelButton: true,
      }).queue([
        {
          title: 'Enter Email',
          // text: `${userData.email}`,
          input: 'email',
          confirmButtonText: 'submit',
          inputValidator: (value) => {
            if (!value) {
              return 'email is required';
            }
          }
        }
      ]).then(async (result:any) => {
        // console.log(result);
        if(result.value && result.value.length > 0) {
          
          var data = {
            email : result.value[0],
            password : this._id
          }
          this.apiservice.postRequest_b2('getSharedUser',data).subscribe(async (response : any) => {
            console.log(response);
              this.loginUID = true;
              this.userDetail = JSON.parse(response.data.object) || {};
              var shortListProperties: any = await this.getuserCount(this.userDetail.email);
              var matchedProperties: any = await this.getMatchedShortlist(this.userDetail.email);
              
              let tempShortlist = [];
              for(const el of Object.entries(matchedProperties)){
                var number = parseInt(el[0])
                if(!isNaN(number)){
                  tempShortlist.push(el[1])
                }
              }
              this.userDetail['matchedProperties'] = tempShortlist ? tempShortlist.filter((x) => {
                console.log(x, x.shortlisted ,'x short')
                if (x && x.shortlisted && x.shortlisted == "true") {
                  return x;
                }
              }) : [];
              
              this.userDetail['shortlistedProperties'] = shortListProperties ? shortListProperties.filter((x) => {
                if (x && x.shortlisted && x.shortlisted == "true") {
                  return x;
                }
              }) : [];

              
            this.userDetail['arrImgIdProofObj'] = []
            if(this.userDetail.proofOfIdFiles){
              try {
                let file = this.userDetail.proofOfIdFiles;
                let name = await this.firebaseservice.getFileNameAndType(file);
                var re = /(?:\.([^.]+))?$/;
                let type = re.exec(name)[1]
                this.userDetail['arrImgIdProof'] = [];
                this.userDetail['arrImgIdProof'].push({
                  url: file,
                  name,
                  type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                }) 
                this.userDetail['arrImgIdProofObj'].push({
                  url: file,
                  name,
                  type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                })   
                console.log("data =====",this.userDetail['arrImgIdProofObj']);
              } catch (error) {
                console.log("ERROR IN SERVICE", error);
              }
            }else {
              for(const file of this.userDetail.arrImgIdProof){
                try {
                  let name = await this.firebaseservice.getFileNameAndType(file);
                  var re = /(?:\.([^.]+))?$/;
                  let type = re.exec(name)[1]
                  this.userDetail['arrImgIdProofObj'].push({
                    url: file,
                    name,
                    type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                  })   
                } catch (error) {
                  console.log("ERROR IN SERVICE", error);
                }
              
              }
                
            }
            console.log('this.userDetail',this.userDetail);
            this.userDetail['arrImgIncomeProofObj'] = []
            if(this.userDetail.proofOfIncomeFiles){
              try {
                let file =this.userDetail.proofOfIncomeFiles
                let name = await this.firebaseservice.getFileNameAndType(file);
                var re = /(?:\.([^.]+))?$/;
                let type = re.exec(name)[1]
                this.userDetail['arrImgIncomeProof'] = [];
                this.userDetail['arrImgIncomeProof'].push({
                    url: file,
                    name,
                    type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                  }) 
                this.userDetail['arrImgIncomeProofObj'].push({
                  url: file,
                  name,
                  type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                })
                } catch (error) {
                  console.log("ERROR IN SERVICE", error);
                }
            }else {
              for(const file of this.userDetail.arrImgIncomeProof){
                try {
                  let name = await this.firebaseservice.getFileNameAndType(file);
                var re = /(?:\.([^.]+))?$/;
                let type = re.exec(name)[1]
                this.userDetail['arrImgIncomeProofObj'].push({
                  url: file,
                  name,
                  type: (!type) ? 'png' : type.startsWith('png') || type.startsWith('jpeg') || type.startsWith('jpg') ? type.substring(0, type.indexOf('-')) : type,
                })
                } catch (error) {
                  console.log("ERROR IN SERVICE", error);
                }
                
              }
            }
              console.log("Data ==>", this.userDetail);
              return true;
          },
          async error => {
            // this.alertnotification.errorAlert('error');
            this.alertnotification.errorAlert(error.error.message || error.message);
            var data = await new Promise((resolve,reject) => {
              setTimeout(function () {
              resolve(true)
              },1300)
            }) 
            this.ngOnInit();    
          })
        }
        else{
          this.ngOnInit();
        }
      }).catch(function(error) {
        console.log(error);
      })
    });
  }
  getuserCount(email) {
    return new Promise((resolve, reject) => {
      this.apiservice.postRequest('shortlistedCountByEmail.php', JSON.stringify({ email: email })).subscribe((response: any) => {
        console.log(response.data, 'data shortlist')
        resolve(response.data)
      }),
        error => {
          this.alertnotification.errorAlert(error.message);
        }
    })
  }
  getMatchedShortlist(email) {
    return new Promise((resolve, reject) => {
      this.apiservice.postRequest('/shortlistProperties.php?all=true', { email: email }).subscribe((response: any) => {
        resolve(response)
      }),
        error => {
          this.alertnotification.errorAlert(error.message);
        }
    })
  }
  convertDate(date) {
    return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD MMM YY") + " at " + moment(date, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
  }
  changeDOB(value){
    // console.log(value);
    return value && value.seconds ? moment.unix(value.seconds).format('DD MMM YYYY') : '';
  }

  async showImage(imagePath,type) {
    var initialState = {
      ImageUrl : imagePath,
      proofType : type
    };
    this.modalRef = await this.modalService.show(ImagePreviewComponent, {
      initialState,
      class: "modal-lg",
      backdrop: "static"
    });
  }

}
