import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-change-account-download-doc',
  templateUrl: './change-account-download-doc.component.html',
  styleUrls: ['./change-account-download-doc.component.scss']
})
export class ChangeAccountDownloadDocComponent implements OnInit {
  formSubmitted = false;
  changeAccountDownloadDocForm: FormGroup;
  userObject
  downloadDoc=[
    {document: "Allow user to download file", checked:false}
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {
    this.changeAccountDownloadDocForm = this.formBuilder.group({
      downloadDoc: [null,[Validators.required]]
    })
  }

  ngOnInit(): void {
    this.downloadDoc[0].checked = this.userObject.downloadDoc == false ? false : true;
  }


  onSubmit() {
    this.formSubmitted = true;
    this.apiservice.postRequest_b2(`/updateUserDownloadDoc`, { email: this.userObject.email, downloadDoc: this.downloadDoc[0].checked }).subscribe(async (response: any) => {
      // this.authUserListArray = response.data;
      this.loaderService.hide();
      this.bsModalRef.hide();
      this.alertnotification.successAlert("Updated Successfully");
    },
      error => {
        this.alertnotification.errorAlert(error.error.message);
      });
  }
  onCheckboxChange(e) {
    this.downloadDoc[0].checked = e.target.checked;
  }

}