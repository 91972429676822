<div class="sidebar" id="mySidenav">
  <a href="javascript:void(0)" class="toggle_icon">&times;</a>
  <div class="logo">
    <a href="#"><img src="assets/images/logo.png" /></a>
    <div class="logo_txt">
      <h4>{{ ActiveAgent.name }}</h4>
    </div>
  </div>
  <div
    class="sidebar-wrapper ps-container ps-theme-default ps-active-y"
    data-ps-id="100f9503-8e93-202d-1ec7-a09419f5ac96"
  >
    <h3>Main Menu</h3>
    <ul class="nav">
      <li class="nav-item" *ngIf="oncheckUserNav('Users')">
        <a
          class="nav-link"
          routerLink="/landlord/user"
          routerLinkActive="active"
        >
          <img src="assets/images/users.png" /> Users
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/landlord/properties"
          routerLinkActive="active"
        >
          <img src="assets/images/home.png" /> Properties
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/landlord/settings"
          routerLinkActive="active"
        >
          <img src="assets/images/accounts.png" /> Settings
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLink="/landlord/accounts"
          routerLinkActive="active"
        >
          <img src="assets/images/accounts.png" /> Accounts
        </a>
      </li>
    </ul>
    <!-- <ul class="nav">
            <li class="nav-item " >
                <a class="nav-link" routerLink="/admin/dashboard" routerLinkActive="active"><img src="assets/images/dashboard.png"> Dashboard </a>
            </li>
            <li class="nav-item " *ngIf="oncheckUserNav('Properties')" >
                <a class="nav-link" routerLink="/admin/properties" routerLinkActive="active" ><img src="assets/images/home.png"> Properties </a>
            </li>
            <li class="nav-item " *ngIf="oncheckUserNav('Housing Association')" >
                <a class="nav-link" routerLink="/admin/HA" routerLinkActive="active"><img src="assets/images/housing.png"> Housing Association </a>
            </li>
            <li class="nav-item "  *ngIf="oncheckUserNav('Referring Agents')" >
                <a class="nav-link" routerLink="/admin/referringAgent" routerLinkActive="active"> <img src="assets/images/agents.png"> Referring Agents </a>
            </li>
            <li class="nav-item" *ngIf="oncheckUserNav('Area of Interest')">
                <a class="nav-link" routerLink="/admin/areaOfInterest" routerLinkActive="active"> <img src="assets/images/buildings.png"> Areas of Interest </a>
            </li>

            <li class="nav-item " *ngIf="oncheckUserNav('Shortlisted')" >
                <a class="nav-link" routerLink="/admin/shortlisted" routerLinkActive="active"> <img src="assets/images/shortlisted.png"> Shortlisted </a>
            </li>

            <li class="nav-item " *ngIf="oncheckUserNav('Matched')">
                <a class="nav-link" routerLink="/admin/matched" routerLinkActive="active"> <img src="assets/images/shortlisted.png"> Matched </a>
            </li>



            <!-- <li class="nav-item dropdown" *ngIf="oncheckUserNav('Notifications')">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-codepen menu-icon"></i>
                    <span class="menu-title">Notifications</span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a routerLink="/admin/pushnotification" class="dropdown-item">Push Notifications</a>
                </div>
            </li> --
            <li class="nav-item dropdown" *ngIf="oncheckUserNav('Notifications')">
                <a class="nav-link dropdown-toggle" routerLinkActive="active" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="assets/images/notification.png">
                    <span class="menu-title">Notifications</span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a routerLink="/admin/pushnotification" class="dropdown-item" routerLinkActive="active">Push Notifications</a>
                </div>
            </li>

            <!-- <li class="nav-item " *ngIf="oncheckUserNav('Notifications')" routerLinkActive="active">
                <a class="nav-link" routerLink="/admin/pushnotification" id="navbarDropdownMenuLink"> <img src="assets/images/notification.png"> Notification </a>
            </li> --
            <li class="nav-item " *ngIf="oncheckUserNav('Messaging Templates')" >
                <a class="nav-link" routerLink="/admin/messagingTemplate" routerLinkActive="active"> <img src="assets/images/shortlisted.png"> Messaging Templates </a>
            </li>
            <li class="nav-item " *ngIf="oncheckUserNav('Accounts')" >
                <a class="nav-link" routerLink="/auth/auth-users" routerLinkActive="active"> <img src="assets/images/accounts.png"> Accounts </a>
            </li>
            <li class="nav-item " *ngIf="oncheckUserNav('Authorizations')" >
                <a class="nav-link" routerLink="/admin/adminSettings" routerLinkActive="active"> <img src="assets/images/accounts.png"> Settings </a>
            </li>
        </ul> -->
  </div>

  <div class="copyrightSec">
    <h5>My Social Housing</h5>
    <span>© 2020 All Rights Reserved</span>
  </div>
</div>
