import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";
// import * as admin from "firebase-admin";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import { AngularFireStorage } from "@angular/fire/storage";
import { ApiServiceService } from "src/app/core/api-service/api-service";

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class UserSharedDataServices {
  DB = firebase.firestore();
  storageFire = firebase.storage();
  userList;
  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    private apiservice: ApiServiceService,
    private loaderService: NgxSpinnerService
  ) {}

  getUserListServices(value, refreshUser = false) {
    console.log("user refresh", refreshUser);
    this.loaderService.show();
    if (this.userList === undefined) {
      this.userList = this.DB.collection("users")
        .where("referringAgent", "==", value)
        .get();
      return this.userList;
    } else {
      if (refreshUser) {
        this.userList = this.DB.collection("users")
          .where("referringAgent", "==", value)
          .get();
      }
      return this.userList;
    }
  }
}
