import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";

@Component({
  selector: "app-add-ra-staff",
  templateUrl: "./add-ra-staff.component.html",
  styleUrls: ["./add-ra-staff.component.scss"],
})
export class AddRaStaffComponent implements OnInit {
  formSubmitted = false;
  Add_RA_Staff_Form: FormGroup;
  reffering_agent_id;
  staffData;
  disableEmail: Boolean = false;
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification
  ) {
    this.Add_RA_Staff_Form = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      address: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.staffData && this.staffData.id) {
      this.disableEmail = true;
      this.Add_RA_Staff_Form.controls["name"].setValue(this.staffData.name);
      this.Add_RA_Staff_Form.controls["email"].setValue(this.staffData.email);
      this.Add_RA_Staff_Form.controls["phone"].setValue(this.staffData.phone);
      this.Add_RA_Staff_Form.controls["address"].setValue(
        this.staffData.address
      );
      if (this.disableEmail == true) {
        this.Add_RA_Staff_Form.controls["email"].disable();
      }
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.Add_RA_Staff_Form.invalid) {
      return;
    } else {
      this.Add_RA_Staff_Form.value["reffering_agent_id"] =
        this.reffering_agent_id.id;
      if (this.staffData && this.staffData.id) {
        this.apiservice
          .postRequest(
            "updateRefferingAgentStaff.php",
            this.Add_RA_Staff_Form.value
          )
          .subscribe(
            (response: any) => {
              this.alertnotification.successAlert(response.messeage);
              this.bsModalRef.content.onClose.next(true);

              this.bsModalRef.hide();
            },
            (error) => {
              this.alertnotification.errorAlert(error.message);
              this.bsModalRef.content.onClose.next(true);

              this.bsModalRef.hide();
            }
          );
      } else {
        this.apiservice
          .postRequest(
            "addRefferingAgentStaff.php",
            this.Add_RA_Staff_Form.value
          )
          .subscribe(
            (response: any) => {
              this.alertnotification.successAlert(response.messeage);
              this.bsModalRef.hide();
            },
            (error) => {
              this.alertnotification.errorAlert(error.message);
              this.bsModalRef.hide();
            }
          );
      }
    }
  }
}
