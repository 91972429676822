<div>
  <div class="global-search">
    <div class="filter--agent text-left border_none">
      <input
        type="text"
        class="form-control"
        aria-label="Text input with dropdown button"
        (keyup)="universalSearch()"
        [(ngModel)]="universalSearchValue"
        placeholder="search"
      />
      <span><i class="fa fa-search"></i></span>
    </div>
  </div>
  <div class="top_btn">
    <div class="total_count">Total {{ userListingArray.length || 0 }}</div>
    <div class="btns">
      <button class="btn_primary" (click)="getRAUser(true)">
        Refresh Users
      </button>
      <button class="btn_primary" (click)="fileUploader.click()">
        Bulk Upload
      </button>
      <input
        style="display: none"
        (change)="uploadCsvFile($event.target.files)"
        type="file"
        accept=".csv"
        #fileUploader
      />
      <button class="btn_primary" (click)="exportUserList()">
        Export Users
      </button>
      <button
        [disabled]="usersListArray.length == 0"
        class="btn_primary send_btn"
        data-toggle="modal"
        data-target="#exampleModal2"
      >
        Send Email
      </button>
    </div>
  </div>
  <div class="table-responsive wh_table" style="min-height: 300px">
    <table class="table">
      <thead>
        <tr>
          <th>
            <i
              class="fa fa-sort mr-1"
              (click)="sortRefferingListingArray('agentNotes')"
            ></i>
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'agentNotes',
                'fa-arrow-up':
                  sortvalue[1] == 'desc' && sortvalue[0] == 'agentNotes'
              }"
            ></i>
          </th>
          <th></th>
          <th style="cursor: pointer">
            <div class="d-flex">
              <i
                class="fa fa-sort mr-1"
                (click)="sortDotsListingArray('documentCount')"
              ></i>
              <i
                class="fa"
                [ngClass]="{
                  'fa-arrow-down':
                    sortvalue[1] == 'asc' && sortvalue[0] == 'documentCount',
                  'fa-arrow-up':
                    sortvalue[1] == 'desc' && sortvalue[0] == 'documentCount'
                }"
              ></i>
            </div>
          </th>
          <th style="cursor: pointer">
            <i class="fa fa-sort" (click)="sortLisitng('firstName')"></i> First
            name
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'firstName',
                'fa-arrow-up':
                  sortvalue[1] == 'desc' && sortvalue[0] == 'firstName'
              }"
            ></i>
          </th>
          <th style="cursor: pointer" (click)="sortLisitng('lastName')">
            Last name
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'lastName',
                'fa-arrow-up':
                  sortvalue[1] == 'desc' && sortvalue[0] == 'lastName'
              }"
            ></i>
          </th>
          <th style="cursor: pointer" (click)="sortLisitng('age')">
            Age
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down': sortvalue[1] == 'asc' && sortvalue[0] == 'age',
                'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'age'
              }"
            ></i>
          </th>
          <th style="cursor: pointer" (click)="sortLisitng('phone')">
            Phone
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'phone',
                'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'phone'
              }"
            ></i>
          </th>
          <th style="cursor: pointer" (click)="sortLisitng('city')">
            Address
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'city',
                'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'city'
              }"
            ></i>
          </th>
          <th style="cursor: pointer" (click)="sortLisitng('date')">
            Date
            <i
              class="fa"
              [ngClass]="{
                'fa-arrow-down':
                  sortvalue[1] == 'asc' && sortvalue[0] == 'date',
                'fa-arrow-up': sortvalue[1] == 'desc' && sortvalue[0] == 'date'
              }"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-template
          ngFor
          let-userObject
          [ngForOf]="
            userListingArray
              | LisitingFilter: searchValue
              | SortingFilter: sortvalue
              | paginate: { itemsPerPage: 100, currentPage: p }
          "
          let-i="index"
        >
          <tr
            [ngStyle]="{
              'background-color':
                userObject.public == 'true' || userObject.public == true
                  ? 'rgba(144, 238, 144,0.3)'
                  : ''
            }"
          >
            <td>
              <i
                *ngIf="userObject.referringAgentNotes"
                class="fa fa-paperclip fa-lg"
                aria-hidden="true"
                style="color: orange"
              ></i>
              <i
                *ngIf="checkRFNotes(userObject.referringAgentNotes)"
                class="fa fa-paperclip fa-lg"
                aria-hidden="true"
                style="color: green"
              ></i>
            </td>
            <td style="width: 50px; text-align: right">
              <div class="form-check mt-0">
                <input
                  type="checkbox"
                  class="form-check-input mt-0"
                  id="exampleCheck1"
                  (click)="copyUserEmail(userObject.email)"
                  [checked]="usersListArray.includes(userObject.email)"
                />
              </div>
            </td>
            <td>
              <span
                class="user-document-count"
                [ngStyle]="{
                  'background-color':
                    userObject.documentCount == 3
                      ? '#62c91c'
                      : userObject.documentCount == 2
                      ? '#f4c76e'
                      : userObject.documentCount == 1
                      ? '#e61010'
                      : '#D3D3D3'
                }"
                >{{ userObject.documentCount }}</span
              >
            </td>
            <td>
              <a
                (click)="clickOnUserEmail(userObject.email)"
                target="_blank"
                class="link"
                [ngClass]="{ badge: userObject?.verified }"
              >
                {{ userObject.firstName }}
              </a>
            </td>
            <td>
              <a
                (click)="clickOnUserEmail(userObject.email)"
                target="_blank"
                class="link"
                [ngClass]="{ badge: userObject?.verified }"
              >
                {{ userObject.lastName }}
              </a>
            </td>
            <td>
              {{ userObject.age }}
            </td>
            <td>
              {{ userObject.phone }}
            </td>
            <td>
              {{ userObject.city }}
            </td>
            <td>
              {{ userObject.createDate }}
            </td>
            <td class="text-center">
              <div class="d-flex justify-content-end">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="assets/images/three_dots.png" />
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      class="dropdown-item"
                      (click)="viewUserDetail(userObject, i)"
                    >
                      <i class="far fa-eye"></i> View
                    </button>
                    <button
                      [disabled]="oncheckUserView()"
                      class="dropdown-item"
                      (click)="copyUserLink(userObject)"
                    >
                      <i class="fas fa-copy"></i> Copy Link
                    </button>
                    <button
                      [disabled]="oncheckUserView()"
                      class="dropdown-item"
                      (click)="addNote(userObject)"
                    >
                      <i class="fas fa-plus"></i> Add Note
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <tr *ngIf="userListingArray?.length == 0">
          <td colspan="7" class="text-center">No documents found !</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="userListingArray?.length > 0"
    class="pagination mt-4 mb-4 wh_pagination Pagiantion"
  >
    <pagination-controls
      (click)="clickPagination()"
      (pageChange)="p = $event"
      directionLinks="true"
      previousLabel="Previous"
      nextLabel="Next"
    >
    </pagination-controls>
  </div>
</div>

<!--End of users lisiting -->

<!--start of  Modals for other Services-->

<!-- Modal to send email-->
<div
  class="modal fade"
  id="exampleModal2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog mg-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Send Mail to Users</h5>
        <div class="d-flex">
          <button
            type="button"
            class="close"
            id="close-modal-mail"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="sendMailData" (ngSubmit)="sendmail()">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              >Emails</label
            >
            <div class="col-sm-10">
              <input
                type="email"
                class="form-control"
                id="inputEmail3"
                value="{{ recipient }}"
                formControlName="emails"
                readonly
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="title" class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                id="title"
                formControlName="subject"
                [(ngModel)]="emailTemplateTitle"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="textarea" class="col-sm-2 col-form-label"
              >Content</label
            >
            <div class="col-sm-10" id="textarea-c">
              <textarea
                name=""
                id="textarea-c"
                class="form-control"
                cols="30"
                rows="6"
                formControlName="textcontent"
                [(ngModel)]="textcontentData"
              ></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="textarea" class="col-sm-2 col-form-label"
              >Property URls</label
            >
            <div class="col-sm-10" id="textarea-c">
              <textarea
                name=""
                id="textarea-c"
                class="form-control"
                cols="30"
                rows="4"
                formControlName="content"
                [(ngModel)]="allcontentData"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="text-left col-6">
              <button
                type="button"
                class="btn_grey copyBtn mr-5"
                data-toggle="modal"
                data-target="#exampleModal3"
                (click)="getPropertiesData()"
              >
                Select Property
              </button>
            </div>
            <div class="text-right col-6">
              <button
                type="submit"
                [disabled]="
                  !emailTemplateTitle || !allcontentData || !textcontentData
                "
                class="btn btn_primary"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-----------------Select Property------------------>
<div
  class="modal fade modalMargin"
  id="exampleModal3"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content changeInModal">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select Property</h5>
        <div class="d-flex">
          <button
            type="button"
            class="close"
            id="close-property-modal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="table-responsive wh_table">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th style="cursor: pointer">Image</th>
                <th style="cursor: pointer">Title</th>
                <th style="cursor: pointer">Provider</th>
                <th style="cursor: pointer">Description</th>
                <th style="cursor: pointer">Amount</th>
                <th style="cursor: pointer">Property Address</th>
                <th style="cursor: pointer">Bedroom Size</th>
              </tr>
            </thead>
            <tbody>
              <ng-template
                ngFor
                let-property
                [ngForOf]="
                  propertiesListArray
                    | SortingFilter: sortvalue
                    | paginate: { itemsPerPage: 100, currentPage: p_popup }
                "
                let-i="index"
              >
                <tr
                  [ngClass]="{ 'has-active-error': property.active == false }"
                >
                  <td>
                    <input
                      [(ngModel)]="property.selected"
                      type="checkbox"
                      (click)="selectObject($event, i, property.id, property)"
                    />
                  </td>
                  <td>
                    <div class="inline_box">
                      <img
                        class="img_logo"
                        *ngIf="property.image && property.image != 'null'"
                        src="{{ property.image }}"
                      />
                      <img
                        class="img_logo"
                        *ngIf="!property.image || property.image === 'null'"
                        src="assets/images/icon_gradiant.png"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      <span class="user--name">{{ property.title }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.provider }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      {{ property.desc | limitTo: "200" }}
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.amount }}</div>
                  </td>
                  <td>
                    <div class="txt_overflow">
                      {{ property.property_address }}
                    </div>
                  </td>
                  <td>
                    <div class="txt_overflow">{{ property.roomtype }}</div>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
        <div
          *ngIf="propertiesListArray?.length > 0"
          class="pagination mt-4 mb-4 wh_pagination Pagiantion"
        >
          <pagination-controls
            (click)="clickPaginationPopUp()"
            (pageChange)="p_popup = $event"
            directionLinks="true"
            previousLabel="Previous"
            nextLabel="Next"
          ></pagination-controls>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          (click)="clearAllProperties()"
        >
          Clear
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="saveCheckedProperties()"
        >
          Add Selected Properties
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
