import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
// import { ApiServiceService } from 'src/app/core/services/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import * as firebase from 'firebase';
import Swal from 'sweetalert2';
import * as CryptoJS from 'crypto-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiServiceService } from 'src/app/core/api-service/api-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  registerForm: FormGroup;
  formSubmitted = false;
  email;
  password;
  autologin = false;
  constructor(
    private formBuilder : FormBuilder,
    private router : Router,
    private db:AngularFirestore,
    private apiservice : ApiServiceService,
    private firebaseservice : FirebaseQueryService,
    private alertnotification : AlertNotification,
    private loaderService: NgxSpinnerService,
  ) {
    this.email = location.pathname.split('/')[3];
    const pwd64 = location.pathname.split('/')[4]
    if(pwd64 !==undefined){
      this.autologin = true;
      const pass = atob(pwd64);
      this.password = pass ;
      console.log("password", this.password);
      this.onSubmit();
    }
   }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
    {
      password : [null,[Validators.required]]
    })
    console.log("this.email",this.email);
  }

  async onSubmit() {
    let logData;
    let loggedIn = false;
    let accountType;
    this.formSubmitted = true;
    localStorage.removeItem('token');

    if(!this.autologin){
      if (this.registerForm.invalid) {
        return;
      }
      this.password = this.registerForm.value.password
      console.log(this.registerForm.value);
    }
    this.loaderService.show();
    // User Sign In
    console.log("this.email",this.email);
    await this.firebaseservice.signInUser(this.email,this.password).then(async function (response){
      localStorage.setItem('User', JSON.stringify(response));
      try {
        var data = {
          email : this.email,
          uid : response.user.uid
        }
        var userObject = await this.getUserType(data);
        // console.log(userObject);
      }catch (e) {
        console.log(e)
      }
      console.log(userObject)
      if(userObject.data && userObject.data.length > 0) {
        userObject.data.map((x) => {
          localStorage.setItem('UserType', JSON.stringify(x));
        })
      }else{
        // console.log("reached else");
        this.alertnotification.errorAlert("No User Found");
        return;
      }
      // Get Current User TokenID
      firebase.auth().currentUser.getIdToken().then(function(token){
        console.log(token);
        localStorage.setItem('token', JSON.stringify(token));
      })
      localStorage.setItem('countersign', JSON.stringify(this.password));
      this.loaderService.hide();
      this.alertnotification.successAlert('Signed In Successfully');
      // here ==========>>>>>> add a log for staff members on signin
      loggedIn = true;
      accountType = userObject.data[0].type;
      logData = {
        staffName: userObject.data[0].name,
        staffEmail: userObject.data[0].email,
        actionPerformed: "Logged in",
        dateTime: new Date()
      };
    }.bind(this))
    .catch(function(error) {
      console.log(error);
      this.alertnotification.errorAlert(error);
      return;
    }.bind(this));
    if(loggedIn){
      await this.firebaseservice.uploadStaffActivity(logData);
      // set date when logged In
      var nowDate = new Date();
      const currentDate = nowDate.getDate()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getFullYear();
      var key = CryptoJS.enc.Base64.parse("253D3FB468A0E24677C28A624BE0F939");
      var iv  = CryptoJS.enc.Base64.parse("                ");
      var encryptedDate = CryptoJS.AES.encrypt(currentDate, key, {iv: iv});
      localStorage.setItem('login_session', encryptedDate);
    }
    console.log({accountType})
    if(accountType == 'RA'){
      let data = {
        "email": logData.staffEmail
      }
      this.apiservice.postRequest('getRefferingAgent.php',data).subscribe((result:any) => {
        console.log(result.data);
        let referringAgent = result.data[0];
        console.log("this.referringAgent", referringAgent);
        localStorage.setItem('ActiveAgent', JSON.stringify(referringAgent))
        this.router.navigate(['landlord/user']);
      },
      error => {

      });
    }else{
      console.log('NOT RA')
    }
  }

  getUserType(data) {
    return new Promise((resolve,reject) => {
      this.apiservice.postRequest_b2('afterLogin',data).subscribe((response) => {
        resolve(response);
      },
      error => {
        reject(error);
      })
    })
  }

  errorMessageAlert(error){
    this.alertnotification.errorAlert(error);
  }

  async resetPassword( ) {
    var swalResult = await Swal.fire({
      title: 'Enter your email',
      input: 'email',
      showCancelButton: true,
      confirmButtonText : 'Send forgot password link',
      inputPlaceholder: 'Enter your email address'
    }).then((result) => {
      if (!result.value) {
        return;
      }
      else{
        console.log(result);
        return result.value;
      }
    });

    if(!swalResult) {
      return
    }else{
      if(swalResult !== 'admin@mysocialhousing.com' && swalResult !== 'mrdeeodus@gmail.com') {
        this.alertnotification.errorAlert('Unauthorised User');
        return;
      }
      this.firebaseservice.passwordreset(swalResult).then(function(response) {
        console.log(response);
        this.loaderService.hide();
        this.alertnotification.successAlert("reset password link sent successfully");
      }.bind(this))
      .catch(function(err) {
        // console.log(err);
        var errorMessage = err.message;
        this.loaderService.hide();
        this.alertnotification.errorAlert(err);
        return;
      }.bind(this))
    }
  }
}

// this.alertnotification.errorAlert('Unauthorised User');
