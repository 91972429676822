import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';


import { PublicLinkRoutingModule } from './public-link-routing.module';
import { UserdetailComponent } from './userdetail/userdetail.component';
import { PublicLinkComponent } from './public-link.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxPaginationModule } from 'ngx-pagination';
import { PropertydetailComponent } from './propertydetail/propertydetail.component';

@NgModule({
  declarations: [UserdetailComponent, PublicLinkComponent, PropertydetailComponent],
  imports: [
    CommonModule,
    PublicLinkRoutingModule,
    SharedModule,
    NgxPaginationModule,
    AccordionModule.forRoot(),
  ]
})
export class PublicLinkModule { }
