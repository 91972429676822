<div *ngIf="formType == 'accountDetails'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Profile Details</h4>
                    <form class="form-sample" [formGroup]="AccountDetailForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> First Name </label>
                                <input [(ngModel)]="userObject.firstName" type="text" class="form-control"
                                    placeholder="First Name" formControlName="firstName" name="firstName">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.firstName.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.firstName.errors.required">First Name is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Last Name </label>
                                <input [(ngModel)]="userObject.lastName" type="text" class="form-control"
                                    placeholder="Last Name" formControlName="lastName" name="lastName">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.lastName.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.lastName.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Age </label>
                                <input [(ngModel)]="userAge" type="text" class="form-control" placeholder="Age"
                                    formControlName="age" name="age" readonly>
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.age.errors" class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.age.errors.required">Age is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Date Of Birth </label>
                                <input (bsValueChange)="dateChange($event)" bsDatepicker [(ngModel)]=userDOB formControlName="dateOfBirth"
                                    class="form-control" placeholder="Date of birth" name="birthday"
                                    [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , rangeInputFormat: 'DD/MM/YYYY'}">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.dateOfBirth.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.dateOfBirth.errors.required">Date Of Birth
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Email </label>
                                <input [(ngModel)]="userObject.email" type="email" class="form-control"
                                    placeholder="Email" formControlName="email" name="email" readonly>
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.email.errors" class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.email.errors.required">Email is
                                        required</span>
                                    <span *ngIf="AccountDetailForm.controls.email.errors.email">Enter valid email</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Phone Number</label>
                                <input [(ngModel)]="userObject.phoneNumber1" type="text" class="form-control"
                                    placeholder="Phone Number" formControlName="phoneNumber1" name="phoneNumber1">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.phoneNumber1.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.phoneNumber.errors.required">Phone Number is
                                        required</span>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <label class="col-form-label"> Email 1</label>
                                <input [(ngModel)]="userObject.email1" type="email" class="form-control"
                                    placeholder="Email" formControlName="email1" name="email1">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.email1.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.email1.errors.required">Email is
                                        required</span>
                                    <span *ngIf="AccountDetailForm.controls.email1.errors.email1">Enter valid
                                        email</span>
                                </div>
                            </div> -->
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> City </label>
                                <input [(ngModel)]="userObject.city" type="text" class="form-control" placeholder="City"
                                    formControlName="city" name="city">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.city.errors" class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.city.errors.required">City is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Address </label>
                                <input [(ngModel)]="userObject.address" type="text" class="form-control"
                                    placeholder="Address" formControlName="address" name="address">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.address.errors" class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.address.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Postcode </label>
                                <input [(ngModel)]="userObject.postCode" type="text" class="form-control"
                                    placeholder="Postcode" formControlName="postCode" name="postCode">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.postCode.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.postCode.errors.required">Postcode is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Email 2</label>
                                <input [(ngModel)]="userObject.email2" type="email" class="form-control"
                                    placeholder="Email" formControlName="email2" name="email2">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.email2.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.email2.errors.required">Email is
                                        required</span>
                                    <span *ngIf="AccountDetailForm.controls.email2.errors.email2">Enter valid
                                        email</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Referring Agent </label>
                                <input [(ngModel)]="userObject.referringAgent" type="text" class="form-control"
                                    placeholder="Referring Agent" formControlName="referringAgent"
                                    name="referringAgent">
                                <div *ngIf="formSubmitted && AccountDetailForm.controls.referringAgent.errors"
                                    class="has-error">
                                    <span *ngIf="AccountDetailForm.controls.referringAgent.errors.required">Referring
                                        Agent is required</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="onCancel()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="formType == 'otherDetails'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Other Details</h4>
                    <form class="form-sample" [formGroup]="otherDetailForm" (submit)="onSubmit()">

                         <div class="form-group row">
                            <div class="col-md-6" *ngIf="userObject.email1">
                                <label class="col-form-label">Alernative Email</label>
                                <input [(ngModel)]="userObject.email1" type="email" class="form-control"
                                    placeholder="Email" formControlName="email1" name="email1">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.email1.errors"
                                    class="has-error">
                                    <span *ngIf="otherDetailForm.controls.email1.errors.required">Email is
                                        required</span>
                                    <span *ngIf="otherDetailForm.controls.email1.errors.email1">Enter valid
                                        email</span>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="!userObject.email1">
                                <label class="col-form-label">Alernative Email</label>
                                <input [(ngModel)]="userObject.email2" type="email" class="form-control"
                                    placeholder="Email" formControlName="email2" name="email2">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.email2.errors"
                                    class="has-error">
                                    <span *ngIf="otherDetailForm.controls.email2.errors.required">Email is
                                        required</span>
                                    <span *ngIf="otherDetailForm.controls.email2.errors.email2">Enter valid
                                        email</span>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> City </label>
                                <input [(ngModel)]="userObject.city" type="text" class="form-control" placeholder="City"
                                    formControlName="city" name="city">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.city.errors" class="has-error">
                                    <span *ngIf="otherDetailForm.controls.city.errors.required">City is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Address </label>
                                <input [(ngModel)]="userObject.address" type="text" class="form-control"
                                    placeholder="Address" formControlName="address" name="address">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.address.errors" class="has-error">
                                    <span *ngIf="otherDetailForm.controls.address.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Postcode </label>
                                <input [(ngModel)]="userObject.postCode" type="text" class="form-control"
                                    placeholder="Postcode" formControlName="postCode" name="postCode">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.postCode.errors"
                                    class="has-error">
                                    <span *ngIf="otherDetailForm.controls.postCode.errors.required">Postcode is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Phone Number 1</label>
                                <input [(ngModel)]="userObject.phoneNumber1" type="text" class="form-control"
                                    placeholder="Phone Number" formControlName="phoneNumber1" name="phoneNumber1">
                                <div *ngIf="formSubmitted && otherDetailForm.controls.phoneNumber1.errors"
                                    class="has-error">
                                    <span *ngIf="otherDetailForm.controls.phoneNumber1.errors.required">Phone Number is
                                        required</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'referringAgency'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Referring Agency</h4>
                    <form class="form-sample" [formGroup]="referringAgencyForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> First Name </label>
                                <input [(ngModel)]="userObject.firstNameRA" type="text" class="form-control"
                                    placeholder="First Name" formControlName="firstNameRA" name="firstNameRA">
                                <div *ngIf="formSubmitted && referringAgencyForm.controls.firstNameRA.errors"
                                    class="has-error">
                                    <span *ngIf="referringAgencyForm.controls.firstNameRA.errors.required">First Name is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Last Name </label>
                                <input [(ngModel)]="userObject.lastNameRA" type="text" class="form-control"
                                    placeholder="Last Name" formControlName="lastNameRA" name="lastNameRA">
                                <div *ngIf="formSubmitted && referringAgencyForm.controls.lastNameRA.errors"
                                    class="has-error">
                                    <span *ngIf="referringAgencyForm.controls.lastNameRA.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Email </label>
                                <input [(ngModel)]="userObject.emailRA" type="email" class="form-control"
                                    placeholder="Email" formControlName="emailRA" name="emailRA">
                                <div *ngIf="formSubmitted && referringAgencyForm.controls.emailRA.errors" class="has-error">
                                    <span *ngIf="referringAgencyForm.controls.emailRA.errors.required">Email is
                                        required</span>
                                    <span *ngIf="referringAgencyForm.controls.emailRA.errors.email">Enter valid email</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Contact No </label>
                                <input [(ngModel)]="userObject.phoneNumberRA" type="text" class="form-control"
                                    placeholder="Phone Number" formControlName="phoneNumberRA" name="phoneNumberRA">
                                <div *ngIf="formSubmitted && referringAgencyForm.controls.phoneNumberRA.errors"
                                    class="has-error">
                                    <span *ngIf="referringAgencyForm.controls.phoneNumberRA.errors.required">Contact No is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'addressHistory'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Address History</h4>
                    <form class="form-sample" [formGroup]="addressHistoryForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Address 2 </label>
                                <input [(ngModel)]="userObject.addressHistory2" type="text" class="form-control"
                                    placeholder="Address 2" formControlName="addressHistory2" name="addressHistory2">
                                <div *ngIf="formSubmitted && addressHistoryForm.controls.addressHistory2.errors"
                                    class="has-error">
                                    <span *ngIf="addressHistoryForm.controls.addressHistory2.errors.required">Address 2
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Address 3 </label>
                                <input [(ngModel)]="userObject.addressHistory3" type="text" class="form-control"
                                    placeholder="Address 3" formControlName="addressHistory3" name="addressHistory3">
                                <div *ngIf="formSubmitted && addressHistoryForm.controls.addressHistory3.errors"
                                    class="has-error">
                                    <span *ngIf="addressHistoryForm.controls.addressHistory3.errors.required"> Address 3
                                        is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Address 4 </label>
                                <input [(ngModel)]="userObject.addressHistory4" type="text" class="form-control"
                                    placeholder="Address 4" formControlName="addressHistory4" name="addressHistory4">
                                <div *ngIf="formSubmitted && addressHistoryForm.controls.addressHistory4.errors"
                                    class="has-error">
                                    <span *ngIf="addressHistoryForm.controls.addressHistory4.errors.required"> Address 4
                                        is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Address 5 </label>
                                <input [(ngModel)]="userObject.addressHistory5" type="text" class="form-control"
                                    placeholder="Address 5" formControlName="addressHistory5" name="addressHistory5">
                                <div *ngIf="formSubmitted && addressHistoryForm.controls.addressHistory5.errors"
                                    class="has-error">
                                    <span *ngIf="addressHistoryForm.controls.addressHistory5.errors.required"> Address 5
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'landlordDetails'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Landlord Details</h4>
                    <form class="form-sample" [formGroup]="landlordDetailForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Landlord Name </label>
                                <input [(ngModel)]="userObject.landlordName" type="text" class="form-control"
                                    placeholder="Landlord Name" formControlName="landlordName" name="landlordName">
                                <div *ngIf="formSubmitted && landlordDetailForm.controls.landlordName.errors"
                                    class="has-error">
                                    <span *ngIf="landlordDetailForm.controls.landlordName.errors.required">Landlord Name
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Landlord Email </label>
                                <input [(ngModel)]="userObject.landlordEmail" type="text" class="form-control"
                                    placeholder="Landlord Email" formControlName="landlordEmail" name="landlordEmail">
                                <div *ngIf="formSubmitted && landlordDetailForm.controls.landlordEmail.errors"
                                    class="has-error">
                                    <span *ngIf="landlordDetailForm.controls.landlordEmail.errors.email">Enter valid
                                        email</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Landlord Phone Number </label>
                                <input [(ngModel)]="userObject.landlordPhoneNumber" type="text" class="form-control"
                                    placeholder="Landlord Phone Number" formControlName="landlordPhoneNumber"
                                    name="landlordPhoneNumber">
                                <div *ngIf="formSubmitted && landlordDetailForm.controls.landlordPhoneNumber.errors"
                                    class="has-error">
                                    <span
                                        *ngIf="landlordDetailForm.controls.landlordPhoneNumber.errors.pattern">Landlord
                                        Phone Number is invalid</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'accommodationRequired'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Accommodation Required</h4>
                    <form class="form-sample" [formGroup]="accommodationForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Accommodation </label>
                                <input [(ngModel)]="userObject.accommodationRequired" type="text" class="form-control"
                                    placeholder="Accommodation" formControlName="accommodationRequired" name="accommodationRequired">
                                <div *ngIf="formSubmitted && accommodationForm.controls.accommodationRequired.errors"
                                    class="has-error">
                                    <span *ngIf="accommodationForm.controls.accommodationRequired.errors.required">Accommodation
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Bedroom Size </label>
                                <input [(ngModel)]="userObject.bedroomSize" type="text" class="form-control"
                                    placeholder="Bedroom Size" formControlName="bedroomSize" name="bedroomSize">
                                <div *ngIf="formSubmitted && accommodationForm.controls.bedroomSize.errors"
                                    class="has-error">
                                    <span *ngIf="accommodationForm.controls.bedroomSize.errors.required"> Bedroom Size
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Current Accommodation </label>
                                <input [(ngModel)]="userObject.currentAccommodation" type="text" class="form-control"
                                    placeholder="Current Accommodation" formControlName="currentAccommodation" name="currentAccommodation">
                                <div *ngIf="formSubmitted && accommodationForm.controls.currentAccommodation.errors"
                                    class="has-error">
                                    <span *ngIf="accommodationForm.controls.currentAccommodation.errors.required"> Current Accommodation
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Current Tenancy Type </label>
                                <input [(ngModel)]="userObject.currentTenancyType" type="text" class="form-control"
                                    placeholder="Current Tenancy Type" formControlName="currentTenancyType" name="currentTenancyType">
                                <div *ngIf="formSubmitted && accommodationForm.controls.currentTenancyType.errors"
                                    class="has-error">
                                    <span *ngIf="accommodationForm.controls.currentTenancyType.errors.required"> Current Tenancy Type
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'incomeDetails'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Income Details</h4>
                    <form class="form-sample" [formGroup]="incomeDetailsForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Employment Details </label>
                                <input [(ngModel)]="userObject.employmentDetails" type="text" class="form-control"
                                    placeholder="Employment Details" formControlName="employmentDetails" name="employmentDetails">
                                <div *ngIf="formSubmitted && incomeDetailsForm.controls.employmentDetails.errors"
                                    class="has-error">
                                    <span *ngIf="incomeDetailsForm.controls.employmentDetails.errors.required">Employment Details
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Income (£) </label>
                                <input [(ngModel)]="userObject.income" type="text" class="form-control"
                                    placeholder="Income (£)" formControlName="income" name="income">
                                <div *ngIf="formSubmitted && incomeDetailsForm.controls.income.errors"
                                    class="has-error">
                                    <span *ngIf="incomeDetailsForm.controls.income.errors.required"> Income
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> National Insurance Number </label>
                                <input [(ngModel)]="userObject.nationalInsuranceNumber" type="text" class="form-control"
                                    placeholder="National Insurance Number" formControlName="nationalInsuranceNumber" name="nationalInsuranceNumber">
                                <div *ngIf="formSubmitted && incomeDetailsForm.controls.nationalInsuranceNumber.errors"
                                    class="has-error">
                                    <span *ngIf="incomeDetailsForm.controls.nationalInsuranceNumber.errors.required"> National Insurance Number
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="formType == 'notesDetail'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Note</h4>
                    <form class="form-sample" [formGroup]="notesDetailForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label">User Name</label>
                                <input [(ngModel)]="userObject.userName" type="text" class="form-control"
                                    placeholder="User Name" formControlName="userName" name="userName" readonly>
                                <div *ngIf="formSubmitted && notesDetailForm.controls.userName.errors"
                                    class="has-error">
                                    <span *ngIf="notesDetailForm.controls.userName.errors.required">User Name is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Staff Name </label>
                                <input [(ngModel)]="userObject.referringAgentNotes[index].name" type="text" class="form-control"
                                    placeholder="Staff Name" formControlName="staffName" name="staffName" readonly>
                                <div *ngIf="formSubmitted && notesDetailForm.controls.staffName.errors"
                                    class="has-error">
                                    <span *ngIf="notesDetailForm.controls.staffName.errors.email">staffName is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Note</label>
                                <!-- <input [(ngModel)]="userObject.notes[index].comment" type="text" class="form-control"
                                    placeholder="Note" formControlName="note" name="note"> -->

                                    <textarea type="text" class="form-control" [(ngModel)]="userObject.referringAgentNotes[index].comment" formControlName="note" name="note" placeholder="note" rows="5"></textarea>
                                <div *ngIf="formSubmitted && notesDetailForm.controls.note.errors" class="has-error">
                                    <span *ngIf="notesDetailForm.controls.note.errors.pattern">Note is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="formType == 'additionalMember'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Additional Member </h4>
                    <form class="form-sample" [formGroup]="additionalMemberForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label">First Name</label>
                                <input [(ngModel)]="userObject.additionalMembers[index].firstName" type="text"
                                    class="form-control" placeholder="User Name" formControlName="firstName"
                                    name="userName">
                                <div *ngIf="formSubmitted && additionalMemberForm.controls.firstName.errors"
                                    class="has-error">
                                    <span *ngIf="additionalMemberForm.controls.firstName.errors.required">First Name is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">Last Name</label>
                                <input [(ngModel)]="userObject.additionalMembers[index].lastName" type="text"
                                    class="form-control" placeholder="User Name" formControlName="lastName"
                                    name="userName">
                                <div *ngIf="formSubmitted && additionalMemberForm.controls.lastName.errors"
                                    class="has-error">
                                    <span *ngIf="additionalMemberForm.controls.lastName.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <label class="col-form-label"> Staff Name </label>
                                <input [(ngModel)]="userObject.notes[index].name" type="text" class="form-control"
                                    placeholder="Staff Name" formControlName="staffName" name="staffName">
                                <div *ngIf="formSubmitted && notesDetailForm.controls.staffName.errors"
                                    class="has-error">
                                    <span *ngIf="notesDetailForm.controls.staffName.errors.email">staffName is
                                        required</span>
                                </div>
                            </div> -->
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Relationship</label>
                                <input [(ngModel)]="userObject.additionalMembers[index].relationshipToYou" type="text"
                                    class="form-control" placeholder="Relationship" formControlName="relationshipToYou"
                                    name="relationshipToYou">
                                <div *ngIf="formSubmitted && additionalMemberForm.controls.relationshipToYou.errors"
                                    class="has-error">
                                    <span
                                        *ngIf="additionalMemberForm.controls.relationshipToYou.errors.required">Relationship
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Date Of Birth </label>
                                <input bsDatepicker [(ngModel)]=memberDOB formControlName="dateOfBirth"
                                    class="form-control" placeholder="Date of birth" name="dateOfBirth"
                                    [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , rangeInputFormat: 'DD/MM/YYYY' , dateInputFormat:'DD/MM/YYYY'}">
                                <div *ngIf="formSubmitted && additionalMemberForm.controls.dateOfBirth.errors"
                                    class="has-error">
                                    <span *ngIf="additionalMemberForm.controls.dateOfBirth.errors.required">Date Of
                                        Birth
                                        is required</span>
                                </div>
                            </div>
                        </div>
                        <!--file upload additional member-->
                        <!-- <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Proof Of ID</label>

                                <input type='file' (change)="readURL($event);" />
                                <img id="blah" [src]="userObject.additionalMembers[index].imgIdProofMember" alt="your image" />
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Proof Of Income </label>

                            </div>
                        </div> -->

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="formType == 'interest'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Area Of Interest</h4>
                    <form class="form-sample" [formGroup]="addInterestForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-12">
                                <label class="col-form-label"> Area Of Interest</label>
                                <ng-select formControlName="areaOfInterest" name="areaOfInterest" [items]="cities2"
                                     [multiple]="true" placeholder="Select cities"
                                    maxSelectedItems=3 [(ngModel)]="selectedCityName" bindLabel="name" bindValue="name" >
                                </ng-select>
                                <!-- <div *ngIf="formSubmitted && addInterestForm.controls.areaOfInterest.errors"
                                    class="has-error">
                                    <span *ngIf="addInterestForm.controls.areaOfInterest.errors.required">Area of
                                        interest is
                                        required</span>
                                </div> -->

                            </div>
                        </div>

                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="formType == 'familyComposition'" class="content-wrapper">
    <div class="row">
        <div class="col-md-10 grid-margin stretch-card" style="margin: 0 auto;">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Update Family Composition</h4>
                    <form class="form-sample" [formGroup]="familyCompositionForm" (submit)="onSubmit()">

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> First Name </label>
                                <input [(ngModel)]="userObject.firstNameFC" type="text" class="form-control"
                                    placeholder="First Name" formControlName="firstNameFC" name="firstNameFC">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.firstNameFC.errors"
                                    class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.firstNameFC.errors.required">First Name is
                                        required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Last Name </label>
                                <input [(ngModel)]="userObject.lastNameFC" type="text" class="form-control"
                                    placeholder="Last Name" formControlName="lastNameFC" name="lastNameFC">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.lastNameFC.errors"
                                    class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.lastNameFC.errors.required">Last Name is
                                        required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <!-- <div class="col-md-6">
                                <label class="col-form-label"> Age </label>
                                <input [(ngModel)]="userObject.age" type="text" class="form-control" placeholder="Age"
                                    formControlName="age" name="age">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.age.errors" class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.age.errors.required">Age is required</span>
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <label class="col-form-label"> Relationship</label>
                                <input [(ngModel)]="userObject.relationshipToYouFC" type="text"
                                    class="form-control" placeholder="Relationship" formControlName="relationshipToYouFC"
                                    name="relationshipToYouFC">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.relationshipToYouFC.errors"
                                    class="has-error">
                                    <span
                                        *ngIf="familyCompositionForm.controls.relationshipToYouFC.errors.required">Relationship
                                        is required</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Date Of Birth </label>
                                <input bsDatepicker [(ngModel)]=familyCompositionDOB formControlName="dateOfBirthFC"
                                    class="form-control" placeholder="Date of birth" name="birthday"
                                    [bsConfig]="{containerClass: 'theme-blue' , showWeekNumbers: false , rangeInputFormat: 'DD/MM/YYYY' , dateInputFormat:'DD/MM/YYYY'}">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.dateOfBirthFC.errors"
                                    class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.dateOfBirthFC.errors.required">Date Of Birth
                                        is required</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> Bedroom Size </label>
                                <input [(ngModel)]="userObject.bedroomSize" type="text" class="form-control"
                                    placeholder="bedroomSize" formControlName="bedroomSize" name="bedroomSize">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.bedroomSize.errors" class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.bedroomSize.errors.required">Bedroom size is
                                        required</span>
                                    <!-- <span *ngIf="familyCompositionForm.controls.email.errors.email">Enter valid email</span> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label"> Current Accommodation</label>
                                <input [(ngModel)]="userObject.currentAccommodation" type="text" class="form-control"
                                    placeholder=" Current Accommodation" formControlName="currentAccommodation" name="currentAccommodation">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.currentAccommodation.errors"
                                    class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.currentAccommodation.errors.required">Current Accommodation is
                                        required</span>
                                    <!-- <span *ngIf="familyCompositionForm.controls.currentAccommodation.errors.currentAccommodation">Enter valid
                                        email</span> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <div class="col-md-6">
                                <label class="col-form-label"> ASB </label>
                                <input [(ngModel)]="userObject.asbDetails" type="text" class="form-control"
                                    placeholder="ASB" formControlName="asbDetails" name="asbDetails">
                                <div *ngIf="formSubmitted && familyCompositionForm.controls.asbDetails.errors" class="has-error">
                                    <span *ngIf="familyCompositionForm.controls.asbDetails.errors.required">ASB is
                                        required</span>
                                    <span *ngIf="familyCompositionForm.controls.email.errors.email">Enter valid email</span>
                                </div>
                            </div>

                        </div> -->
                        <div class="form-actions mt-4 mb-4 float-right">
                            <button type="submit" class="btn btn-outline-success btn-fw m-2">Save</button>
                            <button class="btn btn-outline-danger btn-fw m-2" (click)="bsModalRef.hide()"
                                type="button">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
