import { Pipe, PipeTransform } from '@angular/core';
// import { AlertNotification} from '../../core/alertNotification/alertNotification';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import Fuse from 'fuse.js'

@Pipe({ 
    name: 'LisitingFilter'
})

export class UserListPipe implements PipeTransform {

    constructor(
        private notifier : AlertNotification
    ){}

    transform(allUsers:any,term:string) {
        if(term){
            if(!term.charAt(0).match(/^[a-zA-Z\d]+$/))
                return allUsers
            // if(term.length <= 2){
            //     // console.log("hgdsfj" , typeof(allUsers))
            //     this.notifier.nextData(allUsers.length);
            //     this.notifier.nextExport(allUsers);
            //     return allUsers
            // }
            else{
                const searcher = new Fuse(allUsers, 
                    {
                        keys: ['name', 'title', 'provider', 'property_address', 'firstName', 'lastName',
                        'city','email','userName', 'providerName', 'message', 'desc', 'status'],
                        includeScore: true,
                        threshold: allUsers[0].desc ? 1.0 : 0.2,
                        minMatchCharLength: allUsers[0].desc ? term.length : 2
                    }
                );
                const result = searcher.search(term);

                var ret = []
                if(allUsers[0].email)
                    ret = result.filter(x=> x.score < 0.2).map(r=> r.item)
                else if(allUsers[0].desc || allUsers[0].message)
                    ret = result.map(r=> r.item)
                else
                    ret = result.filter(x=> x.score < 0.5).map(r=> r.item)
                    
                this.notifier.nextData(ret.length);
                this.notifier.nextExport(ret);
                return ret
            }
        }
        else{
            
            if(allUsers && allUsers.length){
                this.notifier.nextExport(allUsers);
                this.notifier.nextData(allUsers.length);
            }
            else{
                this.notifier.nextData(0);
            }
            
            return allUsers
        }
        
    }
}