import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import {  CanActivateLogin } from '../core/guards/authguard';


const routes: Routes = [
  { path: 'landlord/user/:email', component: LoginComponent,canActivate:[CanActivateLogin]},
  { path: 'landlord/user/:email/:pass', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
