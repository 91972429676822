import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { HeaderTitleService } from "src/app/core/headerTitle/header-title.service";
import { SidebarToggleService } from "src/app/core/sidebar-toggle/sidebar-toggle.service";
import { CsvFileService } from "src/app/core/common-service/exportCSV";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import * as moment from "moment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  p: number = 1;
  p_popup: number = 1;
  loggedInUser;
  title = "";
  notificationCountAdmin = 0;
  notificationCount = 0;
  toggleSidebarBool: Boolean = false;
  universalSearchValue;
  stafMembersList;
  staffActivitiesArray: any[] = [];
  staffActivitiesArrayNew: any[] = [];
  staffActivitiesArrayCopy: any[] = [];
  sortvalue = ["created", "desc"];
  constructor(
    private firebaseservice: FirebaseQueryService,
    private alertnotification: AlertNotification,
    private router: Router,
    private apiservice: ApiServiceService,
    private loaderService: NgxSpinnerService,
    private headerTitleService: HeaderTitleService,
    private csvfiledownloader: CsvFileService,
    private sidebarToggleService: SidebarToggleService
  ) {
    this.sidebarToggleService.toggleSidebar.subscribe((response: any) => {
      this.toggleSidebarBool = response;
    });
  }

  ngOnInit(): void {
    this.headerTitleService.title.subscribe((updatedTitle) => {
      this.title = updatedTitle;
    });
    if (localStorage.getItem("UserType")) {
      this.loggedInUser = JSON.parse(localStorage.getItem("UserType"));
      this.getStaffActivities();
      // this.getStaffList();
    }
  }

  logoutUser() {
    Swal.fire({
      title: "Do you want to logout ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.value == true) {
        // here ==========>>>>>> add a log for staff members 0n signout
        let loggedInUserData = JSON.parse(localStorage.getItem("UserType"));
        let logData = {
          staffName: loggedInUserData.name,
          staffEmail: loggedInUserData.email,
          actionPerformed: "Logged out",
          dateTime: new Date(),
        };
        await this.firebaseservice.uploadStaffActivity(logData);
        localStorage.clear();
        this.alertnotification.successAlert("Logout Successfully");
        this.router.navigate([""]);
      } else {
        return;
      }
    });
  }

  toggleSidbar() {
    this.toggleSidebarBool = !this.toggleSidebarBool;
    this.sidebarToggleService.toggleSidebar.next(this.toggleSidebarBool);
  }

  clickPagination() {
    window.scroll(0, 0);
  }

  getStaffActivities() {
    let records = [];
    let ActiveAgent = JSON.parse(localStorage.getItem("ActiveAgent"));
    // this.firebaseservice.getUserPropertyStatusActivitiesForRA().then(function(querySnapshot) {
    this.firebaseservice
      .getUserPropertyStatusForRA(ActiveAgent.name)
      .then(
        function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            var data = doc.data();
            data["id"] = doc.id;
            records.push(data);
          });
          this.staffActivitiesArray = records;
          console.log(this.staffActivitiesArray);

          // this.staffActivitiesArray = this.staffActivitiesArray.filter((x)=>{
          // if(x.referringAgent == ActiveAgent.name){
          //   return x;
          // }
          // })

          this.staffActivitiesArrayNew = this.staffActivitiesArray.filter(
            (x) => {
              if (x.markAsRead == false) {
                return x;
              }
            }
          );
          this.notificationCount = this.staffActivitiesArrayNew.length;
          console.log("this.staffActivitiesArray", this.staffActivitiesArray);
          this.staffActivitiesArrayCopy = records;
        }.bind(this)
      )
      .catch(
        function (error) {
          this.loaderService.hide();
          console.log("Error getting documents: ", error);
        }.bind(this)
      );
  }

  // getStaffList() {
  //   this.apiservice.getRequest_b2(`/getUser`).subscribe(async (response : any )=>{
  //     this.stafMembersList = response.data.slice(0);
  //   },
  //   error =>{
  //     this.alertnotification.errorAlert(error.error.message);
  //   });
  // }

  // makeCountClear(){
  //   if(this.loggedInUser.email === 'admin@mysocialhousing.com'){
  //     this.apiservice.getRequest_b2('/clearNotificationCount').subscribe();
  //     this.notificationCountAdmin = 0;
  //   }
  // }

  makeDateFormate(value) {
    return value && value.seconds
      ? moment.unix(value.seconds).format("DD MMM YYYY h:mm A")
      : "";
  }

  viewUserDetails(userId) {
    this.firebaseservice
      .getUserbyref(userId)
      .then((result) => {
        var records = [];
        var data = result.data();
        data["id"] = result.id;
        records.push(data);
        if (records.length > 0) {
          this.loaderService.hide();
          localStorage.setItem("userDetail", JSON.stringify(records[0]));
          this.router.navigate([]).then((result) => {
            window.open("/landlord/userdetail", "_blank");
          });
        } else {
          this.loaderService.hide();
          this.alertnotification.errorAlert("No User Found");
        }
      })
      .catch(function (error) {
        this.loaderService.hide();
        console.log(error);
        this.alertNotification.errorAlert(error.message);
      });
  }

  universalSearch() {
    this.staffActivitiesArray = this.staffActivitiesArrayCopy;
    if (this.universalSearchValue.length > 0) {
      this.staffActivitiesArray = this.staffActivitiesArray.filter((obj) => {
        return Object.keys(obj).some((key) => {
          if (typeof obj[key] == "string") {
            return obj[key]
              .toLowerCase()
              .includes(this.universalSearchValue.toLowerCase());
          }
        });
      });
    }
  }

  filterByStaff(evt) {
    this.staffActivitiesArray = this.staffActivitiesArrayCopy;
    if (evt && evt.email) {
      this.staffActivitiesArray = this.staffActivitiesArray.filter(
        (x) => x.staffEmail === evt.email
      );
    }
  }

  exportStaffActivity() {
    var headerList = [
      "staffEmail",
      "staffName",
      "actionPerformed",
      "id",
      "userId",
      "dateTime",
    ];
    this.csvfiledownloader.downloadFile(
      this.staffActivitiesArray,
      headerList,
      "staffActivityReport"
    );
  }

  markAsRead() {
    this.staffActivitiesArrayNew.forEach((activity) => {
      this.firebaseservice.updatePropertyStatusLog(activity.id);
    });
    this.getStaffActivities();
  }
}
