import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import * as moment from "moment";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-update-user-details",
  templateUrl: "./update-user-details.component.html",
  styleUrls: ["./update-user-details.component.scss"],
})
export class UpdateUserDetailsComponent implements OnInit {
  formSubmitted = false;
  AccountDetailForm: FormGroup;
  otherDetailForm: FormGroup;
  referringAgencyForm: FormGroup;
  addressHistoryForm: FormGroup;
  landlordDetailForm: FormGroup;
  accommodationForm: FormGroup;
  incomeDetailsForm: FormGroup;
  notesDetailForm: FormGroup;
  addInterestForm: FormGroup;
  additionalMemberForm: FormGroup;
  familyCompositionForm: FormGroup;
  formType;
  userObject;
  userDOB;
  index;
  cities2 = [];
  memberDOB;
  selectedCityName: string[];
  familyCompositionDOB;
  userAge: any;
  oldUserObject;
  ActiveUser;
  ActiveAgent;
  changeFiled = "Edited ";
  dictionary = {
    accommodationRequired: "Accommodation",
    address: "Address",
    addressHistory2: "Address 2",
    addressHistory3: "Address 3",
    addressHistory4: "Address 4",
    addressHistory5: "Address 5",
    age: "Age",
    bedroomSize: "Bedroom Size",
    city: "City",
    currentAccommodation: "Current Accommodation",
    currentTenancyType: "Current Tenancy Type",
    dateOfBirth: "Date of Birth",
    email: "Email",
    email2: "Email 2",
    employmentDetails: "Employment Details",
    firstName: "First Name",
    income: "Income",
    lastName: "Last Name ",
    nationalInsuranceNumber: "National Insurance Number",
    phoneNumber1: "Phone Number",
    referringAgent: "Referring Agent Name",
  };

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService
  ) {
    this.AccountDetailForm = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      age: [null],
      dateOfBirth: [null, [Validators.required]],
      email: [null],
      phoneNumber1: [null, [Validators.pattern(/^[0-9]\d*$/)]],
      city: [null, [Validators.required]],
      address: [null, [Validators.required]],
      postCode: [null],
    });
    this.otherDetailForm = this.formBuilder.group({
      email1: [null],
      email2: [null],
    });
    this.landlordDetailForm = this.formBuilder.group({
      landlordName: [null],
      landlordPhoneNumber: [null, [Validators.pattern(/^[0-9]\d*$/)]],
      landlordEmail: [null, [Validators.email]],
    });
    this.addressHistoryForm = this.formBuilder.group({
      addressHistory2: [null],
      addressHistory3: [null],
      addressHistory4: [null],
      addressHistory5: [null],
    });
    this.referringAgencyForm = this.formBuilder.group({
      firstNameRA: [null],
      lastNameRA: [null],
      emailRA: [null],
      phoneNumberRA: [null],
    });
    this.notesDetailForm = this.formBuilder.group({
      userName: [null, [Validators.required]],
      staffName: [null, [Validators.required]],
      note: [null, [Validators.required]],
    });
    this.addInterestForm = this.formBuilder.group({
      areaOfInterest: [""],
    });
    this.additionalMemberForm = this.formBuilder.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      relationshipToYou: [null, [Validators.required]],
      dateOfBirth: [null, [Validators.required]],
    });
    this.accommodationForm = this.formBuilder.group({
      accommodationRequired: [null, [Validators.required]],
      bedroomSize: [null],
      currentAccommodation: [null],
      currentTenancyType: [null, [Validators.required]],
    });
    this.incomeDetailsForm = this.formBuilder.group({
      employmentDetails: [null, [Validators.required]],
      income: [null, [Validators.required]],
      nationalInsuranceNumber: [null, [Validators.required]],
    });
    this.familyCompositionForm = this.formBuilder.group({
      firstNameFC: [null, [Validators.required]],
      lastNameFC: [null, [Validators.required]],
      dateOfBirthFC: [null, [Validators.required]],
      relationshipToYouFC: [null, [Validators.required]],
      bedroomSize: [null, [Validators.required]],
      currentAccommodation: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.oldUserObject = JSON.parse(localStorage.getItem("userDetail"));
    this.ActiveUser = JSON.parse(localStorage.getItem("UserType"));
    this.ActiveAgent = JSON.parse(localStorage.getItem("ActiveAgent"));
    this.userObject["userName"] = `${
      this.userObject && this.userObject.firstName
        ? this.userObject.firstName
        : ""
    } ${
      this.userObject && this.userObject.lastName
        ? this.userObject.lastName
        : ""
    }`;
    console.log(this.userObject, "userObejct");
    if (this.formType == "accountDetails") {
      this.userAge = this.userObject.age;
      if (this.userObject && this.userObject.dateOfBirth) {
        this.userDOB =
          this.userObject.dateOfBirth && this.userObject.dateOfBirth.seconds
            ? moment.unix(this.userObject.dateOfBirth.seconds).toDate()
            : null;
      }
    }

    if (this.userObject && this.userObject.areaOfInterest) {
      this.selectedCityName = this.userObject.areaOfInterest;
    }

    if (this.formType == "additionalMember") {
      console.log(this.index, "index");
      console.log(this.userObject.additionalMembers[this.index]);
      if (
        this.userObject &&
        this.userObject.additionalMembers[this.index].dateOfBirth
      ) {
        var initDate = moment(
          this.userObject.additionalMembers[this.index].dateOfBirth,
          "DD/MM/YYYY"
        );
        this.memberDOB = moment(initDate).format("DD/MM/YYYY");
      }
    }

    if (this.formType == "familyComposition") {
      if (this.userObject && this.userObject.dateOfBirthFC) {
        var initDate = moment(this.userObject.dateOfBirthFC, "DD/MM/YYYY");
        this.familyCompositionDOB = moment(initDate).format("DD/MM/YYYY");
      }
    }
    this.fetchAreasOfInterest();
  }

  fetchAreasOfInterest() {
    var data = {};
    this.apiservice.postRequest("getCityList.php", data).subscribe(
      (result: any) => {
        this.cities2 = result.data;
      },
      (error) => {
        this.loaderService.hide();
        console.log(error);
      }
    );
  }

  onSubmit() {
    this.formSubmitted = true;
    var updatedDetails = null;
    if (this.formType == "accountDetails") {
      if (this.AccountDetailForm.invalid) {
        return;
      } else {
        updatedDetails = this.AccountDetailForm.value;
      }
    } else if (this.formType == "otherDetails") {
      if (this.otherDetailForm.invalid) {
        return;
      } else {
        updatedDetails = this.otherDetailForm.value;
      }
    } else if (this.formType == "referringAgency") {
      if (this.referringAgencyForm.invalid) {
        return;
      } else {
        updatedDetails = this.referringAgencyForm.value;
      }
    } else if (this.formType == "addressHistory") {
      if (this.addressHistoryForm.invalid) {
        return;
      } else {
        updatedDetails = this.addressHistoryForm.value;
      }
    } else if (this.formType == "landlordDetails") {
      if (this.landlordDetailForm.invalid) {
        return;
      } else {
        updatedDetails = this.landlordDetailForm.value;
      }
    } else if (this.formType == "accommodationRequired") {
      if (this.accommodationForm.invalid) {
        return;
      } else {
        updatedDetails = this.accommodationForm.value;
      }
    } else if (this.formType == "incomeDetails") {
      if (this.incomeDetailsForm.invalid) {
        return;
      } else {
        updatedDetails = this.incomeDetailsForm.value;
      }
    } else if (this.formType == "interest") {
      if (this.addInterestForm.invalid) {
        return;
      } else {
        updatedDetails = this.addInterestForm.value;
      }
    } else if (this.formType == "notesDetail") {
      if (this.notesDetailForm.invalid) {
        return;
      } else {
        updatedDetails = {};
        this.userObject.referringAgentNotes[this.index].comment =
          this.notesDetailForm.value.note;
        this.userObject.referringAgentNotes[this.index].name =
          this.notesDetailForm.value.staffName;
        updatedDetails["referringAgentNotes"] =
          this.userObject.referringAgentNotes;
      }
    } else if (this.formType == "additionalMember") {
      if (this.additionalMemberForm.invalid) {
        return;
      } else {
        updatedDetails = {};
        this.userObject.additionalMembers[this.index].firstName =
          this.additionalMemberForm.value.firstName;
        this.userObject.additionalMembers[this.index].lastName =
          this.additionalMemberForm.value.lastName;
        this.userObject.additionalMembers[this.index].relationshipToYou =
          this.additionalMemberForm.value.relationshipToYou;
        this.userObject.additionalMembers[this.index].dateOfBirth =
          this.additionalMemberForm.value.dateOfBirth;

        this.userObject.additionalMembers[this.index].dateOfBirth = moment(
          this.additionalMemberForm.value.dateOfBirth
        ).format("DD/MM/YYYY");
        updatedDetails["additionalMembers"] = this.userObject.additionalMembers;
      }
    } else {
      if (this.familyCompositionForm.invalid) {
        return;
      } else {
        this.familyCompositionForm.value.dateOfBirthFC = moment(
          this.familyCompositionForm.value.dateOfBirthFC
        ).format("DD/MM/YYYY");
        updatedDetails = this.familyCompositionForm.value;
      }
    }
    if (updatedDetails) {
      this.loaderService.show();
      Object.keys(updatedDetails).forEach((key) => {
        if (updatedDetails[key] === undefined || updatedDetails[key] === null) {
          delete updatedDetails[key];
        }
      });
      if (updatedDetails && updatedDetails.age) {
        updatedDetails.age = parseInt(updatedDetails.age);
      }
      this.changeHistory(updatedDetails);
      updatedDetails.editHistory =
        this.userObject.editHistory == undefined
          ? []
          : this.userObject.editHistory;
      updatedDetails.editHistory.push({
        name: this.ActiveUser.name + " (" + this.ActiveAgent.name + ")",
        action: this.changeFiled,
        date: new Date(),
      });
      this.firebaseservice
        .updateUserStatus(this.userObject.id, updatedDetails)
        .then(async (records) => {
          console.log("successfully updated");
          const updateUser = await this.updateUserInfo(this.userObject.email);
          this.alertnotification.successAlert("successfully updated");
          this.alertnotification.updateNoteData();
          this.loaderService.hide();
          this.bsModalRef.hide();
        })
        .catch((error) => {
          this.loaderService.hide();
          console.log(error);
        });
    }
  }

  changeHistory(newData) {
    let keys = Object.keys(newData);
    keys.forEach((field) => {
      console.log(this.oldUserObject[field], newData[field]);
      if (this.oldUserObject[field] !== newData[field]) {
        console.log({ field });
        this.changeFiled =
          this.changeFiled + " " + this.dictionary[field] + ",";
      }
    });
    this.changeFiled = this.changeFiled.replace(/.$/, "");
    console.log(this.changeFiled);
  }

  updateUserInfo(id) {
    return new Promise((resolve, reject) => {
      var records1 = [];
      this.firebaseservice
        .getUserByEmail(id)
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            localStorage.setItem("userDetail", JSON.stringify(records1[0]));
            resolve(true);
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
            reject(false);
          }.bind(this)
        );
    });
  }

  dateConverter(date) {
    return date && date.seconds ? moment.unix(date.seconds).toDate() : null;
  }

  dateChange(event) {
    if (event != null) {
      var ageDifMs = Date.now() - event.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      var year = ageDate.getUTCFullYear();
      var age = Math.abs(year - 1970);
      this.userAge = age;
    } else {
      this.userObject.dateOfBirth = this.userObject.dateOfBirth;
      this.userObject.age = this.userObject.age;
    }
    return this.userObject.age;
  }

  onCancel() {
    this.dateChange(null);
    this.bsModalRef.hide();
  }
}
