import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { HeaderTitleService } from 'src/app/core/headerTitle/header-title.service';
import { MustMatch } from 'src/app/shared/helper/passwordmatch';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  createForm;
  ActiveUser;
  constructor(
    private headerTitleService: HeaderTitleService,
    private apiservice : ApiServiceService,
    private router: Router,
    private loaderService: NgxSpinnerService,
    private alertnotification : AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.headerTitleService.setTitle("Settings ");
    this.ActiveUser = JSON.parse(localStorage.getItem("UserType"));
    this.createForm = this.formBuilder.group({
      oldPassword: ['' ,[Validators.required]],
      newPassword: ['',[Validators.required]],
      rPassword: ['',[Validators.required]],
    },
    {
      validator: [MustMatch("newPassword", "rPassword")],
    }
    );


  }
  async onSubmit() {
    this.loaderService.show();
    if (this.createForm.invalid) {
      this.alertnotification.errorAlert("All inputs are required");
      this.loaderService.hide();
      return;
    }
    const curPassword = JSON.parse(localStorage.getItem("countersign"))
    console.log(curPassword);
    if(curPassword !== this.createForm.value.oldPassword){
      this.loaderService.hide();
      this.alertnotification.errorAlert("Incorrect Old PAssword");
      return ;
    }
    console.log(this.createForm.value.newPassword);
    let change = this.firebaseservice.changePassword(this.createForm.value.newPassword);
    if(change){
      let data = {
        "email": this.ActiveUser.email,
        "password": this.createForm.value.newPassword
      }
      this.apiservice.postRequest_b2(`/updateUserPassword`, data).subscribe(async (response: any) => {
        this.loaderService.hide();
        localStorage.setItem('countersign', JSON.stringify(this.createForm.value.newPassword));
        this.alertnotification.successAlert("Password Changed Successfully");
      },
        error => {
          this.alertnotification.errorAlert(error.error.message);
      });
    }
  }
}
